import { useToast } from 'vue-toastification';

export type NotificationType = {
  id: number;
  title: string;
  uuid: string;
  default_text: string | null;
  default_when_is_it_sent_text: string | null;
  for_project_leaders_only: boolean;
  default_enabled: boolean;
  default_send_email: boolean;
  default_send_push: boolean;
  category: string;
  order: number;
  with_email: boolean;
  has_time: boolean;
  default_hours_before: number | null;
};

export const fetchNotificationTypes = async () => {
  const { data } = await axios.get<NotificationType[]>('/api/notification-types');
  return data;
};

export type NotificationPreference = {
  id: number;
  notification_type_id: number | null;
  at_all: boolean;
  push: boolean;
  email: boolean;
  hours_before: string | null;
};

export const fetchNotificationPreferences = async () => {
  const { data } = await axios.get<NotificationPreference[]>('/api/notification-preferences');
  return data;
};

export const postNotificationPreference = async ({ notification_type_id, at_all, push, email, hours_before }) => {
  const { data } = await axios.post('/api/notification-preferences', {
    notification_type_id,
    at_all,
    push,
    email,
    hours_before,
  });
  return data;
};

export const patchNotificationPreference = async ({ id, at_all, push, email, hours_before }) => {
  const { data } = await axios.patch(`/api/notification-preferences/${id}`, {
    at_all,
    push,
    email,
    hours_before,
  });
  return data;
};

<script setup lang="ts">
import { useEventTypes } from '@/composables/use-event-types';
import { useGroupProjectLeaders } from '@/composables/use-group-project-leaders';
import { EventMinimalResource } from '@/types/event';
import { InviteFestival, InviteResource } from '@/types/invite';
import { getRoute, openRoute } from '@/util/route';
import { router, usePage } from '@inertiajs/vue3';
import moment from 'moment';
import { computed, nextTick, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useRecurringModal } from '@/composables/modals/use-recurring-modal';
import { useMultipleButtonsModal } from '@/composables/modals/use-multiple-buttons-modal';
import { getItemFromArrayBasedOnId, getMemberImage, uniqueObjectsFromArray } from '@/util/globals';
import { useSmallScreen } from '@/composables/use-small-screen';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import IntermediateStepAssignments from '@/components/IntermediateStep/Sections/IntermediateStepAssignments.vue';
import IntermediateStepRoomBookings from '@/components/IntermediateStep/Sections/IntermediateStepRoomBookings.vue';
import IntermediateStepShifts from '@/components/IntermediateStep/Sections/IntermediateStepShifts.vue';
import IntermediateStepPartners from '@/components/IntermediateStep/Sections/IntermediateStepPartners.vue';
import VButton from '@/components/Inputs/VButton.vue';
import EventRequestSlideOut from '@/components/Models/EventRequests/EventRequestSlideOut.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import UserProfileModal from '@/components/Modals/UserProfileModal.vue';
import IntermediateStepProductionPeriod from '@/components/IntermediateStep/Sections/IntermediateStepProductionPeriod.vue';
import RecurringDatesButton from '@/components/Models/Recurring/RecurringDatesButton.vue';
import IntermediateStepStatusBar from '@/components/IntermediateStep/Sections/IntermediateStepStatusBar.vue';
import DisplayText from '@/components/Display/DisplayText.vue';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import ShowTimeCRUDModal from '@/components/ShowTimes/ShowTimeCRUDModal.vue';
import IntermediateStepPerformanceSection from '@/components/IntermediateStep/Sections/IntermediateStepPerformanceSection.vue';
import IntermediateInfoTabDetail from '@/components/IntermediateStep/Sections/IntermediateInfoTabDetail.vue';
import DisplayBadge from '@/components/Display/DisplayBadge.vue';

type Props = {
  invite: InviteResource;
  groupId: number;
  event: EventMinimalResource;
  admin: boolean;
  venueId: number | null;
  onEvent: boolean;
  performanceId: number | null;
  festival: InviteFestival | null;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  start: [];
  end: [];
  reloadInvite: [];
  editEvent: [];
  updateEventType: [arg: { key: string; value: number | string }];
  attributeUpdated: [arg: { key: string; value: number | string }];
  updateEvent: [arg: EventMinimalResource];
}>();

const toast = useToast();
const { recurringModal } = useRecurringModal();
const { threeButtonModal } = useMultipleButtonsModal();
const { assertCertain } = useCertaintyModal();
const { isSmallScreen } = useSmallScreen();

const { projectLeaders, fetchGroupProjectLeaders } = useGroupProjectLeaders(props.groupId, false);
fetchGroupProjectLeaders();

const { eventTypes, fetch: fetchEventTypes } = useEventTypes('Group', props.groupId, false);
fetchEventTypes();

const roomBookings = ref([]);

const accessLevels = computed(() =>
  props.invite.access_levels !== false
    ? props.invite.access_levels
    : {
        has_assignments: false,
        has_room_bookings: false,
        has_shifts: false,
        has_partners: false,
      }
);

const totalShifts = computed(() => {
  if (!accessLevels.value.has_shifts) {
    return null;
  }
  return props.invite.owner_shifts.filter((s) => s.declined_at === null).length;
});

const declinedShifts = computed(() => {
  if (!accessLevels.value.has_shifts) {
    return null;
  }
  return props.invite.owner_shifts.filter((s) => s.declined_at !== null).length;
});

const unapprovedShifts = computed(() => {
  if (!accessLevels.value.has_shifts) {
    return null;
  }
  return props.invite.owner_shifts.filter((s) => !s.approved).length;
});
const forSaleShifts = computed(() => {
  if (!accessLevels.value.has_shifts) {
    return null;
  }
  return (
    props.invite.owner_shifts.filter(
      (s) => s.for_sale && s.shift_interest_pivots.filter((p) => !p.declined_at).length > 0
    ).length +
    '/' +
    props.invite.owner_shifts.filter((s) => s.for_sale).length
  );
});

const unassignedShifts = computed(() => {
  if (!accessLevels.value.has_shifts) {
    return null;
  }
  return props.invite.owner_shifts.filter((s) => s.user_id === null).length;
});

const pendingShifts = computed(() => {
  if (!accessLevels.value.has_shifts) {
    return null;
  }
  return props.invite.owner_shifts.filter(
    (s) => s.accepted_at === null && s.declined_at === null && s.approved && s.user_id !== null
  ).length;
});

const cancelledShifts = computed(() => {
  if (!accessLevels.value.has_shifts) {
    return null;
  }
  return props.invite.owner_shifts.filter((s) => s.cancelled_at !== null).length;
});

const loading = ref(true);
const requesterModalOpen = ref(false);
const showAssignments = ref(true);
const showRoomBookings = ref(true);
const showShifts = ref(true);
const showPartners = ref(true);
const indexOfRecurringDate = ref<string | number>(0);
const workingItem = ref<string | null>(null);

const isNotOnAPartner = !window.location.href.includes('/contacts/');

const getIndexOfRecurringDate = () => {
  const array = props.event.recurring_dates
    .slice()
    .sort((a, b) => moment(a.start_date).valueOf() - moment(b.start_date).valueOf());

  const index = _.findIndex(array, (d) => d.start_date === props.invite.start_date);

  if (index > -1) {
    indexOfRecurringDate.value = index + 1;
  } else {
    indexOfRecurringDate.value = 'N/A';
  }
};

getIndexOfRecurringDate();

const showStatusbar = computed(() => {
  if (props.festival) return false;
  return (
    getItemFromArrayBasedOnId(props.invite.event_type_id, eventTypes.value, { status_bar: [] })?.status_bar?.length > 0
  );
});

const goToOtherEvent = (date: string) => {
  const otherInvite = props.invite.recurring_dates.find((i) => i.start_date === date);
  if (otherInvite) {
    window.location.href = getRoute('events.show', otherInvite.slug);
  } else {
    const otherEvent = props.event.find((i) => i.start_date === date);
    if (otherEvent) {
      throw new Error('Go to other event error');
    }
  }
};

const formattedDate = (date: string) => {
  if (moment(props.invite.event?.start_date).isSame(date, 'day')) {
    return null;
  }
  return moment(date).format(`ddd MMM Do${moment(props.invite.event?.start_date).isSame(date, 'year') ? '' : 'YYYY'}`);
};

const formattedDateTime = (date: string, secondDate?: string) => {
  if (secondDate) {
    const diff = Math.abs(moment(date).diff(secondDate, 'minutes'));
    if (diff < 60 * 24) {
      return moment(date).format('HH:mm');
    }
    return `${moment(date).format('HH:mm')}(${Math.round(diff) / 60}H)`;
  }
  return moment(date).format('HH:mm');
};

// const openPerformance = (performance, event) => {
//   if (props.performanceId && performance.id === props.performanceId) {
//     return;
//   }
//   if (event && event.ctrlKey) {
//     window.open(performance.slug);
//   } else {
//     router.visit(performance.slug);
//   }
// };

const resetItem = () => {
  workingItem.value = null;
};

const selectProjectLeader = async (newProjectLeaderId: number) => {
  if (newProjectLeaderId === props.invite.project_leader_id) return null;
  if (!props.invite.isAccepted) {
    emit('attributeUpdated', {
      key: 'project_leader_id',
      value: newProjectLeaderId,
    });
    return true;
  }
  workingItem.value = 'project_leader_id';

  const addToAll = props.invite.recurring_original_id
    ? await recurringModal(
        '',
        'Update Project Leader',
        'Do you want to update the event type on all recurrences of this event, or just this one?'
      )
    : false;
  if (addToAll === 'cancel') {
    resetItem();
    return;
  }

  await axios.patch(`/api/invites/${props.invite.id}/project-leader`, {
    project_leader_id: newProjectLeaderId,
    is_global: addToAll === 'all',
  });
  toast.success('Project Leader Changed.');
  emit('attributeUpdated', {
    key: 'project_leader_id',
    value: newProjectLeaderId,
  });
  resetItem();
  return;
};

const selectEventType = async (newEventTypeId: number) => {
  if (newEventTypeId === props.invite.event_type_id) return null;
  if (!props.invite.isAccepted) {
    emit('attributeUpdated', {
      key: 'event_type_id',
      value: newEventTypeId,
    });
    return true;
  }
  workingItem.value = 'event_type_id';

  const addToAll = props.invite.recurring_original_id
    ? await recurringModal(
        '',
        'Update Event Type',
        'Do you want to update the Event type on all recurrences of this event, or just this one?'
      )
    : false;
  if (addToAll === 'cancel') {
    resetItem();
    return;
  }

  const eventType = getItemFromArrayBasedOnId(newEventTypeId, eventTypes.value, { name: 'Event Type' });

  const result =
    newEventTypeId === null
      ? true
      : await threeButtonModal({
          title: 'Add Elements to Event',
          description: `Would you like to add all elements of ${eventType?.name} to event?`,
          button: {
            text: 'Update',
            type: 'primary',
          },
          options: [
            {
              value: 'first',
              label: 'Yes',
            },
            {
              value: 'second',
              label: 'No',
            },
          ],
        });
  if (result === 'cancel') {
    resetItem();
    return;
  }

  await axios.patch(`/api/invites/${props.invite.id}/event-type`, {
    event_type_id: newEventTypeId,
    is_global: addToAll === 'all',
    add_elements: result === 'first',
  });

  toast.success('Event Type Updated.');
  emit('attributeUpdated', {
    key: 'event_type_id',
    value: newEventTypeId,
  });
  resetItem();
  return;
};

const getStatusOfInvite = () => {
  const eventType = getItemFromArrayBasedOnId(props.invite.event_type_id, eventTypes.value, { status_bar: [] });

  if (!eventType) return '';

  if (!eventType.status_bar) return '';

  if (props.invite.status_bar_number === null) return '';

  if (
    eventType.status_bar.length === 0 ||
    props.invite.status_bar_number === 0 ||
    eventType.status_bar.length < props.invite.status_bar_number
  ) {
    return 'N/A';
  }
  return eventType.status_bar[props.invite.status_bar_number - 1].name;
};

const updateStatusBarNumber = async (num: number) => {
  if (num === 0) {
    const c = await assertCertain('Reset Status', 'Are you sure you want to reset the status back to 0?');
    if (!c) return;
  }
  await axios.patch(`/api/invites/${props.invite.id}/status-bar-number`, {
    status_bar_number: num,
  });
  emit('attributeUpdated', {
    key: 'status_bar_number',
    value: num,
  });
  toast.success('Status Updated');
};

const makeSureWantingToUnlink = async () => {
  if (!props.admin) return;
  const certain = await useCertaintyModal().assertCertain(
    'Unlink Event',
    'Are you sure you want to unlink this event? By doing so, it is no longer connected to the other recurrences, and changes will no longer be reflected. All elements on the event will be kept. '
  );
  if (!certain) return;
  await axios.post(`/api/recurring-events/${props.invite.event?.id}/unlink`).catch((error) => {
    toast.warning('Something went wrong, please try again. ');
    console.error(error);
  });
  toast.success('Unlinked event, refreshing page');
  emit('reloadInvite');
};

const selectedEventRequestId = ref(null);
const openEventRequestForm = (eventRequestId) => {
  if (!eventRequestId) return;
  selectedEventRequestId.value = null;
  nextTick(() => {
    selectedEventRequestId.value = eventRequestId;
  });
};

const height = CSS.supports('height: 100dvh') ? '100dvh' : '100vh';

const isOwner = computed(() => {
  if (!props.invite.event) return false;
  return props.invite.event.owner_type === 'App\\Group' && props.invite.event.owner_id === props.groupId;
});

const editDescription = ref('');
const editModeDescription = ref(false);

const reloadEventPage = () => {
  router.reload({
    only: ['event', 'myGroupInvites'],
  });
};

const saveDescription = async () => {
  if (!props.admin || !isOwner.value) return;

  await axios.patch('/api/events/' + props.event.id + '/update-description', {
    description: editDescription.value,
  });

  if (props.onEvent) {
    router.reload({
      only: ['event', 'myGroupInvites'],
      onSuccess: () => {
        toggleEditModeDescription();
      },
    });
  } else {
    emit('updateEvent', { ...props.event, description: editDescription.value });
    toggleEditModeDescription();
  }
};

const toggleEditModeDescription = () => {
  if (!props.admin || !isOwner.value) return;

  if (editModeDescription.value) {
    editDescription.value = '';
    editModeDescription.value = false;
    return;
  }
  if (usePage().props.event?.description) {
    editDescription.value = usePage().props.event.description;
  } else if (props.event.description) {
    editDescription.value = props.event.description;
  } else {
    editDescription.value = '';
  }
  editModeDescription.value = true;
};

const descriptionActions = computed(() => {
  if (!props.admin || !isOwner.value) return;

  if (editModeDescription.value) {
    return [
      {
        title: 'Cancel',
        icon: 'fa-times',
        emphasized: true,
        buttonClasses: 'btn-danger',
        action: () => {
          toggleEditModeDescription();
        },
      },
      {
        title: 'Save',
        icon: 'fa-save',
        type: 'primary',
        emphasized: true,
        action: () => {
          saveDescription();
        },
      },
    ];
  }

  return [
    {
      title: 'Edit',
      icon: 'fa-pencil',
      action: () => {
        toggleEditModeDescription();
      },
    },
  ];
});

const selectedShowTime = ref(null);

const statusBarOfEventType = () => {
  return getItemFromArrayBasedOnId(props.invite.event_type_id, eventTypes.value, { status_bar: [] })?.status_bar;
};
</script>

<template>
  <div
    :style="isSmallScreen ? 'height: calc( ' + height + ' - 180px)' : ''"
    :class="{ 'border-t': !isSmallScreen }"
    class="grid h-full gap-edge p-edge md:grid-cols-[300px_1fr] md:border-none">
    <div
      v-if="showStatusbar && !isSmallScreen"
      class="col-span-2">
      <BoxContainer>
        <div>
          <div class="flex items-center mb-edge-1/2">
            <div class="flex w-full items-center justify-between">
              <div class="flex items-center gap-edge-1/4">
                <InputLabel
                  label="Status: "
                  class="!mb-0"
                  super-text />
                <div class="text-sm font-semibold text">
                  <span> {{ props.invite.status_bar_number }} {{ getStatusOfInvite() }} </span>
                </div>
              </div>

              <VButton
                v-if="admin && invite.status_bar_number > 0"
                tool-tip-text="Reset the Status Bar counter to 0"
                icon="fa-rotate-left text-soft hover:text"
                :stop-click="true"
                @click="updateStatusBarNumber(0)">
              </VButton>
            </div>
          </div>
          <IntermediateStepStatusBar
            v-if="statusBarOfEventType().length > 0"
            :status-bar="statusBarOfEventType()"
            :status="props.invite.status_bar_number"
            :can-edit="admin"
            @update:status="admin && $event !== invite.status_bar_number ? updateStatusBarNumber($event) : null" />
        </div>
      </BoxContainer>
    </div>

    <div class="flex flex-col gap-edge row-span-2 pb-edge">
      <div>
        <BoxContainer
          title="Details"
          :actions="
            [
              isOwner && !festival && admin
                ? {
                    title: 'Add Show',
                    icon: 'fa-plus',
                    primary: false,
                    action: () => {
                      selectedShowTime = null;
                      nextTick(() => {
                        selectedShowTime = { id: null };
                      });
                    },
                  }
                : null,
            ].filter((i) => i !== null)
          "
          :header-size="'h3'">
          <div class="flex flex-col gap-edge">
            <div v-if="invite.hasOwnProperty('show_times') && invite.show_times.length >= 0">
              <div class="space-y-edge">
                <IntermediateInfoTabDetail
                  v-for="(showTime, index) in invite.show_times"
                  :key="showTime.id"
                  icon-container-class="bg-[hsl(var(--color-event-type-red))]"
                  icon="fa-play">
                  <div :class="{ 'grid grid-cols-[auto_50px] pr-edge': isOwner && admin }">
                    <div class="flex flex-col gap-[2px]">
                      <div class="text-sm flex flex-col">
                        {{ showTime.title ? showTime.title : 'Show Time #' + (index + 1) }}
                        <div
                          v-if="showTime.room"
                          class="flex">
                          <DisplayBadge
                            size="tiny"
                            :text="'@ ' + showTime.room" />
                        </div>
                      </div>
                      <div class="flex gap-[5px] text-sm">
                        <span v-if="formattedDate(showTime.start)">
                          {{ formattedDate(showTime.start) }}
                        </span>
                        <span>
                          {{ formattedDateTime(showTime.start, null) }} -
                          {{ formattedDateTime(showTime.end, showTime.start) }}
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="isOwner && admin"
                      class="group">
                      <VButton
                        size="xs"
                        title="Edit"
                        post-icon="invisible group-hover/box:visible"
                        @click="
                          () => {
                            selectedShowTime = null;
                            nextTick(() => {
                              selectedShowTime = showTime;
                            });
                          }
                        ">
                        <template #title>
                          <span class="invisible group-hover:visible mr-edge-1/4"> Edit </span>
                        </template>
                        <template #post>
                          <i class="fa fa-fw fa-pencil group-hover/box:visible invisible" />
                        </template>
                      </VButton>
                    </div>
                  </div>
                </IntermediateInfoTabDetail>

                <IntermediateInfoTabDetail
                  v-if="!invite.show_times?.length"
                  icon-container-class="bg-[hsl(var(--color-event-type-red))]"
                  icon="fa-play">
                  <div class="text-soft text-sm italic">No shows added</div>
                </IntermediateInfoTabDetail>
              </div>
            </div>

            <IntermediateInfoTabDetail
              v-if="invite.event.recurring_original_id && invite.event.recurring_dates"
              label="Recurring Dates"
              icon-container-class="bg-[hsl(var(--color-event-type-raspberry))]"
              icon="fa-chain">
              <div class="flex justify-between items-center">
                <RecurringDatesButton
                  :recurring-dates="invite.event?.recurring_dates"
                  :clickable="true"
                  :should-emit="true"
                  :event="invite.event"
                  @change-date="goToOtherEvent">
                  <template #button>
                    <VButton
                      size="sm"
                      :emphasized="true"
                      :title="`#Event ${indexOfRecurringDate}/${event.recurring_dates.length}`" />
                  </template>
                </RecurringDatesButton>

                <VButton
                  v-if="admin && invite.event.owner_type === 'App\\Group' && invite.event.owner_id === groupId"
                  size="sm"
                  icon="fa-chain-broken"
                  title="Unlink"
                  @click="makeSureWantingToUnlink" />
              </div>
            </IntermediateInfoTabDetail>

            <IntermediateStepProductionPeriod
              :invite="invite"
              :can-edit="admin"
              :group-id="groupId"
              :with-room-bookings="venueId !== null"
              @edit-event="$emit('editEvent')"
              @start="$emit('attributeUpdated', { key: 'start', value: $event })"
              @end="$emit('attributeUpdated', { key: 'end', value: $event })" />

            <IntermediateInfoTabDetail
              label="Venue"
              icon-container-class="bg-[hsl(var(--color-event-type-purple))]"
              icon="fa-home">
              {{ event.venue ? event.venue.name : 'N/A' }}
            </IntermediateInfoTabDetail>

            <IntermediateInfoTabDetail
              label="Owner"
              icon-container-class="bg-[hsl(var(--color-event-type-blue))]"
              icon="fa-users">
              {{ event.owner.name }}
            </IntermediateInfoTabDetail>

            <IntermediateInfoTabDetail
              v-if="invite.requester && event.owner_type === 'App\\Group' && event.owner_id === groupId"
              label="Requester"
              icon-container-class="bg-[hsl(var(--color-event-type-yellow-green))]"
              icon="fa-inbox">
              <p
                class="flex cursor-pointer justify-between"
                @click.self="[
                  (requesterModalOpen = false),
                  $nextTick(() => {
                    requesterModalOpen = true;
                  }),
                ]">
                {{ invite.requester.name }}
                <VButton
                  v-if="admin && isNotOnAPartner && invite.event_request_id"
                  size="xs"
                  class="w-[30px]"
                  icon="fa-external-link"
                  @click="openEventRequestForm(invite.event_request_id)" />
              </p>
              <UserProfileModal
                v-if="requesterModalOpen"
                :name="invite.requester.name"
                :email="invite.requester.email"
                :phone="invite.requester.phone"
                :show-image="false"
                :avatar-url="null" />
            </IntermediateInfoTabDetail>

            <IntermediateInfoTabDetail
              v-if="!festival && projectLeaders && projectLeaders.length > 0 && (admin || invite.project_leader_id)"
              label="Project Leader"
              icon="fa-user-tie">
              <template
                v-if="getItemFromArrayBasedOnId(invite.project_leader_id, projectLeaders)"
                #icon>
                <img
                  class="w-[30px] h-[30px] rounded"
                  :src="getMemberImage(getItemFromArrayBasedOnId(invite.project_leader_id, projectLeaders))"
                  alt="" />
              </template>
              <div
                v-if="workingItem === 'project_leader_id'"
                style="height: 26px">
                <i class="fa fa-fw fa-circle-o-notch fa-spin" />
              </div>
              <VSelect
                v-else
                :model-value="invite.project_leader_id"
                :can-edit="admin"
                :is-hidden="true"
                option-value="name"
                nullable
                placeholder="-- Select --"
                wrapper-class="-mx-edge-1/2 [&_.dropdown-element]:p-0"
                nullable-display-text="N/A"
                :in-box="true"
                :options="projectLeaders"
                @update:model-value="selectProjectLeader" />
            </IntermediateInfoTabDetail>

            <IntermediateInfoTabDetail
              v-if="festival && invite.project_leader"
              label="Project Leader"
              icon="fa-user-tie">
              <template
                v-if="invite.project_leader"
                #icon>
                <img
                  class="w-[30px] h-[30px] rounded"
                  :src="getMemberImage(invite.project_leader)"
                  alt="" />
              </template>
              {{ invite.project_leader.name }}
            </IntermediateInfoTabDetail>

            <IntermediateInfoTabDetail
              v-if="!festival && eventTypes && eventTypes.length > 0 && (admin || invite.event_type_id)"
              label="Event Type"
              :icon-container-style-background="
                getItemFromArrayBasedOnId(invite.event_type_id, eventTypes, { color: 'lightgray' }).color
              "
              icon="fa-tag">
              <div
                v-if="workingItem === 'event_type_id'"
                style="height: 26px">
                <i class="fa fa-fw fa-circle-o-notch fa-spin" />
              </div>
              <VSelect
                v-else
                :model-value="invite.event_type_id"
                :can-edit="admin"
                is-hidden
                option-value="name"
                :in-box="true"
                nullable
                sort-alphabetically
                wrapper-class="-ml-edge-1/2 [&_.dropdown-element]:p-0"
                nullable-display-text="N/A"
                placeholder="-- Select --"
                :options="eventTypes"
                @update:model-value="selectEventType">
                <template #singlelabel="{ value }">
                  <div class="truncate-text w-full p-1 hover:bg-content hover:text">
                    <i
                      class="fa fa-fw fa-tag"
                      :style="'color: ' + value.color" />
                    {{ value.name }}
                  </div>
                </template>
                <template #default="{ option }">
                  <div
                    class="truncate-text w-full p-1 hover:text"
                    :class="invite.event_type_id === option.id ? 'text' : 'text-soft'">
                    <i
                      class="fa fa-fw"
                      :class="invite.event_type_id === option.id ? 'fa-check' : 'fa-tag'"
                      :style="'color: ' + option.color" />
                    {{ option.name }}
                  </div>
                </template>
              </VSelect>
            </IntermediateInfoTabDetail>

            <IntermediateInfoTabDetail
              v-if="showStatusbar && isSmallScreen && invite.event_type_id"
              label="Status"
              icon-container-class="bg-[hsl(var(--color-event-type-pistachio))]"
              icon="fa-bars-progress">
              {{ getStatusOfInvite() }}
              <div :id="'status_bar_wrapper_' + invite.id" />
            </IntermediateInfoTabDetail>
          </div>
        </BoxContainer>
      </div>

      <BoxContainer
        v-if="accessLevels.has_shifts"
        :header-size="'h3'"
        title="Shifts">
        <div
          v-if="accessLevels.has_shifts"
          class="[&>div]:flex [&>div]:justify-between">
          <div>
            <span class="text-soft">Total</span>
            <span>{{ totalShifts ?? 0 }} </span>
          </div>
          <div v-if="unapprovedShifts > 0">
            <span class="text-soft">Not Approved: </span>
            <span>{{ unapprovedShifts }} </span>
          </div>
          <div v-if="forSaleShifts">
            <span class="text-soft">Requested/For Sale: </span>
            <span>{{ forSaleShifts }}</span>
          </div>

          <div v-if="unassignedShifts > 0">
            <span class="text-soft">Not Assigned: </span>
            <span>{{ unassignedShifts }} </span>
          </div>

          <div v-if="declinedShifts > 0">
            <span class="text-soft">Declined: </span>
            <span>{{ declinedShifts }} </span>
          </div>
          <div v-if="pendingShifts > 0">
            <span class="text-soft">Pending: </span>
            <span>{{ pendingShifts }} </span>
          </div>

          <div v-if="cancelledShifts > 0">
            <span class="text-soft">Cancelled: </span>
            <span>{{ cancelledShifts }} </span>
          </div>
        </div>
      </BoxContainer>

      <IntermediateStepPerformanceSection
        v-if="!event.recurring_original_id && event.recurring_dates.length === 0"
        :admin="admin"
        :performance-id="performanceId"
        :festival="festival"
        :invite="invite" />
    </div>

    <div class="other-info relative h-full grid gap-edge row-span-2 pb-edge">
      <BoxContainer
        title="Description"
        :header-size="'h3'"
        class="min-h-[140px]"
        :actions="descriptionActions">
        <TextareaInput
          v-if="editModeDescription"
          v-model="editDescription"
          :min-height="60"
          class="-mx-edge-1/2"
          set-focus
          placeholder="Say something about this event" />

        <DisplayText
          v-else-if="onEvent && $page.props?.event && $page.props?.event?.description"
          :content="$page.props.event.description" />

        <DisplayText
          v-else-if="event.description != null"
          :content="event.description" />
        <span
          v-else
          class="text-soft">
          No Description Added
        </span>
      </BoxContainer>

      <IntermediateStepAssignments
        v-if="invite.isAccepted"
        v-model:show="showAssignments"
        :room-bookings="roomBookings"
        :invite="invite"
        :on-event="onEvent"
        :admin="admin" />

      <div v-if="(venueId || festival) && invite.parent_id === null">
        <IntermediateStepRoomBookings
          v-model:show="showRoomBookings"
          :show-recurring-report-downloader="event.recurring_original_id !== null && event.recurring_dates !== null"
          :invite="invite"
          :venue-id="venueId"
          @room-bookings="roomBookings = $event" />
      </div>

      <div v-if="accessLevels.has_shifts">
        <IntermediateStepShifts
          v-model:show="showShifts"
          :group-id="groupId"
          :can-edit="admin"
          :invite="invite" />
      </div>

      <IntermediateStepPartners
        v-if="!festival && accessLevels.has_partners"
        v-model:show="showPartners"
        :group-id="groupId"
        :invite="invite"
        :on-event-page="onEvent"
        :can-edit="admin" />
    </div>
  </div>

  <ShowTimeCRUDModal
    v-if="selectedShowTime"
    :show-time="selectedShowTime"
    :rooms="
      uniqueObjectsFromArray(
        roomBookings.map((b) => {
          return {
            id: b.room_id,
            name: b.room_name,
          };
        })
      )
    "
    :event="event"
    :show-times="invite.show_times"
    @closed="selectedShowTime = null"
    @created="[$emit('reloadInvite'), reloadEventPage()]"
    @updated="[$emit('reloadInvite'), reloadEventPage()]"
    @deleted="[$emit('reloadInvite'), reloadEventPage()]" />

  <EventRequestSlideOut
    v-if="selectedEventRequestId"
    :working="false"
    :event-request-id="selectedEventRequestId"
    :group-id="groupId"
    :venue-id="venueId"
    :on-event="onEvent"
    @closed="selectedEventRequestId = null"
    @show-invite="selectedEventRequestId = null"
    @open-event="[openRoute(getRoute('events.show', invite.event.slug)), (selectedEventRequestId = null)]" />
</template>

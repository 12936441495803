import { useModal } from 'vue-final-modal';
import VDialog from '@/components/Modals/VDialog.vue';
import { useEventListener } from '@vueuse/core';

export const useKeepOpenModal = () => {
  const keepOpenModal = async (
    title = 'Close Modal',
    content = 'Are you sure you want to close the modal?',
    submitOnEnter = true
  ) =>
    new Promise((resolve) => {
      let resolveResponse = false;
      const modelInstance = useModal({
        defaultModelValue: true,
        component: VDialog,
        attrs: {
          title,
          content,
          buttons: [
            {
              class: '!w-[82px]',
              title: 'Close It',
              emit: 'close',
            },
            {
              class: '!w-[125px] !max-w-[150px]',
              type: 'primary',
              title: 'Keep it Open',
              emit: 'keep',
            },
          ],
          async onCancel() {
            resolveResponse = false;
            await modelInstance.close();
          },
          async onClose() {
            resolveResponse = false;
            await modelInstance.close();
          },
          async onKeep() {
            resolveResponse = true;
            await modelInstance.close();
          },
          onClosed() {
            resolve(resolveResponse);
            modelInstance.destroy();
          },
        },
      });
      if (submitOnEnter) {
        useEventListener('keydown', (e) => {
          if (e.key === 'Enter') {
            resolveResponse = true;
            modelInstance.close();
          }
        });
      }
    });

  return { keepOpenModal };
};

import VDialog from '@/components/Modals/VDialog.vue';
import { useModal } from 'vue-final-modal';
import { useEventListener } from '@vueuse/core';
import { ButtonProps } from '@/components/Inputs/VButton.vue';

export const useCertaintyModal = () => {
  const assertCertain = async (
    title: string,
    content: string,
    certainText = 'Yes',
    options = { titleHighlight: '' },
    cancelText = 'Cancel',
    id: string | undefined = undefined,
    submitOnEnter = true,
    firstButtonProps: ButtonProps = {
      emphasized: true,
    },
    secondButtonProps: ButtonProps = {
      type: 'primary',
      emphasized: true,
    }
  ) => {
    return new Promise<boolean>((resolve) => {
      let resolveResponse = false;
      const modelInstance = useModal({
        defaultModelValue: true,
        component: VDialog,
        attrs: {
          title,
          content,
          id,
          titleHighlight: options?.titleHighlight,
          buttons: [
            {
              ...firstButtonProps,
              title: cancelText,
              emit: 'cancel',
            },
            {
              ...secondButtonProps,
              title: certainText,
              emit: 'sure',
            },
          ],
          async onCancel() {
            resolveResponse = false;

            modelInstance.close();
          },
          async onSure() {
            resolveResponse = true;
            modelInstance.close();
          },
          onClosed() {
            resolve(resolveResponse);
            modelInstance.destroy();
          },
        },
      });
      if (submitOnEnter) {
        useEventListener('keydown', (e) => {
          if (e.key === 'Enter') {
            resolveResponse = true;
            modelInstance.close();
          }
        });
      }
    });
  };

  return { assertCertain };
};

<script lang="ts" setup>
import VButton from '@/components/Inputs/VButton.vue';
import IntermediateStepMemberScheduler from '@/components/IntermediateStep/Tabs/IntermediateStepMemberScheduler.vue';
import IntermediateStepRoomScheduler from '@/components/IntermediateStep/Tabs/IntermediateStepRoomScheduler.vue';
import { getEventRequest } from '@/services/api-event-request';
import type { EventRequestResource } from '@/types/event-request';
import { computed, nextTick, ref } from 'vue';
import { useToast } from 'vue-toastification';
import BaseSlideout from '@/components/Base/BaseSlideout.vue';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';
import { formatStartAndEndAsDates } from '@/util/timeFunctions';
import EventRequest from '@/components/Groups/Settings/EventRequest/EventRequest.vue';
import CrudModal from '@/components/Modals/CrudModal.vue';
import { getItemFromArrayBasedOnId, getKey, truncateText } from '@/util/globals';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { useEmitStore } from '@/store/EmitStore';
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';
import { useGroupProjectLeaders } from '@/composables/use-group-project-leaders';
import { useEventRequestTemplates } from '@/composables/use-event-request-templates';
import { useEventRequestResponses } from '@/composables/use-event-request-responses';
import { useEventTypes } from '@/composables/use-event-types';
import { useVenueRooms } from '@/composables/use-venue-rooms';
import SettingToggle from '@/components/Inputs/Components/SettingToggle.vue';
import HoldEventDateSelector from '@/components/Groups/partials/HoldEventDateSelector.vue';

type Props = {
  eventRequestId: number;
  groupId: number;
  venueId: number | null;
  working: boolean;
  onEvent?: boolean;
  isDisplayOnHold?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  onEvent: false,
  isDisplayOnHold: false,
});

const emit = defineEmits<{
  (event: 'update:working', arg: boolean): void;
  (event: 'showInvite', arg: any): void;
  (event: 'accepted', arg: number): void;
  (event: 'declined', arg: number): void;
  (event: 'closed'): void;
  (event: 'openEvent', id: number): void;
  (event: 'removeEventRequest', id: number): void;
  (event: 'confirmHold'): void;
}>();

const toast = useToast();
const { rootEmit } = useEmitStore();

const { projectLeaders, fetchGroupProjectLeaders } = useGroupProjectLeaders(props.groupId, false);
fetchGroupProjectLeaders();
const { eventRequestTemplates, fetch: fetchTemplates } = useEventRequestTemplates('Group', props.groupId, false);
fetchTemplates();
const { eventRequestResponses, fetch: fetchResponses } = useEventRequestResponses('Group', props.groupId, false);
fetchResponses();
const { eventTypes, fetch: fetchEventTypes } = useEventTypes('Group', props.groupId, false);
fetchEventTypes();
const { venueRooms: rooms, fetch: fetchRooms } = useVenueRooms(props.venueId, props.groupId, false);
fetchRooms();

const show = ref(true);
const loading = ref(true);
const initialized = ref(false);
const activeTab = ref<'request' | 'members' | 'rooms'>('request');
const loadedTabs = ref<string[]>([]);

const keyCounter = ref(0);
const editMode = ref(false);

const eventRequestData = ref<EventRequestResource | null>(null);

const fetchEventRequest = async () => {
  loading.value = true;
  const { data } = await getEventRequest(props.eventRequestId);
  eventRequestData.value = data;
  loading.value = false;
  initialized.value = true;
  keyCounter.value++;
};
fetchEventRequest();

const isPending = computed(() => {
  if (!eventRequestData.value) return false;
  return eventRequestData.value.accepted_at === null && eventRequestData.value.declined_at === null;
});
const hasRecurringElements = computed(() => {
  if (!eventRequestData.value) return false;
  return (
    eventRequestData.value.sections.filter((s) => {
      const content = getKey(s, 'content', []);
      return getKey(s, 'type') === 'recurringDates' && content && content.length > 0;
    }).length > 0
  );
});

const tabs = computed(() => {
  return isPending.value
    ? [
        { id: 'request', title: 'Request', icon: 'fa-inbox' },
        { id: 'members', title: 'Members', icon: 'fa-group' },
        props.venueId ? { id: 'rooms', title: 'Rooms', icon: 'fa-cube' } : null,
      ].filter((t) => t !== null)
    : [];
});

const acceptedResponses = computed(() => eventRequestResponses.value.filter((r) => r.type === 'accepted'));
const declinedResponses = computed(() => eventRequestResponses.value.filter((r) => r.type === 'declined'));

const isHold = ref(false);
const holdDates = ref([]);
const isAccepting = ref(true);
const projectLeaderId = ref<number | null>(null);
const eventTypeId = ref(null);
const reasonId = ref<number | null>(null);
const sendEmail = ref<boolean>(true);
const hideMyDetails = ref<boolean>(false);
const responseModalOpen = ref(false);
const otherRemarks = ref(null);

const deleteModalOpen = ref(false);
const deleteEventOnDecline = ref(true);
const sendEmailOnDelete = ref(false);

const ccMeOnEventRequests = ref(false);
const inviteRequesterOnEventRequests = ref(false);

const openAcceptEventRequestModal = (asHold = false) => {
  if (!eventRequestData.value) return;

  const eventRequestTemplate = getItemFromArrayBasedOnId(
    eventRequestData.value.event_request_template_id,
    eventRequestTemplates.value,
    null
  );
  isAccepting.value = true;
  projectLeaderId.value = eventRequestTemplate
    ? eventRequestTemplate.project_leader_id
    : eventRequestData.value.project_leader_id;

  eventTypeId.value = eventRequestTemplate ? eventRequestTemplate.event_type_id : eventRequestData.value.event_type_id;
  isHold.value = asHold;
  reasonId.value = eventRequestTemplate
    ? eventRequestTemplate.event_request_response_id
    : acceptedResponses.value.length
      ? acceptedResponses.value[0].id
      : null;
  responseModalOpen.value = false;
  nextTick(() => {
    responseModalOpen.value = true;
  });
  updateHoldDatesFromRequest();
};

const declineReason = ref<string | null>(null);
const reOpenEventRequest = async () => {
  if (!eventRequestData.value) return;
  const certain = await useCertaintyModal().assertCertain(
    'Re Open Declined Event Request?',
    'Are you sure that you want to re-open this request?',
    'Re-open'
  );
  if (!certain) return;

  await axios.post(`/api/event-requests/${eventRequestData.value.id}/re-open`);
  useToast().success('Event Request Re-Opened');
  emit('removeEventRequest', eventRequestData.value.id);
  await fetchEventRequest();
  rootEmit('pending-event-requests-update');
};

const openDeleteModal = () => {
  sendEmailOnDelete.value = false;
  deleteEventOnDecline.value = true;
  deleteModalOpen.value = false;
  otherRemarks.value = null;
  nextTick(() => {
    deleteModalOpen.value = true;
  });
};

const deleteEventRequest = async () => {
  if (!eventRequestData.value) return;
  if (!deleteModalOpen.value) return;
  await axios.delete(`/api/event-requests/${eventRequestData.value.id}`, {
    params: {
      send_email: sendEmailOnDelete.value,
      delete_event: deleteEventOnDecline.value,
      decline_reason: otherRemarks.value,
    },
  });
  useToast().success('Event Request Deleted');
  emit('removeEventRequest', eventRequestData.value.id);
  show.value = false;
  deleteModalOpen.value = false;
};

const openDeclineEventRequestModal = () => {
  isAccepting.value = false;
  isHold.value = false;
  declineReason.value = '';
  reasonId.value = declinedResponses.value.length ? declinedResponses.value[0].id : null;
  responseModalOpen.value = false;
  nextTick(() => {
    responseModalOpen.value = true;
  });
};

const acceptEventRequest = async () => {
  if (!eventRequestData.value?.id) return;

  responseModalOpen.value = false;
  show.value = false;
  emit('update:working', true);
  const { data } = await axios.post(`/api/event-requests/${eventRequestData.value.id}/accept`, {
    event_type_id: eventTypeId.value,
    project_leader_id: projectLeaderId.value,
    event_request_response_id: reasonId.value,
    other_remarks: otherRemarks.value,
    cc_me: ccMeOnEventRequests.value,
    invite_requester: inviteRequesterOnEventRequests.value,
    send_email: sendEmail.value,
    hide_my_details: hideMyDetails.value,
    is_hold: isHold.value,
    hold_dates: isHold.value ? holdDates.value : [],
  });

  emit('update:working', false);
  toast.success('Event Request was accepted');

  emit('showInvite', data.invite_id);
  emit('accepted', eventRequestData.value.id);
};

const declineEventRequest = async () => {
  if (!eventRequestData.value?.id) return;

  responseModalOpen.value = false;
  emit('update:working', true);
  await axios.post(`/api/event-requests/${eventRequestData.value.id}/decline`, {
    reason: declineReason.value,
    send_email: sendEmail.value,
    hide_my_details: hideMyDetails.value,
    cc_me: ccMeOnEventRequests.value,
    event_request_response_id: reasonId.value,
  });
  emit('update:working', false);
  toast.success('Event Request was declined');
  show.value = false;
  emit('declined', eventRequestData.value.id);
};

const headerActions = computed(() => {
  if (!isPending.value) return [];
  if (activeTab.value !== 'request') return [];
  return [
    {
      icon: editMode.value ? 'fa-save' : 'fa-pencil',
      title: editMode.value ? 'Done' : 'Edit',
      type: editMode.value ? 'primary' : undefined,
      emphasized: true,
      action: () => {
        editMode.value = !editMode.value;
      },
    },
  ];
});

const updateHoldDatesFromRequest = () => {
  holdDates.value = [];
  if (isHold.value && eventRequestData.value) {
    for (let i = 0; i < eventRequestData.value.sections.length; i++) {
      if (eventRequestData.value.sections[i].type === 'alternativeDates') {
        const content = getKey(eventRequestData.value.sections[i], 'content', []);
        if (content) {
          holdDates.value = content.map((d) => {
            return {
              date: d,
            };
          });
        }
      }
    }
  }
};
</script>
<template>
  <BaseSlideout
    v-if="show"
    v-model:active-tab="activeTab"
    :model-value="true"
    :base-z-index="105"
    :menu="tabs"
    width="1000"
    :header-actions="headerActions"
    :footer-link-text="eventRequestData?.accepted_at ? 'Open Event' : null"
    :header-text="eventRequestData?.name"
    @update:active-tab="loadedTabs.includes($event) ? null : loadedTabs.push($event)"
    @closed="$emit('closed')">
    <template #overHeader>
      <div class="flex justify-between">
        <h4 class="text-left w-full px-edge mb-[-10px] text">
          {{ formatStartAndEndAsDates(eventRequestData.start, eventRequestData.end, false) }}
        </h4>
      </div>
    </template>
    <template #footerLink>
      <div
        v-if="isDisplayOnHold"
        class="flex w-full justify-end">
        <VButton
          icon="fa-check"
          type="primary"
          title="Confirm"
          @click="emit('confirmHold')" />
      </div>
      <div
        v-else
        class="flex w-full justify-between">
        <VButton
          type="warning"
          :disabled="editMode || working"
          icon="fa-trash fa-regular"
          title="Delete "
          @click="openDeleteModal" />

        <div
          v-if="eventRequestData && !eventRequestData.hold_event"
          class="flex gap-edge">
          <VButton
            v-if="eventRequestData && eventRequestData.accepted_at !== null"
            icon="fa-window-restore"
            title="Open Invite"
            @click="emit('showInvite', eventRequestData?.invite_id)" />
          <VButton
            v-if="eventRequestData && eventRequestData.accepted_at !== null && !onEvent"
            icon="fa-external-link"
            title="Open Event"
            @click="emit('openEvent', eventRequestData?.invite_id)" />
        </div>
        <div
          v-if="eventRequestData && eventRequestData.hold_event"
          class="flex gap-edge">
          <VButton
            v-if="eventRequestData && eventRequestData.accepted_at !== null"
            icon="fa-window-restore"
            title="Open Hold Event"
            @click="emit('showInvite', eventRequestData?.invite_id)" />
        </div>
      </div>
    </template>

    <template #underHeader>
      <div
        class="w-full h-[30px] mt-edge border-t bg-content/50 text-soft border-b flex gap-edge-1/2 items-center px-edge text-sm">
        <template v-if="isPending">
          <i class="fa fa-fw fa-inbox text-pending text-xs" />
          Pending Request
        </template>
        <template v-if="eventRequestData && eventRequestData.accepted_at">
          <i class="fa fa-fw fa-check text-highlight text-xs" />
          Accepted
        </template>
        <template v-if="eventRequestData && eventRequestData.declined_at">
          <i class="fa fa-fw fa-ban text-warning text-xs" />
          Declined
        </template>
      </div>
    </template>
    <div
      v-if="!initialized"
      class="mt-[24px] h-full items-center overflow-hidden text-center">
      <IconWithLoading
        loading
        classes="text-4xl" />
    </div>
    <div
      v-if="initialized && eventRequestData"
      class="h-full bg-content-main overflow-auto">
      <EventRequest
        v-show="activeTab === 'request'"
        :key="keyCounter"
        :model-id="groupId"
        model-type="Group"
        :edit-mode="editMode"
        :rooms="rooms"
        :event-request="eventRequestData"
        @updated="[
          (eventRequestData.start = getKey($event, 'start', eventRequestData.start)),
          (eventRequestData.end = getKey($event, 'end', eventRequestData.end)),
          (eventRequestData.name = getKey($event, 'name', eventRequestData.name)),
        ]" />

      <IntermediateStepMemberScheduler
        v-if="loadedTabs.includes('members')"
        v-show="activeTab === 'members'"
        :can-edit="false"
        :group-id="groupId"
        :has-shifts="false"
        :is-past-event="false"
        :start-date="eventRequestData?.start" />

      <IntermediateStepRoomScheduler
        v-if="loadedTabs.includes('rooms')"
        v-show="activeTab === 'rooms'"
        :event-start="eventRequestData?.start"
        :venue-id="venueId"
        :group-id="groupId"
        :can-edit="false" />
    </div>
    <template
      v-if="isPending || (eventRequestData && eventRequestData.declined_at !== null)"
      #footer>
      <div class="flex justify-between p-edge">
        <div class="flex gap-edge">
          <VButton
            v-if="isPending"
            type="warning"
            title="decline"
            @click="openDeclineEventRequestModal" />
          <VButton
            v-if="isPending"
            :disabled="editMode || working"
            tool-tip-text="Delete"
            title="Delete"
            type="warning"
            @click="openDeleteModal" />
        </div>

        <div
          v-if="isPending"
          class="flex h-full items-center justify-end gap-edge">
          <VButton
            title="Accept as Hold"
            :emphasized="true"
            disabled-tool-tip-text="This Event Request is recurring, and can therefore not be accepted as a hold."
            :disabled="editMode || working || hasRecurringElements"
            @click="openAcceptEventRequestModal(true)" />
          <VButton
            title="Accept"
            :disabled="editMode || working"
            type="primary"
            :emphasized="true"
            @click="openAcceptEventRequestModal()" />
        </div>
        <div
          v-if="eventRequestData && eventRequestData.declined_at !== null"
          class="flex h-full items-center justify-end gap-edge">
          <VButton
            type="warning"
            :disabled="editMode || working"
            title="Re-open Declined Request"
            @click="reOpenEventRequest" />
        </div>
      </div>
    </template>
  </BaseSlideout>

  <CrudModal
    v-if="responseModalOpen && isPending"
    :create-button-text="isAccepting ? 'Accept Event Request' : 'Decline Event Request'"
    :title="isAccepting ? `Accept ${eventRequestData?.name}` : `Decline ${eventRequestData?.name}`"
    :title-highlight="eventRequestData?.name"
    @closed="responseModalOpen = false"
    @create="isAccepting ? acceptEventRequest() : declineEventRequest()">
    <div class="form-layout grid-cols-2">
      <VSelect
        v-if="isAccepting"
        v-model="isHold"
        :can-edit="!hasRecurringElements"
        label="Event Status"
        :options="[
          { id: false, name: 'Confirmed' },
          { id: true, name: 'Hold' },
        ]"
        @update:model-value="updateHoldDatesFromRequest" />
      <VSelect
        v-if="eventTypes.length && isAccepting"
        v-model="eventTypeId"
        label="Event Type"
        nullable
        :options="eventTypes" />
      <VSelect
        v-if="projectLeaders.length && isAccepting"
        v-model="projectLeaderId"
        label="Project Leader"
        nullable
        :options="projectLeaders" />
      <SettingToggle
        v-model="sendEmail"
        class="mt-6"
        label="Send Email Confirmation" />
      <HoldEventDateSelector
        v-if="isHold && isAccepting"
        v-model:hold-dates="holdDates"
        class="col-span-2"
        :event-date="eventRequestData.start" />
      <VSelect
        v-if="acceptedResponses.length && isAccepting"
        v-model="reasonId"
        class="col-span-2"
        label="Accept Text"
        :can-edit="sendEmail"
        nullable
        option-value="title"
        :options="acceptedResponses.concat({ id: '', title: 'N/A' })" />
      <small
        v-if="isAccepting && sendEmail"
        class="col-span-2 text-soft"
        :title="getItemFromArrayBasedOnId(reasonId, acceptedResponses, { text: '' }).text">
        {{
          truncateText(
            getItemFromArrayBasedOnId(reasonId, acceptedResponses, {
              text: '',
            }).text,
            500
          )
        }}
      </small>

      <TextareaInput
        v-if="isAccepting"
        v-model="otherRemarks"
        class="col-span-2"
        :can-edit="sendEmail"
        label="Other Remarks for Requester" />

      <VSelect
        v-if="declinedResponses.length && !isAccepting"
        v-model="reasonId"
        wrapper-class="col-start-1"
        label="Select Template For Decline Reason"
        :can-edit="sendEmail"
        nullable
        option-value="title"
        :options="declinedResponses" />

      <small
        v-if="!isAccepting && sendEmail"
        class="text-soft col-span-2"
        :title="getItemFromArrayBasedOnId(reasonId, declinedResponses, { text: '' }).text">
        {{
          truncateText(
            getItemFromArrayBasedOnId(reasonId, declinedResponses, {
              text: '',
            }).text,
            500
          )
        }}
      </small>

      <TextareaInput
        v-if="!isAccepting"
        v-model="declineReason"
        class="col-span-2"
        :can-edit="sendEmail"
        label="Reason for decline" />

      <SettingCheck
        v-model="ccMeOnEventRequests"
        class="col-span-2"
        :can-edit="sendEmail"
        label="Send me a copy of the response"
        title="Would you like to be cc on the response email sent to the requester?" />

      <SettingCheck
        v-model="hideMyDetails"
        class="col-span-2"
        :can-edit="sendEmail"
        label="Hide My Details"
        title="Would you like to remove your details from the confirmation email?" />

      <SettingCheck
        v-if="isAccepting"
        v-model="inviteRequesterOnEventRequests"
        class="col-span-2"
        label="Invite requester to event"
        :title="'Would you like to invite ' + eventRequestData?.contact_details?.name + ' to the event?'" />
    </div>
  </CrudModal>

  <CrudModal
    v-if="deleteModalOpen"
    :title="`Delete ${eventRequestData?.name}`"
    :title-highlight="eventRequestData?.name"
    only-close-button
    small
    @closed="deleteModalOpen = false">
    <div class="flex flex-col gap-edge">
      <p class="sub-title text-base text-center text-soft">
        If you delete this request, it can not be restored.
        <span v-if="eventRequestData && eventRequestData.accepted_at !== null"
          >All files, assignments, invitations, running orders and others will be deleted. This is the final
          warning.</span
        >
      </p>

      <h3 class="mt-edge">Options</h3>
      <SettingCheck
        v-if="eventRequestData && eventRequestData.accepted_at !== null"
        v-model="deleteEventOnDecline"
        label="Delete Event"
        title="Would you like to delete the event as well? If disabled, the event will not be deleted." />
      <SettingCheck
        v-model="sendEmailOnDelete"
        label="Send Email to Requester"
        title="Would you like to send an email to requester that the request has been declined?" />

      <TextareaInput
        v-if="sendEmailOnDelete"
        v-model="otherRemarks"
        :min-height="30"
        set-focus
        label="Other Remarks for Requester" />
      <TextareaInput
        v-else
        :can-edit="false"
        :min-height="30"
        set-focus
        label="Other Remarks for Requester" />

      <div class="text-center mt-edge">
        <VButton
          icon="fa-trash fa-regular"
          title="Delete Request"
          type="warning"
          @click="deleteEventRequest" />
      </div>
    </div>
    <div class="form-layout"></div>
  </CrudModal>
</template>

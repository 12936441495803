<script setup lang="ts">
import type { EventRequestResource } from '@/types/event-request';
import { EventRequestForm, EventRequestFormSection } from '@/types/event-request';
import type { ModelType } from '@/types/global';
import type { VenueRoomResource } from '@/types/venue';
import { createUuId, getKey } from '@/util/globals';
import { useDebounceFn } from '@vueuse/core';
import { computed, onMounted, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import EventRequestSectionList from '@/components/Groups/Settings/EventRequest/EventRequestSectionList.vue';

type Props = {
  modelType: ModelType;
  modelId: number;
  eventRequest: EventRequestResource;
  editMode: boolean;
  rooms: VenueRoomResource[];
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'updated', data: object): void;
}>();

const request = ref<EventRequestForm>({
  description: getKey(props.eventRequest, 'description', ''),
  name: getKey(props.eventRequest, 'name', ''),
  start: getKey(props.eventRequest, 'start'),
  end: getKey(props.eventRequest, 'end'),
  request_by_name: props.eventRequest.contact_details.name ?? '',
  request_by_email: props.eventRequest.contact_details.email ?? '',
  request_by_phone: props.eventRequest.contact_details.phone ?? '',
  request_by_country_code: props.eventRequest.contact_details.country_code ?? '',
  requester: props.eventRequest.requester ?? '',
  is_user: props.eventRequest.contact_details.is_user ?? false,
});

const sections = ref<EventRequestFormSection[]>([
  ...props.eventRequest.sections.map((section) => ({ ...section, id: createUuId('section') })),
]);
const loading = ref(false);

const canSave = computed(() => !loading.value);

const saveData = useDebounceFn(async () => {
  loading.value = true;
  try {
    const newRequest = {
      name: request.value.name,
      start: request.value.start,
      end: request.value.end,
      description: request.value.description,
      request_by_email: request.value.request_by_email,
      request_by_name: request.value.request_by_name,
      request_by_phone: request.value.request_by_phone,
      request_by_country_code: request.value.request_by_country_code,
      sections: sections.value.map((section) => ({
        title: section.title,
        description: section.description,
        type: section.type,
        content: section.content,
      })),
    };
    await axios.patch(`/api/event-requests/${props.eventRequest.id}`, newRequest);
    useToast().success('Changes saved');
    emit('updated', newRequest);
  } catch (e) {
    useToast().error('Error saving changes');
  }
  loading.value = false;
}, 500);

watch(
  request,
  () => {
    if (canSave.value && props.editMode) {
      saveData();
    }
  },
  { deep: true }
);

watch(
  sections,
  () => {
    if (canSave.value && props.editMode) {
      saveData();
    }
  },
  { deep: true }
);

const listenForBroadcast = () => {
  Echo.join(`On.${props.modelType}.${props.modelId}`).listen(`.eventRequest.${props.eventRequest.id}.updated`, () => {
    emit('updated', {});
  });
};

const moveSections = ref(false);

onMounted(() => {
  listenForBroadcast();
});
</script>

<template>
  <EventRequestSectionList
    v-model:sections="sections"
    v-model:request="request"
    class="p-edge"
    :event-request="eventRequest"
    :is-answered="eventRequest.accepted_at !== null || eventRequest.declined_at !== null"
    :is-template="false"
    :response-mode="eventRequest.accepted_at === null && eventRequest.declined_at === null"
    :move-sections="moveSections"
    :rooms="rooms"
    :edit-mode="editMode" />
</template>

<script lang="ts" setup>
import BaseSlideout from '@/components/Base/BaseSlideout.vue';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import IntermediateStepContent from '@/components/IntermediateStep/IntermediateStepContent.vue';
import SimpleEventSlideOut from '@/components/Venues/SimpleEventSlideOut.vue';
import { contentListElement, extraPixelsOverContentContainer } from '@/provide/keys';
import { useContextSidebarStore } from '@/store/ContextSidebarStore';
import { EventMinimalResource } from '@/types/event';
import type { InviteResource } from '@/types/invite';
import {
  createUuId,
  exchangeValuesOfObject,
  getAllGroupsAndChildrenICanAccess,
  getIndexFromArrayBasedOnId,
  getItemFromArrayBasedOnId,
  getKey,
  updateItem,
} from '@/util/globals';
import { isAdminOfGroup } from '@/util/group-helpers';
import { getRoute } from '@/util/route';
import { createIntermediateStepTabs } from '@/util/tabs/intermediate-step-tabs';
import { formatStartAndEndAsDates } from '@/util/timeFunctions';
import { computed, provide, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import { checkForQueryParameter } from '@/util/query-helpers';
import SlideoutMenuButton from '@/components/Base/SlideoutMenuButton.vue';
import ModeSelector from '@/components/Inputs/Components/ModeSelector.vue';
import HoldEventSlideOut from '@/components/Models/HoldEvents/HoldEventSlideOut.vue';
import { isAdminOfFestival } from '@/util/festival-functions';

type Props = {
  initalInvites?: InviteResource[];
  initalInviteId?: number | null;
  initialTab?: string | null;
  onEventPage?: boolean;
  halfOpen?: boolean;
  overrideAdmin?: boolean;
  potentiallyASimpleBooking?: boolean;
  performanceId?: number | null;
};

const props = withDefaults(defineProps<Props>(), {
  onEventPage: false,
  halfOpen: false,
  overrideAdmin: false,
  potentiallyASimpleBooking: false,
  initalInviteId: null,
  performanceId: null,
  initialTab: null,
  initalInvites: () => [],
});

const emit = defineEmits<{
  (event: 'closed'): void;
  (event: 'inviteUpdated'): void;
  (event: 'eventUpdated', arg: object): void;
  (event: 'eventCancelled', arg: object): void;
  (event: 'eventDeleted'): void;
  (event: 'eventDuplicated'): void;
  (event: 'refetchEvents'): void;
}>();

const open = ref(!props.halfOpen);

const portalUUID = createUuId('portal-to');

const loading = ref(false);
const invites = ref<InviteResource[]>([]);
const selectedInvite = ref<InviteResource | null>(null);
const event = ref<EventMinimalResource | null>(null);
const festival = ref<InviteResource | null>(null);
const isSimple = ref(false);
const isHold = ref(false);

const fetchInvite = async (inviteId: number) => {
  const { data } = await axios.get(`/api/invites/${inviteId}`);
  return data;
};

type Group = {
  id: number;
  invite_id: number;
  name: string;
  slug: string;
  created: boolean;
  visited: boolean;
  admin: boolean;
};

const groups = ref<Group[]>([]);
const currentGroup = ref<Group | null>(null);

const checkIfAdminOfGroupFromFestival = async (invite: InviteResource) => {
  if (!invite.festival || invite.festival.owner_type !== 'App\\Group') return;
  const isAdmin = await isAdminOfFestival(invite.festival.id, invite.festival.owner_id);
  if (!isAdmin) return;
  const index = getIndexFromArrayBasedOnId(invite.invitable.id, groups.value);
  if (index > -1) {
    groups.value[index].admin = true;
  }
};
const createGroups = async () => {
  groups.value = [];
  invites.value.forEach((item) => {
    if (!item.invitable) return;
    let isAdmin = props.overrideAdmin ? true : isAdminOfGroup(item.invitable.id, item.invitable.parent_id);
    if (!isAdmin) {
      checkIfAdminOfGroupFromFestival(item);
    }
    groups.value.push({
      id: item.invitable.id,
      invite_id: item.id,
      name: item.invitable.name,
      slug: item.invitable.slug,
      created: true,
      visited: false,
      admin: isAdmin,
    });
  });
  invites.value.forEach((item) => {
    if (item.children_invitable_groups?.length) {
      const allGroupNames = getAllGroupsAndChildrenICanAccess();
      item.children_invitable_groups?.forEach(
        (invitedChild: { id: number; invitable_id: number; parent_id: number | null }) => {
          if (invitedChild.parent_id === item.id) {
            if (getIndexFromArrayBasedOnId(invitedChild.invitable_id, groups.value) === -1) {
              groups.value.push({
                id: invitedChild.invitable_id,
                invite_id: invitedChild.id,
                name: getItemFromArrayBasedOnId(invitedChild.invitable_id, allGroupNames, { name: 'Unknown Group' })
                  .name,
                slug: getItemFromArrayBasedOnId(invitedChild.invitable_id, allGroupNames, { slug: null }).slug,
                created: false,
                visited: false,
                admin: isAdminOfGroup(invitedChild.invitable_id, item.invitable.id),
              });
            }
          }
        }
      );
    }
  });
};

const tabs = computed(() => {
  if (isSimple.value) return [];
  if (!selectedInvite.value) return [];

  return createIntermediateStepTabs(selectedInvite.value, props.overrideAdmin);
});
const activeTab = ref<string | null>(null);
if (props.initialTab) {
  activeTab.value = props.initialTab;
}

watch(tabs, () => {
  if (tabs.value.length && !activeTab.value) {
    activeTab.value = tabs.value[0].id;
  }
});

const changeGroup = async (group: Group) => {
  if (group.created) {
    const maybeInvite = invites.value.find((i) => i.invitable?.id === group.id);
    if (!maybeInvite) {
      useToast().error('Invite not found');
      return;
    }
    selectedInvite.value = maybeInvite;
  } else {
    const data = await fetchInvite(group.invite_id);
    invites.value.push(data);
    selectedInvite.value = data;
  }
  group.created = true;
  group.visited = true;
  currentGroup.value = { ...group };
};

const setCurrentGroup = async () => {
  const group = useContextSidebarStore().currentGroup;
  const maybeGroup = groups.value.find((g) => g.id === group?.id);

  if (maybeGroup) {
    await changeGroup(maybeGroup);
    return;
  }

  await changeGroup(groups.value[0]);
};

if (props.halfOpen) {
  watch(
    () => props.initalInvites,
    () => {
      initialize();
    }
  );
}

const initialize = async () => {
  loading.value = true;
  isSimple.value = true;
  isHold.value = false;
  if (props.initalInvites.length > 0) {
    invites.value = [...props.initalInvites];
    selectedInvite.value = { ...props.initalInvites[0] };
  }

  if (props.initalInviteId) {
    const data = await fetchInvite(props.initalInviteId);
    invites.value = [data];
    selectedInvite.value = data;
  }

  if (!selectedInvite.value) {
    useToast().error('No invite found');
    return;
  }

  event.value = selectedInvite.value.event;
  festival.value = selectedInvite.value.festival;
  isSimple.value = selectedInvite.value.is_simple_event;

  isHold.value = selectedInvite.value.hold_event !== null && selectedInvite.value.event.is_hold;

  await createGroups();
  await setCurrentGroup();

  loading.value = false;
};

initialize();

const currentVenueId = computed(() => selectedInvite.value?.invitable?.venue_id);

const currentProps = computed(() => {
  const p = { onEventPage: props.onEventPage, venueId: null, performanceId: null };

  if (currentVenueId.value) p.venueId = currentVenueId.value;

  if (props.performanceId) p.performanceId = props.performanceId;

  return p;
});

const attributeUpdated = (groupId: number, data: any) => {
  const invite = invites.value.find((i) => i.invitable?.id === groupId);

  if (!invite) return;

  switch (data.key) {
    case 'event_type_id':
    case 'project_leader_id':
    case 'start':
    case 'end':
    case 'status_bar_number': {
      invite[data.key] = data.value;
      if (groupId === selectedInvite.value.invitable.id) {
        selectedInvite.value[data.key] = data.value;
      }
      break;
    }
    default: {
      break;
    }
  }
  emit('inviteUpdated');
};

const reloadInvite = async (inviteId: number) => {
  loading.value = true;
  const { data: newInvite } = await axios.get(`/api/invites/${inviteId}`);
  exchangeValuesOfObject(newInvite, invites.value);
  selectedInvite.value = newInvite;
  event.value = newInvite.event;
  festival.value = newInvite.festival;
  await createGroups();
  await setCurrentGroup();
  loading.value = false;
};
const getFooterContent = (giveLink = false) => {
  if (!selectedInvite.value) return null;

  if (props.onEventPage) {
    if (selectedInvite.value.festival) {
      return giveLink
        ? getRoute('festivals.show', selectedInvite.value.festival.slug)
        : selectedInvite.value.festival.name;
    }
    return giveLink
      ? getRoute('groups.show', selectedInvite.value.invitable?.slug)
      : selectedInvite.value.invitable?.name;
  }
  if (selectedInvite.value?.event) {
    return giveLink ? getRoute('events.show', selectedInvite.value.event.slug) : 'Go to Event';
  }
};

const convertToEvent = async () => {
  loading.value = true;
  isSimple.value = false;
  await reloadInvite(selectedInvite.value.id);
};

const waitHalfASecond = ref(!props.potentiallyASimpleBooking);
setTimeout(() => {
  waitHalfASecond.value = true;
}, 60);

const extraHeight = ref(groups.value.length > 1 ? (props.onEventPage ? 0 : 150) : props.onEventPage ? -35 : 111);

watch(groups, () => {
  extraHeight.value = groups.value.length > 1 ? (props.onEventPage ? 0 : 150) : props.onEventPage ? -35 : 111;
});

provide(extraPixelsOverContentContainer, extraHeight);

provide(contentListElement, '#intermediate-step-content');

const inviteIdToBeOpened = checkForQueryParameter('invite_id', true);
if (inviteIdToBeOpened) {
  const index = groups.value.findIndex((i) => i.invite_id === Number(inviteIdToBeOpened));
  if (index > -1) {
    open.value = true;
    changeGroup(groups.value[index]);
    setTimeout(() => {
      const tabToBeOpened = checkForQueryParameter('tab', true);
      if (tabToBeOpened) {
        if (tabs.value.findIndex((t) => t.id === tabToBeOpened) > -1) {
          activeTab.value = tabToBeOpened;
        }
      }
    }, 200);
  }
}
</script>

<template>
  <BaseSlideout
    v-if="!isSimple && waitHalfASecond && !isHold"
    v-model="open"
    v-model:active-tab="activeTab"
    :footer-link-text="getFooterContent()"
    :footer-link-url="getFooterContent(true)"
    :half-open="halfOpen"
    inner-content-class="bg-content-main"
    header-classes="[&_.slide-out-header-text]:text-4xl"
    :header-link="!onEventPage && event ? getRoute('events.show', event?.slug) : null"
    :header-text="event?.name"
    :menu="tabs"
    @closed="$emit('closed')">
    <template #overHeader>
      <div class="flex justify-between">
        <h4 class="text-left w-full px-edge mb-[-10px] text-soft font-mediumbold">
          {{ formatStartAndEndAsDates(event?.start_date, event?.end_date, false) }}
        </h4>
        <div
          :id="portalUUID"
          class="absolute right-0 px-edge" />
      </div>
    </template>

    <template
      v-if="groups.length > 1"
      #secondarySmallScreenSelector>
      <div class="h-[40px] mt-edge">
        <InputLabel
          class="absolute top-[75px] z-[1]"
          label="Group"
          style="left: calc(50% + 18px)"
          super-text />
        <VSelect
          :model-value="currentGroup?.id ?? null"
          :options="
            groups.map((g) => {
              return {
                id: g.id,
                name: g.name,
              };
            })
          "
          wrapper-class="[&>*>*]:!ring-0"
          @update:model-value="changeGroup(getItemFromArrayBasedOnId($event, groups))">
        </VSelect>
      </div>
    </template>

    <template #underHeader>
      <div
        v-if="groups.length > 1"
        class="border-t">
        <div class="w-[70px] text-sm text mt-auto">View As:</div>
        <div class="overflow-auto w-full tiny-scrollbar ml-edge-1/2 mt-auto">
          <ModeSelector
            class="border-none flex-nowrap whitespace-nowrap"
            :model-value="currentGroup?.id ?? null"
            grey-default
            :modes="
              groups.map((g) => {
                return {
                  name: g.name,
                  value: g.id,
                };
              })
            "
            @update:model-value="changeGroup(getItemFromArrayBasedOnId($event, groups))" />
        </div>
      </div>
      <div
        v-if="currentGroup"
        class="w-full text-soft flex gap-edge-1/2 items-center px-edge text-sm"
        :class="{ 'border-t': groups.length < 2 }">
        <i class="fa fa-fw fa-lock text-xs text-pending" />
        <div class="text-sm">
          Only visible to members of
          <span class="font-semibold text">{{ currentGroup.name }}</span>
        </div>
      </div>
    </template>

    <template
      v-if="
        currentGroup &&
        selectedInvite?.parent_id === null &&
        getItemFromArrayBasedOnId(currentGroup.id, groups, { admin: false }).admin
      "
      #bottomAction>
      <SlideoutMenuButton
        :active="activeTab === 'templates' && open"
        icon="fa-plus"
        title="Add Templates"
        @click="[(open = true), (activeTab = 'templates')]" />
    </template>

    <div
      v-if="loading"
      class="my-[50px] h-full items-center overflow-hidden pt-[100px]">
      <IconWithLoading
        classes="text-6xl mx-auto"
        loading />
    </div>

    <div
      v-if="event && !loading"
      id="intermediate-step-content"
      class="h-full bg-content-main"
      data-intro="app-slide-out-container">
      <IntermediateStepContent
        v-for="createdGroup in groups.filter((g) => g.created)"
        :key="createdGroup.id"
        :active-tab="activeTab"
        :admin="createdGroup.admin"
        :current-group-id="currentGroup?.id"
        :event="event"
        :class="currentGroup?.id !== createdGroup.id ? 'hidden' : ''"
        :group-id="createdGroup.id"
        :invite="invites.filter((i) => i.id === createdGroup.invite_id)[0]"
        :is-open="open"
        :portal-u-u-i-d="portalUUID"
        :venue-id="currentVenueId"
        :visited="createdGroup.visited"
        v-bind="currentProps"
        @update-event="event = updateItem($event, event)"
        @event-deleted="[$emit('closed'), emit('eventDeleted')]"
        @event-duplicated="[emit('eventDuplicated', $event)]"
        @event-cancelled="[reloadInvite(createdGroup.invite_id), emit('eventCancelled', $event)]"
        @event-updated="[reloadInvite(createdGroup.invite_id), emit('eventUpdated', $event)]"
        @reload-invite="reloadInvite(createdGroup.invite_id)"
        @attribute-updated="attributeUpdated(createdGroup.id, $event)" />
    </div>
  </BaseSlideout>

  <SimpleEventSlideOut
    v-else-if="isSimple && !loading && !isHold"
    :group="currentGroup"
    :invite="selectedInvite"
    :venue-id="currentVenueId"
    :can-edit="getKey(currentGroup, 'admin', false)"
    @closed="$emit('closed')"
    @updated="$emit('eventDeleted')"
    @no-longer-simple="convertToEvent" />

  <HoldEventSlideOut
    v-else-if="!isSimple && !loading && isHold"
    :group="currentGroup"
    :invite="selectedInvite"
    :venue-id="currentVenueId"
    :can-edit="getKey(currentGroup, 'admin', false)"
    @accepted="[$emit('refetchEvents'), $emit('closed')]"
    @deleted="$emit('eventDeleted')"
    @closed="[$emit('closed')]" />
</template>

<style>
.scroll-shadows {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;

  background:
    linear-gradient(white 30%, green) center top,
    linear-gradient(white, white 70%) center bottom,
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center top,
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center bottom;

  background-repeat: no-repeat;
  background-size:
    100% 40px,
    100% 40px,
    100% 14px,
    100% 14px;
  background-attachment: local, local, scroll, scroll;
}
</style>

<script lang="ts" setup>
import { getDocuments } from '@/services/api-documents';
import { DocumentResource } from '@/types/document';
import { InviteResource } from '@/types/invite';
import { useToast } from 'vue-toastification';
import { computed, nextTick, ref } from 'vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import DocumentRow from '@/components/Documents/DocumentRow.vue';
import DocumentCRUDModal from '@/components/Documents/DocumentCRUDModal.vue';
import ContentList from '@/components/Content/ContentList.vue';
import { exchangeValuesOfObject, getIndexFromArrayBasedOnId } from '@/util/globals';
import { uploadFile } from '@/util/file-upload';

type Props = {
  invite: InviteResource;
  canEdit?: boolean | null;
};

const props = defineProps<Props>();

const toast = useToast();

const loaded = ref(false);
const loading = ref(false);
const documents = ref<DocumentResource[]>([]);

const selectedDocument = ref<DocumentResource | null>(null);

const fetchDocuments = async () => {
  if (loaded.value) return;

  loaded.value = true;
  loading.value = true;

  const { data } = await getDocuments('Invite', props.invite.id);
  documents.value = data;
  loading.value = false;
};

fetchDocuments();

const uploadCompleted = () => {
  loaded.value = false;
  fetchDocuments();
};

const working = ref(false);

const openFileUploader = async () => {
  working.value = true;
  const uploaded = await uploadFile({
    modelType: 'Invite',
    modelId: props.invite.id,
    multiple: true,
  });
  if (uploaded) {
    toast.success('Uploaded Document');
    await nextTick();
    uploadCompleted();
  }
  working.value = false;
};

const documentDeleted = (documentId: number) => {
  const index = getIndexFromArrayBasedOnId(documentId, documents.value);
  index === -1 ? uploadCompleted() : documents.value.splice(index);
};

const documentUploaded = (newDoc: DocumentResource) => {
  documents.value = exchangeValuesOfObject(newDoc, documents.value);
};

const actions = computed(() => {
  return props.canEdit
    ? [
        {
          title: 'Upload',
          emphasized: true,
          icon: working.value ? 'fa-circle-o-notch fa-spin' : 'fa-upload',
          action: () => {
            openFileUploader();
          },
        },
      ]
    : [];
});
</script>

<template>
  <ContentList
    :loading="loading"
    title="Internal Documents"
    :under-title="''"
    :with-back-button="false"
    :empty-message="documents.length === 0 ? 'No Documents found' : ''"
    empty-button-action-text="Upload"
    pre-icon="fa-file"
    :empty-action="
      canEdit
        ? () => {
            openFileUploader();
          }
        : null
    "
    actions-as-buttons
    :actions="actions">
    <VTable
      edge-to-edge
      row-size="medium">
      <!--      <template #head>-->
      <!--        <VTableRow>-->
      <!--          <VTableCell-->
      <!--            style="width: 40%; height: 0"-->
      <!--            colspan="2" />-->
      <!--          <VTableCell style="width: 30%; height: 0" />-->
      <!--          <VTableCell style="width: calc(20% - 50px); height: 0" />-->
      <!--          <VTableCell style="width: 50px; height: 0" />-->
      <!--        </VTableRow>-->
      <!--      </template>-->
      <DocumentRow
        v-for="document in documents"
        :key="'doc_' + document.id"
        :document="document"
        :can-edit="true"
        @edit="selectedDocument = document" />
    </VTable>
  </ContentList>
  <DocumentCRUDModal
    v-if="selectedDocument"
    :init-document="selectedDocument"
    :document-folders="[]"
    :model="'Invite'"
    :model-id="invite.id"
    @closed="selectedDocument = null"
    @updated="documentUploaded"
    @deleted="documentDeleted" />
</template>

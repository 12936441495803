<script setup lang="ts">
import { nextTick, onMounted, ref } from 'vue';
import { InviteResource } from '@/types/invite';
import ContentList from '@/components/Content/ContentList.vue';
import { useGuestListSettings } from '@/composables/use-guest-list-settings';
import GuestListContainer from '@/components/Models/guestLists/GuestListContainer.vue';
import PerformanceGuestListModal from '@/components/performance/PerformanceGuestListModal.vue';
import {
  exchangeValuesOfObject,
  getIndexFromArrayBasedOnId,
  getKey,
  removeItemFromArrayBasedOnId,
} from '@/util/globals';
import { useToast } from 'vue-toastification';
import GuestListReportModal from '@/components/Models/guestLists/GuestListReportModal.vue';

type Props = {
  invite: InviteResource;
  groupId: number;
  canEdit: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  canEdit: false,
});

const fetchingData = ref(true);
const initialFetchDone = ref(false);
const loading = ref(false);
const guestLists = ref([]);
const selectedGuestList = ref(null);

const { guestListSettings, fetch: fetchGuestListSettings } = useGuestListSettings('Group', props.groupId);
const fetchGuestLists = async () => {
  loading.value = true;
  const { data } = await axios.get(`/api/guest-lists/`, {
    params: {
      model_type: 'App\\Invite',
      model_id: props.invite.id,
    },
  });
  loading.value = false;
  initialFetchDone.value = true;
  guestLists.value = data;
};
const openEditModal = async (guestList = null) => {
  if (!guestListSettings.value) return;
  selectedGuestList.value = null;
  await nextTick();
  selectedGuestList.value = { ...guestList, id: getKey(guestList, 'id', null) };
};
const fetchItAll = async () => {
  fetchingData.value = true;
  await fetchGuestListSettings();
  await fetchGuestLists();
  fetchingData.value = false;
};
fetchItAll();

onMounted(() => {
  Echo.join(`Invite.${props.invite.id}`)
    .listen('.guests.created', (guest) => {
      if (getKey(guest, 'changed_by_user_name')) {
        useToast().success(`Guest created by ${guest.changed_by_user_name}`);
      }
      const index = getIndexFromArrayBasedOnId(guest.guest_list_id, guestLists.value);
      if (index === -1) return;
      exchangeValuesOfObject(guest, guestLists.value[index].guests);
    })
    .listen('.guests.updated', (guest) => {
      if (getKey(guest, 'changed_by_user_name')) {
        useToast().success(`Guest updated by ${guest.changed_by_user_name}`);
      }
      const index = getIndexFromArrayBasedOnId(guest.guest_list_id, guestLists.value);
      if (index === -1) return;
      exchangeValuesOfObject(guest, guestLists.value[index].guests);
    })
    .listen('.guests.deleted', (guest) => {
      if (getKey(guest, 'changed_by_user_name')) {
        useToast().success(`Guest removed by ${guest.changed_by_user_name}`);
      }
      const index = getIndexFromArrayBasedOnId(guest.guest_list_id, guestLists.value);
      if (index === -1) return;
      removeItemFromArrayBasedOnId(guest.id, guestLists.value[index].guests);
    });
});
const downloadingReport = ref(false);
const downloadModalOpen = ref(false);
const openDownloadModal = async () => {
  downloadModalOpen.value = false;
  await nextTick();
  downloadModalOpen.value = true;
};
</script>
<template>
  <ContentList
    :loading="loading || fetchingData || !initialFetchDone"
    title="Guest Lists"
    :with-back-button="false"
    empty-icon="fa-ticket fa-regular"
    content-slot-classes="mt-edge"
    :empty-message="guestLists.length === 0 && !loading ? 'No Guest Lists Have Been Added' : null"
    :empty-action="
      canEdit && guestListSettings
        ? () => {
            openEditModal(null);
          }
        : null
    "
    :actions="[
      {
        title: 'Download',
        icon: 'fa-download fa-regular',
        emphasized: true,
        loading: downloadingReport,
        action: () => {
          openDownloadModal();
        },
      },
      canEdit && guestListSettings
        ? {
            title: 'Create',
            icon: 'fa-plus',
            type: 'primary',
            action: () => {
              openEditModal(null);
            },
          }
        : null,
    ]">
    <GuestListContainer
      v-for="(guestList, index) in guestLists"
      :key="guestList.id"
      :guest-list="guestList"
      :can-edit="canEdit"
      :guest-list-settings="guestListSettings"
      @update:guest-list="guestLists[index] = $event"
      @edit="openEditModal(guestList)" />
  </ContentList>

  <PerformanceGuestListModal
    v-if="selectedGuestList"
    :guest-list-settings="guestListSettings"
    :initial-guest-list="selectedGuestList"
    :model-type="'App\\Invite'"
    :model-id="invite.id"
    :accreditation-levels="[]"
    :default-start="invite.start"
    :default-end="invite.end"
    @fetch="fetchGuestLists()"
    @created="fetchGuestLists()"
    @updated="exchangeValuesOfObject($event, guestLists)"
    @closed="selectedGuestList = null" />

  <GuestListReportModal
    v-if="downloadModalOpen"
    v-model:downloading="downloadingReport"
    :guest-lists="guestLists"
    :guest-list-settings="guestListSettings" />
</template>

import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { usePageTabsStore } from '@/store/PageTabsStore';
import { useSmallScreen } from '@/composables/use-small-screen';
import { getCssVariable, setCssVariable } from '@/util/globals';
import { goBack } from '@/util/route';

export const useNavBarTitleStore = defineStore('nav-title-store', () => {
  const { isSmallScreen } = useSmallScreen();

  const navBarTitle = ref<string | null>(null);
  const navBarTitleIcon = ref('');
  const navBarTitleLarge = ref(false);
  const navBarBackButton = ref(false);
  const navBarMinimum = ref(false);
  const allowItemsInSubNav = ref(true);
  const goBackFn = ref<() => void>(() => {
    goBack();
  });

  const reset = () => {
    navBarTitle.value = null;
    navBarBackButton.value = false;
    navBarMinimum.value = false;
    allowItemsInSubNav.value = true;
    goBackFn.value = () => {
      goBack();
    };
    setCssVariable('--main-content-height-reducer', '0px');
  };

  const updateVariables = () => {
    const pageTabsStore = usePageTabsStore();
    if (!isSmallScreen.value && navBarMinimum.value) {
      setCssVariable('--navbar-current-height', getCssVariable('--navbar-minimum-height'));
    } else if (navBarTitle.value && pageTabsStore.tabs.length > 0 && pageTabsStore.inNavbar && !isSmallScreen.value) {
      setCssVariable('--navbar-current-height', getCssVariable('--navbar-full-height'));
    } else {
      setCssVariable('--navbar-current-height', getCssVariable('--navbar-height'));
    }
  };

  watch(
    navBarMinimum,
    () => {
      updateVariables();
    },
    { immediate: true, deep: true }
  );
  watch(
    navBarTitle,
    () => {
      updateVariables();
    },
    { immediate: true, deep: true }
  );
  return {
    allowItemsInSubNav,
    navBarBackButton,
    navBarTitleLarge,
    navBarTitleIcon,
    navBarTitle,
    navBarMinimum,
    reset,
    goBackFn,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNavBarTitleStore, import.meta.hot));
}

<script setup lang="ts">
import { useEmitStore } from '@/store/EmitStore';
import { InviteResource } from '@/types/invite';
import { ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import { useRecurringModal } from '@/composables/modals/use-recurring-modal';
import { addTimelineTemplateToEvent } from '@/util/timeline-templates';
import VButton from '@/components/Inputs/VButton.vue';
import DisplayModal from '@/components/Modals/DisplayModal.vue';
import CustomForm from '@/components/Models/Forms/CustomForm.vue';
import Board from '@/components/Models/Boards/Board.vue';
import RunningOrder from '@/components/Models/RunningOrder/RunningOrder.vue';
import { boardStore } from '@/store/boards-store';
import { formsStore } from '@/store/forms-store';
import { checkListStore } from '@/store/check-list-store';
import { runningorderStore } from '@/store/runningorder-store';
import { timelineStore } from '@/store/timeline-store';
import { invoiceStore } from '@/store/invoice-store';
import { router, usePage } from '@inertiajs/vue3';
import { getKey } from '@/util/globals';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import EmptyStateFullPage from '@/components/EmptyState/EmptyStateFullPage.vue';

type ModelTypes = 'Board' | 'RunningOrder' | 'Forms' | 'InvoiceBase' | 'CheckList' | 'TimelineTemplate' | 'Folders';

export type Model = { title: string; icon: string; model: ModelTypes };

type Props = {
  invite: InviteResource;
  model: Model;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'open'): void;
}>();

const toast = useToast();
const { recurringModal } = useRecurringModal();
const { rootEmit } = useEmitStore();
const { fetchBoards } = boardStore();
const { fetchForms } = formsStore();
const { fetchCheckLists } = checkListStore();
const { fetchRunningorder } = runningorderStore();
const { fetchTimelineTemplates } = timelineStore();
const { fetchInvoice } = invoiceStore();

const templates = ref<unknown[]>([]);

const getList = () => {
  switch (props.model.model) {
    case 'Board':
      return boardStore().list;
    case 'RunningOrder':
      return runningorderStore().list;
    case 'Forms':
      return formsStore().list;
    case 'InvoiceBase':
      return invoiceStore().list;
    case 'CheckList':
      return checkListStore().list;
    case 'TimelineTemplate':
      return timelineStore().list;
    case 'Folders': {
      break;
    }
    default:
      throw new Error('Invalid model type');
  }
};

const fetchData = async () => {
  if (!props.invite?.invitable?.id) return;

  switch (props.model.model) {
    case 'Board':
      return fetchBoards('Group', props.invite.invitable.id);
    case 'RunningOrder':
      return fetchRunningorder('Group', props.invite.invitable.id);
    case 'Forms':
      return fetchForms('Group', props.invite.invitable.id);
    case 'InvoiceBase':
      return fetchInvoice('Group', props.invite.invitable.id);
    case 'CheckList':
      return fetchCheckLists('Group', props.invite.invitable.id);
    case 'TimelineTemplate':
      return fetchTimelineTemplates(props.invite.invitable.id);
    case 'Folders':
    default:
      break;
  }
};

const list = getList();

if (list) {
  watch(
    list,
    async () => {
      const data = await fetchData();

      templates.value = data ?? [];
    },
    { deep: true }
  );
}

const isOpen = ref(false);
const working = ref(false);
const hasLoaded = ref(false);

const checkToReloadEvent = (key, hash = null) => {
  const isOnEventPage = usePage().props.hasOwnProperty('event');
  if (!isOnEventPage) return;
  const event = usePage().props.event;
  if (getKey(event, key, true)) return;
  if (hash) {
    window.location.hash = hash;
  }
  router.reload({
    only: ['event'],
  });
};

const fetchTemplates = async () => {
  if (!props.invite?.invitable?.id) return;
  switch (props.model.model) {
    case 'Board': {
      const data = await fetchBoards('Group', props.invite.invitable.id);
      templates.value = data ?? [];

      break;
    }
    case 'RunningOrder': {
      const data = await fetchRunningorder('Group', props.invite.invitable.id);
      templates.value = data ?? [];
      break;
    }
    case 'Forms': {
      const data = await fetchForms('Group', props.invite.invitable.id);
      templates.value = data ?? [];
      break;
    }
    case 'InvoiceBase': {
      const data = await fetchInvoice('Group', props.invite.invitable.id);
      templates.value = data ?? [];
      break;
    }
    case 'CheckList': {
      const data = await fetchCheckLists('Group', props.invite.invitable.id);
      templates.value = data ?? [];
      break;
    }
    case 'TimelineTemplate': {
      const data = await fetchTimelineTemplates(props.invite.invitable.id);
      templates.value = data ?? [];
      break;
    }
    default: {
      break;
    }
  }
  hasLoaded.value = true;
};

const selectTemplateForEvent = async (template: unknown) => {
  if (!props.invite.event?.id) return;

  const addToAll =
    props.invite.recurring_original_id !== null
      ? await recurringModal(
          '',
          'Add Template To Event',
          `Do you want to add ${template.title} to all recurrences of ${props.invite.event.name}, or just this one?`
        )
      : false;

  if (addToAll === 'cancel') return;

  working.value = true;

  switch (props.model.model) {
    case 'Forms': {
      try {
        await axios.post('/api/forms', {
          title: template.title,
          model_type: 'App\\Event',
          model_id: props.invite.event.id,
          parent_id: template.id,
          is_global: addToAll === 'all',
        });
        rootEmit('form-added-to-event');
        checkToReloadEvent('has_forms', 'forms');
        toast.success(`${template.title} has been added to ${props.invite.event.name}`);
      } catch (e) {
        toast.warning('Something went wrong, please try again later.');
        working.value = false;
      }

      break;
    }
    case 'TimelineTemplate': {
      const added = await addTimelineTemplateToEvent(template, props.invite.event, false, true);
      if (added) {
        toast.success(`${template.title} has been added to ${props.invite.event.name}`);
      }
      rootEmit('assignments_added_to_event');
      break;
    }
    case 'CheckList': {
      try {
        await axios.post('/api/check-lists', {
          model_type: 'App\\Invite',
          model_id: props.invite.id,
          template_id: template.id,
          is_global: addToAll === 'all',
        });
        rootEmit('check-list-added-to-event-via-group-add-to-event', {
          inviteId: props.invite.id,
        });
        toast.success(`${template.title} has been added to ${props.invite.event.name}`);
      } catch (e) {
        toast.warning('Something went wrong, please try again later.');
        working.value = false;
        throw e;
      }
      break;
    }
    case 'InvoiceBase': {
      try {
        await axios.post('/api/invoice-bases', {
          title: template.title,
          model_type: 'App\\Invite',
          model_id: props.invite.id,
          template_id: template.id,
          is_global: addToAll === 'all',
        });
        rootEmit('invoice-basis-added-tp-event-via-group-add-to-event', {
          inviteId: props.invite.id,
        });
        toast.success(`${template.title} has been added to ${props.invite.event.name}`);
      } catch (e) {
        working.value = false;
        toast.warning('Something went wrong, please try again later.');
      }
      break;
    }
    case 'Board': {
      await axios.post('/api/boards', {
        title: template.title,
        model_type: 'App\\Event',
        model_id: props.invite.event.id,
        template_id: template.id,
        is_global: addToAll === 'all',
      });
      rootEmit('board-added-to-event');
      checkToReloadEvent('has_boards', 'boards');
      toast.success(`${template.title} has been added to ${props.invite.event.name}`);
      break;
    }
    case 'RunningOrder': {
      await axios.post('/api/runningorders', {
        title: template.title,
        model_type: 'App\\Event',
        model_id: props.invite.event.id,
        template_id: template.id,
        is_global: addToAll === 'all',
      });
      rootEmit('runningorder-added-to-event');
      checkToReloadEvent('has_running_orders', 'running-order');
      toast.success(`${template.title} has been added to ${props.invite.event.name}`);
      break;
    }
    default: {
      throw new Error('Invalid model type');
    }
  }
  working.value = false;
};

const selectTemplateId = (id: number) => {
  const index = _.findIndex(templates.value, (r) => r.id === id);
  if (index > -1) {
    selectTemplateForEvent(templates.value[index]);
  } else {
    toast.warning('Error selecting form');
  }
};

watch(isOpen, (val) => {
  if (val && hasLoaded.value === false) {
    fetchTemplates();
  }
  emit('open', val);
});
</script>

<template>
  <BoxContainer
    v-model="isOpen"
    :title="model.title"
    header-size="h3"
    :content-padding="false"
    :openable="true">
    <EmptyStateFullPage
      v-if="!templates.length && hasLoaded"
      :icon="model.icon + ' fa-regular'"
      size="tiny"
      :button-function="null"
      :description="'No ' + model.title" />
    <VTable
      v-if="templates.length > 0"
      edge-to-edge
      :bordered-table="true">
      <VTableRow
        v-for="template in templates"
        :key="template.id">
        <VTableCell main-cell> {{ template.title }}</VTableCell>
        <VTableCell style="width: 100px">
          <DisplayModal
            v-if="model.model === 'Board'"
            :title="template.title"
            @selected="selectTemplateId(template.id)">
            <Board
              :board="template"
              :can-edit="false"
              :model="'Invite'"
              :is-template="false"
              :model-id="invite.id"
              is-display />
          </DisplayModal>
          <DisplayModal
            v-if="model.model === 'RunningOrder'"
            :title="template.title"
            @selected="selectTemplateId(template.id)">
            <RunningOrder
              :running-order="template"
              :is-template="false"
              :can-edit="false"
              is-display />
          </DisplayModal>

          <DisplayModal
            v-if="model.model === 'Forms'"
            :title="template.title"
            @selected="selectTemplateId(template.id)">
            <CustomForm
              :form="template"
              :model="'Invite'"
              :can-edit="false"
              :is-owner="false"
              :is-template="false"
              :model-id="invite.id"
              is-display />
          </DisplayModal>
        </VTableCell>
        <VTableCell style="width: 100px">
          <VButton
            type="primary"
            emphasized
            class="float-right"
            size="sm"
            title="Add"
            icon="fa-plus"
            :loading="working"
            :disabled="working"
            @click="selectTemplateForEvent(template)" />
        </VTableCell>
      </VTableRow>
    </VTable>
  </BoxContainer>
</template>

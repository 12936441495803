import { usePage } from '@inertiajs/vue3';
import { getKey } from '@/util/globals';

export const festivalSchedulerTableKey = 'festivalSchedulerTable';
export const eventInviteLinksKey = 'eventInviteLinks';
export const eventTypeInlineAdderKey = 'eventTypeInlineAdder';
export const orderReportSetupKey = 'orderReportSetup';

export const hasAccessToFeatureInTesting = (featureKey: null | string) => {
  if (!featureKey) return false;
  if (!usePage().props?.authenticated) return false;
  if (!usePage().props?.auth.user) return false;
  if (usePage().props?.auth.user.is_admin) return true;
  return getKey(usePage().props?.auth?.user, 'test_features', [])?.includes(featureKey);
};

'
<script setup lang="ts">
import { InvoiceBasesResource } from '@/types/invoice-row';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';

type Props = {
  inviteId: number;
  invoice: InvoiceBasesResource;
  canEdit?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  canEdit: false,
});

const emit = defineEmits<{
  (event: 'update:invoice', value: InvoiceBasesResource): void;
}>();

const setText = async (text) => {
  if (props.invoice.text === text) return;
  if (!props.invoice.text && !text) return;
  await axios.patch(`/api/invoice-bases/${props.invoice.id}`, {
    text: text,
  });
  const newInvoice = { ...props.invoice };
  newInvoice.text = text;
  emit('update:invoice', newInvoice);
};
</script>

<template>
  <div>
    <InputLabel label="Order Text"></InputLabel>
    <TextareaInput
      :model-value="invoice.text"
      wrapper-class="-mx-edge-1/2"
      is-hidden
      placeholder="Order Text"
      :min-height="30"
      @blur="setText($event)" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, onBeforeUnmount, nextTick } from 'vue';

type Props = {
  height?: string;
};

withDefaults(defineProps<Props>(), {
  height: 'h-[320px]',
});

const isOpen = ref(false);
const isTruncated = ref(true);
const addTruncate = ref(false);

const container = ref<HTMLDivElement | null>(null);
const content = ref<HTMLDivElement | null>(null);

const checkIfTruncated = async (add = false) => {
  if (add) {
    addTruncate.value = false;
    await nextTick();
  }
  if (container.value && content.value) {
    isTruncated.value = container.value.clientHeight < content.value.clientHeight - 5;
  }
  if (add) {
    addTruncate.value = true;
  }
};

onMounted(() => {
  checkIfTruncated();
  setTimeout(() => {
    checkIfTruncated(true);
  }, 500);
});

const handleResize = () => {
  addTruncate.value = false;
  checkIfTruncated(true);
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<template>
  <div
    v-if="isTruncated"
    class="cursor-pointer"
    @click.stop="isOpen = !isOpen">
    <div
      ref="container"
      :style="addTruncate ? '' : '  visibility: hidden;'"
      :class="(isOpen || !addTruncate ? '' : ' overflow-hidden ') + ' ' + (isOpen ? '' : height)">
      <div ref="content">
        <slot />
      </div>
    </div>
    <div v-if="!isOpen">
      <i class="fas fa-fw fa-ellipsis" />
    </div>
  </div>

  <div v-else>
    <div>
      <div>
        <slot />
      </div>
    </div>
  </div>
</template>

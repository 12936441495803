<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import TextInput from '@/components/Inputs/TextInput.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { createUuId, getItemFromArrayBasedOnId } from '@/util/globals';
import { SortEmit } from '@/components/TestTable.vue';
import { copyObject } from '@/util/object-helpers';

type Props = {
  options: string[] | null;
  unsavedOption: boolean;
  handleOutside?: boolean | null;
  canDrag?: boolean;
  optionsAsObjects?: boolean | null;
};
const props = withDefaults(defineProps<Props>(), {
  canDrag: true,
  handleOutside: false,
  optionsAsObjects: false,
});

const emit = defineEmits<{
  (event: 'update:options', arg: any): void;
  (event: 'update:unsavedOption', arg: boolean): void;
  (event: 'create', arg: string): void;
  (event: 'delete', arg: any): void;
  (event: 'sorted', arg: SortEmit): void;
}>();

const toast = useToast();

const newListOption = ref(null);
const addListOption = () => {
  if (!newListOption.value) return;
  if (newListOption.value.trim().length === 0) return;
  if (props.handleOutside) {
    emit('create', newListOption.value);
    newListOption.value = '';
    return;
  }

  let { options } = props;
  if (options) {
    if (!options.includes(newListOption.value)) options.push(newListOption.value);
  } else {
    options = [newListOption.value];
  }
  nextTick(() => {
    newListOption.value = '';
    emit('update:options', options);
  });
};

const removeListOption = (option, index) => {
  if (props.handleOutside) {
    emit('delete', option);
    return;
  }

  const { options } = props;
  if (index === -1) {
    toast.warning(`Could not find "${option}" in list`);
    return;
  }
  options.splice(index, 1);
  emit('update:options', options);
};

const updateOption = (newValue, index) => {
  let localOptions = props.options;
  localOptions[index] = newValue;
  emit('update:options', localOptions);
};
watch(
  newListOption,
  () => {
    emit('update:unsavedOption', newListOption.value.length > 0);
  },
  { deep: true }
);

const formattedOptions = computed(() => {
  if (props.optionsAsObjects) {
    return props.options;
  }
  return props.options.map((o) => {
    return {
      id: createUuId('option_'),
      value: o,
    };
  });
});

const newOrderOfOptions = async (e: SortEmit) => {
  if (props.optionsAsObjects) {
    emit('sorted', e);
    return;
  }
  const { selectedItem: idOfMovedRow, newOrder: arrayOfIds } = e;

  let localOptions = copyObject(formattedOptions.value);
  const newLocalOptions = arrayOfIds.map((id) => getItemFromArrayBasedOnId(id, [...localOptions]));
  emit(
    'update:options',
    newLocalOptions.map((o) => o.value)
  );
};
</script>

<template>
  <div>
    <TextInput
      v-model="newListOption"
      label="New List Item"
      title="Add the list you want others to be able to select from"
      placeholder="Type an option and press 'Enter'"
      @save="addListOption"
      @clear="newListOption = ''"
      @keydown.enter="addListOption">
      <template #end>
        <div class="space-x-edge-1/2 pr-edge-1/2">
          <VButton
            size="sm"
            :disabled="!newListOption"
            type="primary"
            title="Save"
            emphasized
            icon="fa-save"
            @click="addListOption" />
          <VButton
            size="sm"
            type="warning"
            :disabled="!newListOption"
            icon="fa-times"
            @click="newListOption = ''" />
        </div>
      </template>
    </TextInput>

    <VTable
      v-if="options && options.length > 0"
      class="mt-edge -mx-edge"
      :model-value="formattedOptions"
      edge-to-edge
      :can-drag="canDrag"
      handle-outside
      :items="options"
      @sorted="newOrderOfOptions">
      <template #row="{ item: option, index: index }">
        <VTableCell v-if="canDrag">
          <div class="flex items-center justify-center">
            <i class="fa fa-fw fa-arrows-up-down cursor-grab text-soft" />
          </div>
        </VTableCell>
        <VTableCell
          main-cell
          has-input>
          <slot
            name="option"
            :option="option">
            <TextInput
              :model-value="option.value"
              @blur="updateOption($event, index)">
            </TextInput>
          </slot>
        </VTableCell>
        <VTableCell style="width: 40px">
          <VButton
            size="sm"
            type="primary"
            icon="fa-trash"
            @click="removeListOption(option, index)" />
        </VTableCell>
      </template>
    </VTable>
  </div>
</template>

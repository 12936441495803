'
<script setup lang="ts">
import { InvoiceBasesResource } from '@/types/invoice-row';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { ref } from 'vue';

type Props = {
  inviteId: number;
  invoice: InvoiceBasesResource;
  canEdit?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  canEdit: false,
});

const emit = defineEmits<{
  (event: 'update:invoice', value: InvoiceBasesResource): void;
}>();

const loading = ref(false);
const setReference = async () => {
  if (props.invoice.reference) return;
  if (loading.value) return;
  loading.value = true;
  const { data } = await axios.post(`/api/invoice-bases/${props.invoice.id}/generate-reference`);

  const newInvoice = { ...props.invoice };
  newInvoice.reference = data.reference;
  emit('update:invoice', newInvoice);
  loading.value = false;
};
</script>

<template>
  <div>
    <InputLabel label="Reference"></InputLabel>
    <div v-if="invoice.reference">
      {{ invoice.reference }}
    </div>
    <VButton
      v-else
      size="sm"
      :loading="loading"
      icon="fa-plus fa-regular"
      type="primary"
      @click="setReference()"
      title="Generate Unique Reference" />
  </div>
</template>

import { useModal } from 'vue-final-modal';
import VDialog from '@/components/Modals/VDialog.vue';
import { useEventListener } from '@vueuse/core';

export const useDeleteObjectModal = () => {
  const assertReadyToDeleteModal = async (
    title: string,
    content: string,
    deleteString = 'Delete',
    submitOnEnter = true
  ) =>
    new Promise<boolean>((resolve) => {
      let resolveResponse = false;
      const modelInstance = useModal({
        defaultModelValue: true,
        component: VDialog,
        attrs: {
          title,
          content,
          buttons: [
            {
              title: 'Cancel',
              emit: 'cancel',
            },
            {
              icon: 'fa-trash',
              type: 'warning',
              title: deleteString,
              emit: 'remove',
            },
          ],
          async onRemove() {
            resolveResponse = true;
            await modelInstance.close();
          },
          async onCancel() {
            resolveResponse = false;
            await modelInstance.close();
          },
          onClosed() {
            resolve(resolveResponse);
            modelInstance.destroy();
          },
        },
      });
      if (submitOnEnter) {
        useEventListener('keydown', (e) => {
          if (e.key === 'Enter') {
            resolveResponse = true;
            modelInstance.close();
          }
        });
      }
    });

  return { assertReadyToDeleteModal };
};

import type { ModelType } from '@/types/global';
import { computed, provide, readonly } from 'vue';
import { storeToRefs } from 'pinia';
import { orderReportKey } from '@/provide/keys';
import { formatModel } from '@/util/store-helpers';
import { useOrderReportsStore } from '@/store/OrderReportStore';
import { OrderReportResource } from '@/types/invoice-row';

export const useOrderReports = (
  modelType: ModelType,
  modelId: number,
  shouldProvide = true,
  hasOrderReports = false
) => {
  const { list, loading } = storeToRefs(useOrderReportsStore());
  const {
    fetchOrderReports: fetch,
    addOrUpdateOrderReport: addOrUpdate,
    removeOrderReport: remove,
  } = useOrderReportsStore();

  const orderReports = computed(() => list.value.get(formatModel(modelType, modelId)));

  const fetchOrderReports = async (force = false) => {
    if (!hasOrderReports) return;
    await fetch(modelType, modelId, force);
  };
  const addOrUpdateOrderReport = async (orderReport: OrderReportResource) => {
    if (!hasOrderReports) return;
    await addOrUpdate(modelType, modelId, orderReport);
  };

  const removeOrderReport = async (orderReportId: number) => {
    if (!hasOrderReports) return;
    await remove(modelType, modelId, orderReportId);
  };

  if (shouldProvide) {
    provide(orderReportKey, {
      orderReports: readonly(orderReports),
      loading: readonly(loading),
      fetchOrderReports,
      addOrUpdateOrderReport,
      removeOrderReport,
    });
  }

  return {
    orderReports,
    fetchOrderReports,
    loading,
    addOrUpdateOrderReport,
    removeOrderReport,
  };
};

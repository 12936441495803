<script lang="ts" setup>
import { nextTick, onMounted, ref, useTemplateRef, watch } from 'vue';
import { MaybeElementRef, onClickOutside, useEventListener } from '@vueuse/core';
import { ZIndexHoverBox } from '@/variables/z-indexes';

const {
  xPos,
  yPos,
  teleport = 'body',
  transition = 'fade',
  ignoreElements = [],
  closeOnScroll = false,
  closeOnClick = false,
  listIsInverted = false,
  zIndex = ZIndexHoverBox,
  eventBounds = undefined,
  addY = 0,
  rerenderPositionIndicator = 0,
  anchorToElement = undefined,
} = defineProps<{
  xPos: number;
  yPos: number;
  teleport?: string;
  transition?: string;
  ignoreElements?: (MaybeElementRef | string)[];
  zIndex?: number;
  eventBounds?: DOMRect | null;
  closeOnScroll?: boolean;
  closeOnClick?: boolean;
  listIsInverted?: boolean | null;
  addY?: number;
  rerenderPositionIndicator?: number | null;
  anchorToElement?: HTMLElement | null;
}>();

const emit = defineEmits<{
  (e: 'closed'): void;
  (event: 'update:listIsInverted', value: boolean): void;
}>();

const show = ref(false);
const wrapper = useTemplateRef<HTMLDivElement>('wrapper');

if (closeOnScroll) {
  useEventListener(
    'wheel',
    () => {
      emit('closed');
    },
    { passive: true }
  );
}

const xValue = ref(xPos);
const yValue = ref(yPos);

const updatePositionOfBox = (elem: HTMLDivElement) => {
  if (!elem) return;

  const wrapperBox = elem.getBoundingClientRect();

  if (anchorToElement) {
    // TODO: ADD ANCHOR TO ELEMENT ERIK
  } else if (eventBounds) {
    if (wrapperBox.left < 0) {
      xValue.value = eventBounds.right;
    } else if (wrapperBox.right > window.innerWidth) {
      xValue.value = eventBounds.left - (wrapperBox.width + 4);
    }

    if (wrapperBox.top < 0) {
      yValue.value = eventBounds.bottom;
    } else if (wrapperBox.bottom > window.innerHeight) {
      yValue.value = eventBounds.top - (wrapperBox.height + 4);
    }
  } else {
    if (wrapperBox.left < 0) {
      xValue.value = 0;
    } else if (wrapperBox.right > window.innerWidth) {
      xValue.value = window.innerWidth - (wrapperBox.width + 4);
    }

    if (wrapperBox.top < 0) {
      yValue.value = 0;
    } else if (wrapperBox.bottom > window.innerHeight) {
      yValue.value = window.innerHeight - (wrapperBox.height + 4);
    }
  }

  show.value = true;
};

onMounted(async () => {
  await nextTick();
  if (wrapper.value) {
    updatePositionOfBox(wrapper.value);
  }
});

defineOptions({
  inheritAttrs: false,
});
</script>

<template>
  <teleport :to="teleport">
    <div
      v-if="closeOnClick"
      :style="`z-index: ${zIndex - 1}`"
      class="fixed bg-transparent w-dvw h-dvh top-0 left-0"
      @click="$emit('closed')" />
    <div
      ref="wrapper"
      class="fixed shadow-3xl border rounded overflow-hidden"
      :class="[{ 'invisible': !show }, { ' bottom-0 [&>div]:bg-highlight ': listIsInverted }, $attrs.class]"
      :style="`left: ${xValue}px; top: ${yValue}px; z-index: ${zIndex}; ${$attrs.style}`">
      <slot />
    </div>
  </teleport>
</template>

<script setup lang="ts">
import { keyContacts } from '@/types/event-request';
import { createUuId } from '@/util/globals';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import EmailInput from '@/components/Inputs/EmailInput.vue';
import PhoneInput from '@/components/Inputs/PhoneInput.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import { validEmail } from '@/util/validators';
import { computed, nextTick, ref, watch } from 'vue';
import VButton from '@/components/Inputs/VButton.vue';
import { useSmallScreen } from '@/composables/use-small-screen';

type Props = {
  editMode: boolean;
  isTemplate: boolean;
  eventStart?: string | null;
  content: keyContacts[] | null;
  isNewRequest: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  eventStart: null,
});

const emit = defineEmits<{
  (event: 'update:content', ...args: object[]): void;
}>();

const deleteModal = useDeleteObjectModal();
const { isSmallScreen } = useSmallScreen();

const contacts = ref(
  props.content?.map((a) => ({
    name: a.name,
    email: a.email,
    role: a.role,
    phone: a.phone,
  })) ?? []
);

watch(
  contacts,
  () => {
    emit(
      'update:content',
      contacts.value
        .filter((a) => a.role?.trim().length)
        .filter((a) => validEmail(a.email))
        .map((a) => ({
          name: a.name,
          role: a.role,
          email: a.email,
          phone: a.phone,
        }))
    );
  },
  { deep: true }
);

const newContactName = ref('');
const uniqueId = createUuId('event_request_contacts');

const canSave = computed(() => {
  if (props.editMode && !props.isTemplate) {
    contacts.value.forEach((contact) => {
      if (contact.email === null || !contact.role.length) {
        return false;
      }
    });
    return true;
  }
  return false;
});

const onlyUnique = (value, index, self) => self.indexOf(value) === index;

const alreadyUsedRoles = computed(() => contacts.value.map((c) => c.role).filter(onlyUnique));

const removeContact = async (contact) => {
  const deleteIt = await deleteModal.assertReadyToDeleteModal(
    'Remove Contact',
    'Are you sure you want to delete this contact? It can not be restored.'
  );
  if (!deleteIt) return;
  const index = contacts.value.indexOf(contact);
  if (index === -1) return;
  contacts.value.splice(index, 1);
};

const sortContacts = () => {
  nextTick(() => {
    contacts.value = contacts.value.sort((a, b) => a.name - b.name).sort((a, b) => a.role - b.role);
  });
};

const goToEmailField = (rowId: string) => {
  const elem = document.getElementById(`${rowId}_email`);
  if (elem !== null) {
    elem.childNodes[0].focus();
  }
};

const addRow = () => {
  if (!newContactName.value) {
    return;
  }
  const rowId = createUuId();
  contacts.value.push({
    name: newContactName.value,
    email: '',
    phone: '',
    role: '',
    id: rowId,
  });
  newContactName.value = '';
  sortContacts();

  nextTick(() => {
    goToEmailField(rowId);
  });
};
</script>

<template>
  <h4
    v-if="isTemplate"
    class="text-soft">
    Key Contacts will come here.
  </h4>
  <div v-else>
    <div v-if="contacts.length || editMode">
      <VTable
        row-size="large"
        edge-to-edge
        header-hover>
        <template
          v-if="!isSmallScreen"
          #head>
          <VTableRow head>
            <VTableCell>Name*</VTableCell>
            <VTableCell>Email*</VTableCell>
            <VTableCell>Phone</VTableCell>
            <VTableCell>Role*</VTableCell>
            <VTableCell
              v-if="editMode"
              style="width: 40px" />
          </VTableRow>
        </template>

        <VTableRow
          v-for="contact in contacts"
          :no-background="isSmallScreen">
          <VTableCell main-cell>
            <div
              :class="{ ' border-b-2 pb-edge': isSmallScreen }"
              class="flex flex-col gap-edge">
              <div class="flex gap-edge items-center">
                <TextInput
                  text-wrapper-class="flex-1"
                  :model-value="contact.name"
                  :can-edit="canSave"
                  placeholder="Contact Name"
                  @blur="contact.name = $event" />
                <VButton
                  v-if="isSmallScreen"
                  size="sm"
                  type="warning"
                  class="btn-outline"
                  title="Delete"
                  icon="fa-trash"
                  @click="removeContact(contact)" />
              </div>

              <template v-if="isSmallScreen">
                <EmailInput
                  v-model:email="contact.email"
                  :label="isSmallScreen ? 'Email' : null"
                  :can-edit="canSave" />
                <PhoneInput
                  :phone="contact.phone"
                  :label="isSmallScreen ? 'Phone' : null"
                  :with-country-code="false"
                  :can-edit="canSave"
                  size="block"
                  @phone-blur="contact.phone = $event" />
                <TextInput
                  :model-value="contact.role"
                  :can-edit="canSave"
                  :label="isSmallScreen ? 'Role' : null"
                  :data-list-options="alreadyUsedRoles"
                  placeholder="Contact Role"
                  @blur="contact.role = $event" />
              </template>
            </div>
          </VTableCell>
          <template v-if="!isSmallScreen">
            <VTableCell>
              <EmailInput
                v-model:email="contact.email"
                :label="null"
                :can-edit="canSave" />
            </VTableCell>
            <VTableCell>
              <PhoneInput
                :phone="contact.phone"
                :label="null"
                :with-country-code="false"
                :can-edit="canSave"
                size="block"
                @phone-blur="contact.phone = $event" />
            </VTableCell>
            <VTableCell>
              <TextInput
                :model-value="contact.role"
                :can-edit="canSave"
                :data-list-options="alreadyUsedRoles"
                placeholder="Contact Role"
                @blur="contact.role = $event" />
            </VTableCell>
            <VTableCell v-if="editMode">
              <VButton
                size="sm"
                tool-tip-text="Remove Contact"
                icon="fa-trash"
                @click="removeContact(contact)" />
            </VTableCell>
          </template>
        </VTableRow>
      </VTable>
      <div
        v-if="canSave && isNewRequest"
        :class="{ 'sm:grid w-full sm:grid-cols-[100px_auto]': !isSmallScreen }"
        class="mt-edge">
        <InputLabel label="Add Contact" />
        <TextInput
          :id="'addNewContact_' + uniqueId"
          v-model="newContactName"
          :can-edit="canSave"
          placeholder="Name of Contact"
          action-buttons
          :with-delete-action="false"
          save-action-title="Add"
          with-save-action
          :with-clear-action="false"
          @create="addRow"
          @clear="newContactName = ''"
          @keyup.esc="blur()"
          @blur="addRow"
          @keyup.enter="addRow" />
      </div>
    </div>
    <div
      v-if="!isNewRequest && !contacts.length"
      class="text-center italic text-soft">
      No contacts created.
    </div>
  </div>
</template>

<script setup lang="ts">
import type { AlternativeDates } from '@/types/event-request';
import moment from 'moment';
import { computed, nextTick, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import { dateFormat } from '@/variables/date-format';
import {
  arrayToJoinString,
  createUuId,
  getIndexFromArrayBasedOnId,
  getItemFromArrayBasedOnId,
  getKey,
} from '@/util/globals';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import ChevronToggle from '@/components/Icons/ChevronToggle.vue';
import {
  changeAndFormatStamp,
  formatStampAsDate,
  formatStampAsHumanReadableDate,
  getDiffInInterval,
  getNow,
  timeStampsAreSame,
} from '@/util/timeFunctions';
import { groupRecurringDates } from '@/helpers/recurringFunctions';
import VButton from '@/components/Inputs/VButton.vue';
import RoomBookingTable from '@/components/RoomBookingTable.vue';
import { getRoomBooking } from '@/services/api-room-booking';
import _ from 'lodash';

type Props = {
  content: AlternativeDates | null;
  editMode: boolean;
  isTemplate: boolean;
  rooms: {
    id: number;
    name: string;
  }[];
  eventStart?: string | null;
  eventEnd?: string | null;
  responseMode?: boolean;
  isNewRequest?: boolean;
  isAnswered: boolean;
  roomBookings: string[];
  slug?: string;
  type: string;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (event: 'update:content', arg: Date): void;
  (event: 'changeDate', arg: string): void;
}>();

type Date = {
  id: string;
  date: string;
  bookings: string[];
  open: boolean;
  working: boolean;
};

const toast = useToast();
const certaintyModal = useCertaintyModal();
const roomBookingsLoaded = ref(false);
const hasLoaded = ref(false);
const dates = ref<Date[]>([]);

const changeString = ref(null);
const sectionKey = ref(0);

const orderedDates = computed(() => {
  return _.orderBy(dates.value, 'date');
});

const groupDates = computed(() => {
  return groupRecurringDates(orderedDates.value, 'date');
});

// const minDate = computed(() => {
//   return moment().add('1', 'day').format(dateFormat);
// });
const date = computed(() => {
  return moment(props.eventStart).format(dateFormat);
});

const canSave = computed(() => props.eventStart !== null && props.editMode);

const addBookingToDate = async (booking, date: string) => {
  if (changeString.value !== null || props.isAnswered) return;
  const dateOfEventStart = changeAndFormatStamp({
    stamp: props.eventStart,
    startOf: 'day',
  });
  const start = changeAndFormatStamp({
    stamp: date,
    startOf: 'day',
    addMinutes: getDiffInInterval(booking.start, dateOfEventStart, 'minutes'),
  });
  const end = changeAndFormatStamp({
    stamp: date,
    startOf: 'day',
    addMinutes: getDiffInInterval(booking.end, dateOfEventStart, 'minutes'),
  });
  const localBooking = {
    room_id: booking.room_id,
    start,
    end,
    working: true,
    issues: false,
  };
  if (props.responseMode) {
    const { data } = await getRoomBooking(localBooking.room_id, localBooking.start, localBooking.end);
    localBooking.issues = getKey(data, 'status') !== 'Available';
    localBooking.other_uses = getKey(data, 'other_uses', []);
    localBooking.restrictions = getKey(data, 'restrictions', []);
  } else {
    const { data } = await axios.post(`/event-requests/${props.slug}/available`, {
      room_id: localBooking.room_id,
      start: localBooking.start,
      end: localBooking.end,
    });
    localBooking.issues = getKey(data, 'status') !== 'Available';
  }

  localBooking.working = false;
  sectionKey.value += 1;
  const item = getItemFromArrayBasedOnId(date, dates.value, null, 'date');
  if (!item) return;
  item.working = false;
  item.bookings.push(localBooking);
};

const roomBookingsUpdated = async (newVal, oldVal) => {
  if (!canSave.value) return;
  if (!dates.value.length) return;

  if (props.roomBookings.length) {
    let newBooking = null;
    if (newVal.length > oldVal.length) {
      newVal.forEach((val) => {
        if (!oldVal.includes(val)) {
          newBooking = val;
        }
      });
    }

    for (let i = 0; i < dates.value.length; i++) {
      if (newBooking) {
        await addBookingToDate(newBooking, dates.value[i].date);
      } else {
        await checkBookingsForDate(dates.value[i].date);
      }
    }
  } else {
    for (let i = 0; i < dates.value.length; i++) {
      dates.value[i].bookings = [];
    }
  }
};

const getFormattedDate = (date: string) => formatStampAsDate(date, 'dddd Do [of] MMMM');
const getFormattedMonth = (date: string) => moment(date, dateFormat).format('MMMM YYYY');

const toggleDate = (allDates: string[]) => {
  if (!props.editMode) return;
  if (!allDates.length) {
    dates.value = [];
    return;
  }
  const newDates: Date[] = [];

  allDates.forEach((date) => {
    const i = _.findIndex(dates.value, (d) => moment(d.date).isSame(date, 'day'));
    if (i > -1) {
      newDates.push({ ...dates.value[i] });
    } else {
      newDates.push({
        date: date,
        bookings: [],
        working: false,
        open: false,
      });
      nextTick(() => {
        checkBookingsForDate(date);
      });
    }
  });
  dates.value = newDates;
};

const noIssuesWithDate = (date: Date) => {
  if (date.bookings.length === 0) return true;
  return date.bookings.filter((booking) => booking.issues).length === 0;
};

const issuesWithDateTitle = (selectedDate: Date) => {
  if (selectedDate.bookings.length === 0) return '';

  if (noIssuesWithDate(selectedDate)) {
    return `No issues with ${arrayToJoinString(
      selectedDate.bookings.map((booking) => {
        const index = _.findIndex(props.rooms, (r) => r.id === booking.room_id);
        if (index > -1) {
          return props.rooms[index].name;
        }
      }),
      'or'
    )} on ${getFormattedDate(selectedDate.date)}.`;
  }

  return `${arrayToJoinString(
    selectedDate.bookings
      .filter((booking) => booking.issues)
      .map((booking) => {
        const index = _.findIndex(props.rooms, (r) => r.id === booking.room_id);
        if (index > -1) {
          return props.rooms[index].name;
        }
      })
  )}  are already in use in the requested time frame on ${getFormattedDate(selectedDate.date)}.`;
};

const selectDate = async (date: Date) => {
  if (!props.responseMode) return;

  const certain = await certaintyModal.assertCertain(
    'Change date',
    `Are you sure you want to change the date of the event from ${getFormattedDate(
      props.eventStart
    )} to ${getFormattedDate(date.date)}`
  );
  if (!certain) return;

  changeString.value = `Date changed from ${getFormattedDate(props.eventStart)} to ${getFormattedDate(date.date)}`;
  const localDates = [...dates.value];
  const newDateIndex = _.findIndex(localDates, (i) => formatStampAsDate(i.date) === formatStampAsDate(date.date));
  if (newDateIndex > -1) {
    localDates.splice(newDateIndex, 1);
  }
  const oldEventDateIndex = _.findIndex(
    localDates,
    (i) => formatStampAsDate(i.date) === formatStampAsDate(props.eventStart)
  );
  if (oldEventDateIndex === -1) {
    localDates.push({
      id: createUuId(),
      date: formatStampAsDate(props.eventStart),
      bookings: [],
      working: false,
      open: false,
    });
  }
  dates.value = localDates;
  emitDates();
  emit('changeDate', date.date);
};

const emitDates = () => {
  if (canSave.value)
    emit(
      'update:content',
      dates.value.map((d) => d.date)
    );
};

watch(dates, () => {
  if (canSave.value) emitDates();
});

watch(
  () => props.roomBookings,
  (newVal, oldVal) => {
    if (newVal.length <= oldVal.length) return;
    roomBookingsUpdated(newVal, oldVal);
  },
  { deep: true }
);

watch(
  () => props.eventStart,
  (newVal, oldVal) => {
    if (!props.isNewRequest) return;
    if (!oldVal) return;
    if (newVal === oldVal) return;
    if (timeStampsAreSame(newVal, oldVal, 'day')) {
      hydrate(props.content);
      return;
    }
    if (dates.value.length > 0) {
      switch (props.type) {
        case 'alternativeDates': {
          toast.warning('All Alternative Dates removed.');
          break;
        }
        case 'recurringDates': {
          toast.warning('All Recurring Dates removed.');
          break;
        }
        default: {
          break;
        }
      }
    }
    dates.value = [];
  },
  { deep: true }
);

const removeDate = (date) => {
  if (!props.editMode) return;
  const index = getIndexFromArrayBasedOnId(date, dates.value, 'date');
  if (index === -1) return;
  dates.value.splice(index, 1);
  toast.success('Removed ' + formatStampAsHumanReadableDate(date));
  emitDates();
};

const checkBookingsForDate = async (date: string) => {
  // if (props.roomBookings.length === 0) return;
  const item = getItemFromArrayBasedOnId(date, dates.value, null, 'date');
  if (!item) return;
  item.bookings = [];
  for (let i = 0; i < props.roomBookings.length; i++) {
    await addBookingToDate(props.roomBookings[i], date);
  }
  item.working = false;
};

const hydrate = async (data) => {
  if (data && data.length) {
    dates.value = [];
    for (let i = 0; i < data.length; i++) {
      const d = {
        id: createUuId(),
        date: data[i],
        bookings: [],
        open: false,
        working: true,
      };
      dates.value.push(d);
    }
    for (let i = 0; i < data.length; i++) {
      await checkBookingsForDate(data[i]);
    }
  } else {
    roomBookingsLoaded.value = true;
  }
  hasLoaded.value = true;
};
hydrate(props.content);
</script>

<template>
  <h4
    v-if="isTemplate"
    class="text-soft">
    {{ type === 'alternativeDates' ? 'Alternative dates will come here' : '' }}
    {{ type === 'recurringDates' ? 'Recurring dates will come here' : '' }}
  </h4>
  <div v-else>
    <h4 v-if="changeString">
      {{ changeString }}
    </h4>
    <div class="grid grid-cols-1 md:grid-cols-[300px_auto] gap-edge">
      <div class="flex flex-col gap-edge">
        <h3>
          {{ type === 'alternativeDates' ? 'Primary requested Date' : '' }}
          {{ type === 'recurringDates' ? 'First Date' : '' }}
        </h3>
        <p>
          {{ date ? getFormattedDate(date) : 'Must set Event Date first' }}
        </p>
        <div>
          <h4>Select alternatives</h4>
          <VDatepicker
            class="[&>*]:bg"
            :can-edit="canSave && (isNewRequest || (editMode && type === 'recurringDates'))"
            :model-value="dates.map((d) => d.date)"
            :earliest-date="type === 'recurringDates' ? eventStart : getNow()"
            :disabled-dates="[eventStart]"
            multi-dates
            inline
            @update:model-value="toggleDate" />
        </div>
      </div>
      <div
        :key="sectionKey"
        class="flex flex-col gap-edge">
        <h3>
          {{ type === 'alternativeDates' ? 'Alternative Dates' : '' }}
          {{ type === 'recurringDates' ? 'Recurring Dates' : '' }}
        </h3>

        <div
          v-if="!isNewRequest && !dates.length"
          class="text-center italic text-soft">
          {{ type === 'alternativeDates' ? 'No Alternative Dates added.' : '' }}
          {{ type === 'recurringDates' ? 'No Recurring Dates added.' : '' }}
        </div>

        <div v-for="sortedMonth in groupDates">
          <h4>
            {{ getFormattedMonth(sortedMonth[0].date) }}
          </h4>
          <VTable
            edge-to-edge
            row-size="medium"
            :add-background-classes-to-rows="false"
            rounded-pill-rows>
            <template v-if="!responseMode">
              <VTableRow
                v-for="sortedDate in sortedMonth"
                :key="sortedDate.date"
                :classes="noIssuesWithDate(sortedDate) ? '' : 'ring-warning ring-2'"
                :title="issuesWithDateTitle(sortedDate)">
                <VTableCell style="width: 50px">
                  <IconWithLoading
                    :loading="sortedDate.working"
                    :icon="noIssuesWithDate(sortedDate) ? 'fa-check text-success' : 'fa-info text-warning'" />
                </VTableCell>
                <VTableCell main-cell>
                  {{ getFormattedDate(sortedDate.date) }}
                </VTableCell>
                <VTableCell
                  v-if="editMode"
                  style="width: 50px">
                  <VButton
                    size="sm"
                    icon="fa-trash"
                    @click="removeDate(sortedDate.date)" />
                </VTableCell>
              </VTableRow>
            </template>
            <template v-if="responseMode">
              <template
                v-for="sortedDate2 in sortedMonth"
                :key="sortedDate2">
                <VTableRow
                  :classes="noIssuesWithDate(sortedDate2) ? '' : ' ring-2 ring-warning '"
                  :title="issuesWithDateTitle(sortedDate2)">
                  <VTableCell
                    v-if="isAnswered"
                    style="width: 50px" />
                  <VTableCell
                    v-else-if="sortedDate2.working"
                    style="width: 50px">
                    <i class="fa fa-fw fa-circle-o-notch fa-spin" />
                  </VTableCell>
                  <template v-else>
                    <VTableCell
                      v-if="noIssuesWithDate(sortedDate2)"
                      style="width: 50px">
                      <i class="fa fa-fw fa-check text-success" />
                    </VTableCell>
                    <VTableCell
                      v-else
                      style="width: 50px"
                      class="pointer"
                      @click="sortedDate2.open = !sortedDate2.open">
                      <ChevronToggle :model-value="sortedDate2.open" />
                    </VTableCell>
                  </template>
                  <VTableCell>
                    {{ getFormattedDate(sortedDate2.date) }}
                  </VTableCell>

                  <VTableCell
                    v-if="type === 'alternativeDates' && editMode"
                    classes="!p-0">
                    <VButton
                      size="sm"
                      title="Select"
                      class="float-right"
                      @click="selectDate(sortedDate2)" />
                  </VTableCell>

                  <VTableCell
                    v-if="editMode && type === 'recurringDates'"
                    classes="!p-0"
                    style="width: 50px">
                    <VButton
                      size="sm"
                      icon="fa-trash"
                      @click="removeDate(sortedDate2.date)" />
                  </VTableCell>
                </VTableRow>
                <VTableRow v-if="sortedDate2.open">
                  <VTableCell
                    colspan="100%"
                    classes="border-b-2 border-highlight">
                    <RoomBookingTable
                      v-if="sortedDate2.bookings.length"
                      :rooms="rooms"
                      :room-bookings="sortedDate2.bookings"
                      :start-date="sortedDate2"
                      :can-edit="false" />
                  </VTableCell>
                </VTableRow>
                <transition name="slide-down">
                  <div
                    v-if="!noIssuesWithDate(sortedDate2) && date.open"
                    class="tow_row vcenter">
                    {{ date.bookings }}
                  </div>
                </transition>
              </template>
            </template>
          </VTable>
        </div>
      </div>
    </div>
  </div>
</template>

import { useModal } from 'vue-final-modal';
import VDialogMultiOptions from '@/components/Modals/VDialogMultiOptions.vue';
import { type ButtonProps } from '@/components/Inputs/VButton.vue';

type Arg = {
  title: string;
  description?: string;
  button: {
    text: ButtonProps['title'];
    type: ButtonProps['type'];
  };
  options: {
    value: string | number;
    label: string;
  }[];
  useSelect?: boolean;
};

export const useMultipleButtonsModal = () => {
  const threeButtonModal = async (arg: Arg) =>
    new Promise((resolve) => {
      let resolveResponse: string | number = 'cancel';
      const modelInstance = useModal({
        defaultModelValue: true,
        component: VDialogMultiOptions,
        attrs: {
          title: arg.title,
          buttonText: arg.button.text,
          buttonType: arg.button.type ?? 'primary',
          description: arg.description,
          options: arg.options,
          useSelect: arg.useSelect,
          async onSubmit(event: string | number) {
            resolveResponse = event;
            await modelInstance.close();
          },
          onClosed() {
            resolve(resolveResponse);
            modelInstance.destroy();
          },
        },
      });
    });

  return { threeButtonModal };
};

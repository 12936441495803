<script lang="ts" setup>
import { useSmallScreen } from '@/composables/use-small-screen';
import { nextTick, onMounted, ref, useTemplateRef } from 'vue';
import { tooltip } from '@/util/tooltip';

const {
  label = undefined,
  icon = undefined,
  title = undefined,
  mandatoryText = undefined,
  superText = false,
  isHidden = false,
} = defineProps<{
  label?: string;
  icon?: string;
  title?: string;
  mandatoryText?: string;
  isHidden?: boolean;
  superText?: boolean;
}>();

const { isSmallScreen } = useSmallScreen();

const maxWidth = ref(300);
const element = ref<HTMLDivElement | null>(null);
const getWidthOfElement = () => {
  if (!element.value) return;
  try {
    maxWidth.value = element.value.parentNode.parentNode.offsetWidth;

    if (maxWidth.value === 0) {
      setTimeout(() => {
        getWidthOfElement();
      }, 1000);
    }
  } catch (__) {}
};
[0, 10, 50, 100, 200, 300, 500, 1000, 1500, 2000, 3000, 5000].forEach((delay) => {
  setTimeout(() => {
    getWidthOfElement();
  }, delay);
});

const labelElem = useTemplateRef<HTMLSpanElement>('labelElement');
const mandatoryIcon = useTemplateRef<HTMLSpanElement>('icon');

onMounted(async () => {
  await nextTick();
  if (label) {
    tooltip(labelElem.value, title ?? label);
    if (mandatoryText) {
      tooltip(mandatoryIcon.value, mandatoryText);
    }
  }
});
</script>

<template>
  <label
    v-if="label || $slots.default"
    ref="element"
    :class="
      isHidden
        ? ' '
        : '' + (superText ? ' super-text-input capitalized font-headers text-sm font-mediumbold text-soft ' : '')
    "
    :style="'max-width: ' + maxWidth + 'px'"
    class="indent flex items-center gap-1 font-[--label-font-weight-default] capitalize">
    <slot>
      <i
        v-if="icon"
        class="fa fa-fw"
        :class="icon" />

      <span
        ref="labelElement"
        class="text-sm"
        :class="isSmallScreen ? '' : 'truncate'">
        {{ label }}
      </span>

      <i
        v-if="title"
        class="fa fa-fw fa-info-circle text-sm" />

      <i
        v-if="mandatoryText"
        ref="icon"
        class="fa fa-fw fa-asterisk fa-2xs text-[8px] text-warning" />
    </slot>
  </label>
</template>

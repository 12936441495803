<script setup lang="ts">
import { onMounted, ref } from 'vue';

type Props = {
  statusBar: { number: number; name: string; percent: number }[];
  status: number | null;
  canEdit?: boolean | null;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:status', arg: number): void;
  (event: 'updateEventType', arg: { key: string; value: number | string }): void;
  (event: 'attributeUpdated', arg: { key: string; value: number | string }): void;
}>();

const pxOfEachElement = ref(100);
const wrapper = ref<HTMLDivElement | null>(null);

onMounted(() => {
  setTimeout(() => {
    if (wrapper.value) {
      pxOfEachElement.value = (wrapper.value.offsetWidth - 160) / props.statusBar.length;
      if (pxOfEachElement.value > 120) {
        pxOfEachElement.value = 120;
      }
    }
  }, 100);
});
</script>

<template>
  <div
    ref="wrapper"
    class="flex items-center justify-between min-w-full pb-edge">
    <template
      v-for="step in statusBar"
      :key="step.number">
      <div
        class="flex flex-col items-center relative flex-1 h-[30px] min-w-[100px]"
        :title="step.name"
        @click="emit('update:status', step.number)">
        <div
          class="w-[24px] h-[24px] border-2 bg text-xs aspect-1 flex items-center justify-center rounded-full transition-all"
          :class="{
            '  hover:border-highlight cursor-pointer ': canEdit,
            ' ': step.number === status,
            'border-highlight bg-highlight text-inverted': step.number <= status,
          }">
          {{ step.number }}
        </div>
        <div
          :style="'max-width: ' + pxOfEachElement + 'px'"
          class="text-sm text-center truncate absolute mt-[25px]"
          :class="{ 'text-soft ': step.number < status }">
          {{ step.name }}
        </div>
      </div>
      <div
        v-if="step.number < statusBar.length"
        :class="{ 'border-highlight': step.number < status }"
        class="h-1 w-full transition-all -mx-[50px] border -mt-[7px]" />
    </template>
  </div>
</template>

<script setup lang="ts">
import {
  addFolderToModel,
  getDocumentFolderOnModel,
  getFolderDocuments,
  removeFolderToModel,
} from '@/services/api-documents';
import { useEmitStore } from '@/store/EmitStore';
import { DocumentFolderResource } from '@/types/document';
import { InviteAccessLevels, InviteResource } from '@/types/invite';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import IntermediateStepTemplatesSection, {
  type Model,
} from '@/components/IntermediateStep/Tabs/Templates/IntermediateStepTemplatesSection.vue';
import VTable from '@/components/Tables/VTable.vue';
import EventTypeFolderSectionFolder from '@/components/Config/EventTypes/Sections/EventTypeFolderSectionFolder.vue';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';

type Props = {
  groupId: number;
  invite: InviteResource;
  accessLevels: InviteAccessLevels;
};
const props = defineProps<Props>();

const toast = useToast();
const { assertCertain } = useCertaintyModal();
const { rootEmit } = useEmitStore();

const models = ref<Model[]>([]);

if (!props.invite.invitable?.parent_id) {
  models.value.push({
    icon: 'fa-table-cells fa-regular',
    title: 'Boards',
    model: 'Board',
  });
  models.value.push({
    icon: 'fa-table-list fa-regular',
    title: 'Running Orders',
    model: 'RunningOrder',
  });
  models.value.push({
    icon: 'fa-sheet-plastic fa-regular',
    title: 'Forms',
    model: 'Forms',
  });
}

if (props.invite.access_levels.has_invoice_bases) {
  models.value.push({
    icon: 'fa-money fa-regular',
    title: 'Invoice Bases',
    model: 'InvoiceBase',
  });
}
if (props.invite.access_levels.has_check_lists) {
  models.value.push({
    icon: 'fa-list-check fa-regular',
    title: 'Check Lists',
    model: 'CheckList',
  });
}

if (!props.invite.invitable?.parent_id) {
  models.value.push({
    icon: 'fa-list fa-regular',
    title: 'Timeline Templates',
    model: 'TimelineTemplate',
  });
}

const folders = ref<DocumentFolderResource[]>([]);
const connections = ref<Set<number>>(new Set());

const fetchFolders = async () => {
  if (!props.invite.invitable?.id || !props.invite.event?.id) return;

  const { data } = await getFolderDocuments('Group', props.invite.invitable.id);
  folders.value = data;

  const { data: folderIds } = await getDocumentFolderOnModel('Event', props.invite.event.id);

  folderIds.document_folder_ids.forEach((id) => connections.value.add(id));
};

const addFolder = async (folder: DocumentFolderResource) => {
  if (!props.invite.invitable?.id || !props.invite.event?.id) return;

  await addFolderToModel('Event', props.invite.event.id, folder.id);
  connections.value.add(folder.id);
  rootEmit('folder-added-to-event');
  toast.success(`'${folder.name}' has been added to ${props.invite.event.name}`);
};

const removeDocumentFolder = async (folder: DocumentFolderResource) => {
  if (!props.invite.invitable?.id || !props.invite.event?.id) return;

  const c = await assertCertain(
    'Remove Folder?',
    `Are you sure that you want to remove '${folder.name}' from '${props.invite.event.name}'? The event will no longer be able to access these files, and they will be removed from all runningorders and boards. `
  );
  if (!c) return;

  await removeFolderToModel('Event', props.invite.event.id, folder.id);

  toast.success(`'${folder.name}' has been removed from ${props.invite.event.name}`);
  rootEmit('folder-removed-from-event');
  connections.value.delete(folder.id);
};

useEmitStore().$subscribe((_, state) => {
  switch (state.item?.key) {
    case 'folder-removed-from-event': {
      if (state.item?.payload) {
        connections.value.delete(state.item.payload.id);
      }
      break;
    }
    case 'folder-added-to-event': {
      if (state.item?.payload) {
        connections.value.add(state.item.payload.id);
      }
      break;
    }
    default:
      break;
  }
});
</script>

<template>
  <div class="flex flex-col divide-y p-edge gap-edge">
    <IntermediateStepTemplatesSection
      v-for="model in models"
      :key="model.model"
      :invite="invite"
      :model="model" />

    <IntermediateStepTemplatesSection
      v-if="invite.invitable?.parent_id === null"
      :model="{
        model: 'Folders',
        title: 'Document Folders',
        icon: 'fa-folder',
      }"
      :invite="invite"
      @open="fetchFolders()">
      <VTable
        edge-to-edge
        bordered-table>
        <EventTypeFolderSectionFolder
          v-for="folder in folders"
          :key="folder.id"
          :included-ids="connections"
          :number="10"
          :parent-included="connections.has(folder.id)"
          :document-folder="folder"
          @remove-folder="removeDocumentFolder"
          @add-folder="addFolder" />
      </VTable>
    </IntermediateStepTemplatesSection>
  </div>
</template>

<script lang="ts" setup>
import { allCountryCodes } from '@/util/country_codes';
import VSelect from '@/components/Inputs/VSelect.vue';
import { useVModel } from '@vueuse/core';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import { ref, watch } from 'vue';

type Props = {
  countryCode?: string | null;
  phone?: string | null;
  placeholder?: string;
  errorMessage?: string | null;
  canEdit?: boolean;
  isHidden?: boolean;
  label?: string | null;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  withCountryCode?: boolean;
  title?: string;
  size?: 'small' | 'medium' | 'large' | 'block';
  withClear?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  countryCode: null,
  phone: null,
  placeholder: '',
  errorMessage: null,
  canEdit: true,
  disabled: false,
  isHidden: false,
  label: 'Phone',
  required: false,
  readonly: false,
  withCountryCode: true,
  size: 'medium',
  title: '',
  withClear: false,
});

const emit = defineEmits<{
  (e: 'update:countryCode', value: string): void;
  (e: 'update:phone', value: string): void;
  (e: 'phoneBlur', value: string): void;
  (e: 'phoneFocus', value: string): void;
}>();

const code = useVModel(props, 'countryCode', emit);

if (!code.value && props.withCountryCode && props.required) {
  code.value = '+47';
}

const tlf = useVModel(props, 'phone', emit);

const sizes = {
  small: 'w-[140px]',
  medium: 'w-[300px]',
  large: 'w-[400px]',
  block: 'w-full',
};

const dropdownIsOpen = ref(false);

const onKeyDown = (e: KeyboardEvent) => {
  if (!e.key.match(/[a-z]/i)) return;

  const item = allCountryCodes.find((o) => o.name?.toLowerCase().startsWith(e.key.toLowerCase()));
  if (!item) return;

  const element = document.getElementById('phone-' + item.name);

  if (!element) return;

  element.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest',
  });
};

watch(dropdownIsOpen, () => {
  if (dropdownIsOpen.value) {
    window.addEventListener('keydown', onKeyDown);
  } else {
    window.removeEventListener('keydown', onKeyDown);
  }
});

const codes = () => {
  const allCodes = [...allCountryCodes];
  if (code.value && !allCountryCodes.find((o) => o.code === code.value)) {
    allCodes.push({
      name: 'Unknown',
      code: code.value,
      flag: '',
    });
  }
  return allCodes;
};
</script>

<template>
  <div>
    <InputLabel
      v-if="label"
      :is-hidden="isHidden"
      :mandatory-text="required ? 'Mandatory' : null"
      :label="label" />
    <div
      class="flex h-[40px]"
      :class="[sizes[size], isHidden ? 'bg-transparent' : 'bg-inputs-background']">
      <VSelect
        v-if="withCountryCode"
        v-model="code"
        :is-hidden="isHidden"
        :can-edit="canEdit && !disabled"
        wrapper-class="h-full !w-[100px]"
        input-area-class="!rounded-r-none"
        option-key="code"
        :options="codes()"
        placeholder="country code"
        autocomplete="off"
        :hide-arrow="true"
        @dropdown-opened="dropdownIsOpen = true"
        @dropdown-closed="dropdownIsOpen = false">
        <template #single-label="{ value }">
          <div class="flex items-center select-none gap-edge-1/4 h-full">
            <div>{{ value.flag }}</div>
            <div
              v-if="size !== 'small'"
              class="truncate">
              {{ value.code }}
            </div>
          </div>
        </template>

        <template #option-label="{ option }">
          <div
            v-if="option.separator"
            class="w-full border-t cursor-not-allowed hover:select-none"></div>
          <div
            v-else
            :id="`phone-${option.name}`"
            class="flex items-center gap-edge-1/4">
            <span class="w-[24px]"> {{ option.flag }} </span>
            <span class="w-[64px] truncate">{{ option.code }} </span>
            <span class="flex-1">{{ option.name }}</span>
          </div>
        </template>
      </VSelect>
      <div class="relative flex-1">
        <input
          v-model="tlf"
          :class="[
            withCountryCode ? 'rounded-left-none rounded-r' : 'rounded',
            isHidden
              ? ' !bg-transparent disabled:!bg-transparent border-transparent'
              : 'bg-inputs-background border-base hover:border-[--color-border-input-hover]',
          ]"
          :placeholder="canEdit && !disabled ? 'Ex: 55 55 55 55' : placeholder"
          :disabled="disabled || !canEdit"
          :readonly="readonly"
          :title="title"
          class="w-full border disabled:text-disabled disabled:placeholder:text-disabled text-base focus:ring-0 enabled:focus:border-[--color-border-input-focus] disabled:cursor-not-allowed"
          type="tel"
          @focus="$emit('phoneFocus', ($event.target as HTMLInputElement).value)"
          @blur="[$emit('phoneBlur', ($event.target as HTMLInputElement).value)]" />
        <i
          v-if="withClear"
          class="fa fa-fw fa-times absolute top-[12px] right-[4px] cursor-pointer text-soft hover:text"
          @click.stop="tlf = ''" />
      </div>
    </div>
  </div>
</template>

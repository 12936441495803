<script lang="ts" setup>
import { nextTick, onActivated, onBeforeUnmount, onDeactivated, onMounted, ref, useTemplateRef, watch } from 'vue';
import ContentHeader from '@/components/Content/ContentHeader.vue';
import ChevronToggle from '@/components/Icons/ChevronToggle.vue';
import { getQueryString, shouldBeOpened, updateQueryString } from '@/util/query-helpers';
import ActionButtonGroup, { type ActionButtonProps } from '@/components/Inputs/Components/ActionButtonGroup.vue';
import BoxContainer from '@/components/Elements/BoxContainer.vue';

type Props = {
  savingIssue?: boolean;
  loading?: boolean;
  canEdit?: boolean;
  editMode?: boolean;
  fullSize?: boolean;
  startOpen?: boolean;
  title?: string | null;
  superHeader?: string | null;
  preIcon?: string | null;
  postIcon?: string | null;
  withBorder?: boolean;
  actionsInPrimaryHeader?: boolean;
  justContentWithoutHeader?: boolean;
  actions?: ActionButtonProps[];
  actionsAsButtons?: boolean | null;
  overrideQueryString?: string | null;
  extraHeight?: number;
  alignCenter?: boolean | null;
  withSecondBorder?: boolean;
  contentClass?: string;
  fullSizePageContainerClasses?: string | null;
  headerSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  zIndex?: number;
};

const props = withDefaults(defineProps<Props>(), {
  savingIssue: false,
  loading: false,
  canEdit: false,
  editMode: false,
  startOpen: false,
  fullSize: true,
  title: null,
  overrideQueryString: null,
  superHeader: null,
  preIcon: null,
  postIcon: null,
  withBorder: true,
  actionsInPrimaryHeader: false,
  justContentWithoutHeader: false,
  actions: () => [],
  actionsAsButtons: true,
  extraHeight: 0,
  alignCenter: false,
  withSecondBorder: true,
  contentClass: undefined,
  fullSizePageContainerClasses: null,
  headerSize: 'h2',
  zIndex: 102,
});

const emit = defineEmits<{
  (event: 'open', value: boolean): void;
  (event: 'update:editMode', value: boolean): void;
  (event: 'edit'): void;
}>();

// const sameTabClick = inject(adminSameTabClickKey, null) as Ref<boolean>;

// const widthOfContent = inject(widthOfContentKey, ref(100));

const isOpen = ref(false);
const queryString = getQueryString(props.overrideQueryString);

const toggleOpen = (forceOpen = false) => {
  isOpen.value = forceOpen ? true : !isOpen.value;
  emit('open', isOpen.value);
  updateQueryString(queryString, isOpen.value);
};

// if (sameTabClick) {
//   watch(sameTabClick, () => {
//     isOpen.value = false;
//     emit('open', false);
//     updateQueryString(queryString, false);
//   });
// }

onMounted(() => {
  if (shouldBeOpened(queryString)) toggleOpen(true);
  if (props.startOpen) toggleOpen(true);
});

onBeforeUnmount(() => {
  if (queryString) updateQueryString(null, false);
});

const containerWrapper = useTemplateRef<HTMLDivElement>('containerWrapper');
const container = useTemplateRef<HTMLDivElement>('container');

// onMounted(async () => {
//   // navbarHeight.value = document.getElementsByTagName('header')[0]?.offsetHeight;
//
//   // copy size to container
//   await nextTick();
//   const elem = document.getElementById('container-wrapper');
//   if (elem) {
//     // console.log(elem.style.left);
//     console.log(container.value);
//     // container.value.style.left = `${elem.getBoundingClientRect().left}px`;
//     // console.log(elem.getBoundingClientRect());
//   }
// });

const findContainerWrapper = (element: HTMLDivElement) => {
  while (element) {
    if (element.classList && element.classList.contains('container-wrapper')) {
      return element; // Found the parent with the class "container-wrapper"
    }
    element = element.parentElement; // Move up to the next parent
  }
  return null; // No matching ancestor found
};

const setSize = async () => {
  await nextTick();

  if (!containerWrapper.value) return;

  const parent = findContainerWrapper(containerWrapper.value);

  if (parent && container.value) {
    container.value.style.left = `${parent.getBoundingClientRect().left}px`;
    container.value.style.top = `${parent.getBoundingClientRect().top}px`;
    container.value.style.width = `${parent.getBoundingClientRect().width}px`;
    container.value.style.height = `${parent.getBoundingClientRect().height}px`;
  }
};

const resize_ob = new ResizeObserver((e) => {
  setSize();
});

watch(isOpen, () => {
  if (isOpen.value) {
    setSize();
    const parent = findContainerWrapper(containerWrapper.value);
    if (parent) {
      resize_ob.observe(parent);
      window.addEventListener('resize', () => setSize());
    }
  } else {
    resize_ob.disconnect();
    window.removeEventListener('resize', () => setSize());
  }
});

const isActive = ref(true);

onActivated(() => {
  isActive.value = true;
});

onDeactivated(() => {
  isActive.value = false;
});
</script>

<template>
  <div
    v-if="!justContentWithoutHeader"
    ref="containerWrapper"
    :class="{ 'px-edge ': !isOpen }">
    <BoxContainer
      :content-padding="false"
      :padding-bottom="false">
      <div
        class="flex justify-between items-center cursor-pointer hover:bg-hover p-edge"
        @click="toggleOpen(false)">
        <div
          class="w-full"
          :class="!fullSize || preIcon ? '  grid grid-cols-[50px_auto] ' : ' flex flex-1'">
          <div
            v-if="!fullSize || preIcon"
            class="text-center flex items-center justify-center">
            <ChevronToggle
              v-if="!fullSize"
              :model-value="isOpen"
              :loading="loading"
              classes="text-soft " />
            <i
              v-else-if="preIcon"
              class="fa fa-fw text-soft text-lg"
              :class="preIcon" />
          </div>
          <div class="flex flex-col justify-center">
            <div
              v-if="props.superHeader"
              class="sub-title mb-[-5px] text-soft">
              {{ props.superHeader }}
            </div>

            <slot name="title">
              <div class="flex items-center gap-edge">
                <h3>{{ title }}</h3>
                <div
                  v-if="postIcon"
                  class="text-center">
                  <i
                    class="fa fa-fw"
                    :class="postIcon" />
                </div>
              </div>
            </slot>

            <slot name="underHeader" />
          </div>
        </div>
        <div>
          <template v-if="isOpen && actions && !fullSize && actionsInPrimaryHeader">
            <div
              v-if="actionsAsButtons"
              class="h-full pr-edge">
              <ActionButtonGroup :actions="actions" />
            </div>
          </template>
        </div>
        <div
          v-if="!isOpen && fullSize"
          class="flex h-full items-center pr-edge">
          <i class="fa fa-fw fa-chevron-right text-soft"> </i>
        </div>
      </div>
    </BoxContainer>

    <div
      v-if="isOpen && !fullSize"
      class="shadow-border slide-down-content-container">
      <slot name="slideDownContent" />
    </div>

    <teleport
      v-if="isOpen && fullSize"
      :disabled="!isActive"
      to="body">
      <div
        ref="container"
        class="fixed flex flex-col bg-content-main full-size-page-container"
        :style="`z-index: ${zIndex}`"
        :class="fullSizePageContainerClasses">
        <div
          class="full-screen-header"
          :class="{ 'border-b': withSecondBorder }">
          <ContentHeader
            :title="title"
            :super-header="superHeader"
            :loading="loading"
            :pre-icon="preIcon || postIcon"
            with-back-button
            :with-border="withBorder"
            :edit-mode="editMode"
            :actions="actions"
            :header-size="headerSize"
            :actions-as-buttons="actionsAsButtons"
            :align-center="alignCenter"
            @edit="$emit('edit')"
            @back="toggleOpen(false)">
            <template #underTitle>
              <slot name="underHeader" />
            </template>
            <template #menuButton>
              <slot name="menuButton" />
            </template>
            <template #title>
              <slot name="title" />
            </template>
            <template #afterTitle>
              <slot name="afterTitle" />
            </template>
          </ContentHeader>
        </div>
        <div
          class="overflow-auto"
          :class="contentClass">
          <slot name="content" />
        </div>
      </div>
    </teleport>
  </div>
  <div
    v-else
    class="overflow-auto">
    <slot name="content" />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { VueFinalModal } from 'vue-final-modal';
import { ZIndexCrudModal } from '@/variables/z-indexes';

type Props = {
  width: string;
  clickToClose?: boolean;
  shouldTeleport?: boolean;
  bgOverlayBackdrop?: string | null;
  footerClasses?: string | null;
};

withDefaults(defineProps<Props>(), {
  width: '600px',
  clickToClose: true,
  shouldTeleport: true,
  bgOverlayBackdrop: 'bg-[--color-backdrop-modal]',
  footerClasses: '',
});

defineEmits<{
  (e: 'closed', arg: any): void;
  (e: 'clickOutside', arg: () => void, arg2: any): void;
  (e: 'beforeClose', arg: () => void, arg2: any): void;
}>();

const html = ref(null);

defineExpose({
  html,
});

defineSlots<{
  default?: (props: { close: () => void }) => never;
}>();

const show = ref(true);

const closeModal = () => {
  show.value = false;
};

const setIndex = ({ index }: { index: number }) => {
  if (index === 0) return ZIndexCrudModal;
  return ZIndexCrudModal + index;
};
</script>

<template>
  <teleport
    to="body"
    :disabled="!shouldTeleport">
    <VueFinalModal
      ref="html"
      v-model="show"
      :z-index-fn="setIndex"
      :click-to-close="clickToClose"
      :content-transition="{
        'enter-active-class': 'transition duration-300 ease-in-out transform',
        'enter-from-class': 'scale-0 opacity-0',
        'enter-to-class': '',
        'leave-active-class': 'transition duration-200 ease-in-out transform',
        'leave-to-class': 'opacity-0',
        'leave-from-class': '',
      }"
      :focus-trap="false"
      :overlay-class="bgOverlayBackdrop"
      class="flex items-center justify-center"
      :content-style="{ width: width }"
      content-class="relative flex flex-col bg rounded border shadow-lg"
      @click-outside="$emit('clickOutside', closeModal, $event)"
      @before-close="$emit('beforeClose', closeModal, $event)"
      @closed="$emit('closed', $event)">
      <div>
        <slot :close="closeModal" />
      </div>
      <!--      <slot-->
      <!--        name="header"-->
      <!--        :close="closeModal" />-->

      <!--      <div-->
      <!--        v-if="$slots.title"-->
      <!--        class="px-edge pt-edge-1/2 pb-edge bg-pending">-->
      <!--        <slot-->
      <!--          name="title"-->
      <!--          :close="closeModal" />-->
      <!--      </div>-->

      <!--      <div class="max-h-[70vh] overflow-auto p-edge md:max-h-[80vh]">-->
      <!--        <slot :close="closeModal" />-->
      <!--      </div>-->

      <!--      <div-->
      <!--        v-if="$slots.footer"-->
      <!--        :class="footerClasses"-->
      <!--        style="padding-bottom: 1.8rem"-->
      <!--        class="p-edge bg-pending">-->
      <!--        <slot-->
      <!--          name="footer"-->
      <!--          :close="closeModal" />-->
      <!--      </div>-->
      <!--      <div-->
      <!--        v-else-->
      <!--        class="h-[40px]" />-->
    </VueFinalModal>
  </teleport>
</template>

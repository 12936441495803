<script setup lang="ts">
import moment from 'moment';
import { timeFormat } from '@/variables/date-format';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import HoverBox from '@/components/HoverBox.vue';
import CheckBox from '@/components/Icons/CheckBox.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { getKey } from '@/util/globals';
import { nextTick, ref, watch } from 'vue';

const {
  roomBookings = [],
  rooms = [],
  canEdit = false,
  startDate = undefined,
} = defineProps<{
  roomBookings?: Array<unknown>;
  canEdit?: boolean;
  rooms?: Array<unknown>;
  startDate?: string;
}>();

const emit = defineEmits<{
  editBooking: [arg: unknown];
  removeBooking: [arg: unknown];
}>();

const displayBooking = ref('');
const showBooking = ref(false);
const showBookingTimer = ref(null);
const pageY = ref(0);
const pageX = ref(0);

const getRoomName = (roomId) => {
  if (rooms.length) {
    const index = _.findIndex(rooms, (r) => r.id === roomId);
    if (index > -1) {
      return rooms[index].name;
    }
  }
  return '';
};

const moseNotOverBooking = () => {
  if (showBookingTimer.value) {
    clearInterval(showBookingTimer.value);
  } else {
    showBooking.value = false;
    displayBooking.value = null;
  }
};
const clearAll = () => {
  clearInterval(showBookingTimer.value);
  showBooking.value = false;
  displayBooking.value = null;
};

const moseOverBooking = (mouseEnterInfo, booking) => {
  if (!booking.hasOwnProperty('other_uses') && !booking.hasOwnProperty('restrictions')) {
    return;
  }
  if ((booking.other_uses && booking.other_uses.length) || (booking.restrictions && booking.restrictions.length)) {
    pageY.value = mouseEnterInfo.y + 10;
    pageX.value = mouseEnterInfo.x + 10;
    clearAll();
    nextTick(() => {
      showBookingTimer.value = setInterval(() => {
        clearInterval(showBookingTimer.value);
        showBookingTimer.value = null;
        showBooking.value = true;
        displayBooking.value = booking;
      }, 500);
    });
  }
};

const emitEditBooking = (booking) => {
  emit('editBooking', booking);
};

const emitRemoveBooking = (booking) => {
  emit('removeBooking', booking);
};

const formattedDateTime = (time) => {
  if (startDate) {
    if (moment(startDate).isSame(time, 'day')) {
      return moment(time).format(timeFormat);
    }
    return moment(time).format('YYYY-MM-DD HH:mm');
  }
  return moment(time).format('YYYY-MM-DD HH:mm');
};

watch(
  () => roomBookings,
  () => {
    setTimeout(() => {
      showBooking.value = false;
      displayBooking.value = null;
    }, 300);
  }
);
</script>

<template>
  <div>
    <VTable
      edge-to-edge
      bordered-table>
      <template #head>
        <VTableRow head>
          <VTableCell v-if="roomBookings.length" />
          <VTableCell :classes="roomBookings.length ? '' : '!pl-0'">Room</VTableCell>
          <VTableCell>Title</VTableCell>
          <VTableCell>Start</VTableCell>
          <VTableCell>End</VTableCell>
          <VTableCell
            v-if="canEdit"
            style="width: 50px" />
          <VTableCell
            v-if="canEdit"
            style="width: 50px" />
        </VTableRow>
      </template>
      <VTableRow
        v-for="booking in roomBookings"
        :key="booking.id"
        clickable
        :classes="booking.issues ? '!border-[2px] border-warning ' : ''"
        @mouseenter="moseOverBooking($event, booking)"
        @mouseleave="moseNotOverBooking">
        <VTableCell @click="emitEditBooking(booking)">
          <template v-if="getKey(booking, 'working')">
            <i class="fa fa-fw fa-circle-o-notch fa-spin" />
          </template>
          <template v-else-if="getKey(booking, 'issues') !== null">
            <i
              v-if="booking.issues"
              class="fa fa-fw fa-info text-warning"
              title="Issues with booking" />
            <CheckBox
              v-if="!booking.issues"
              :model-value="true"
              title="No Issues with" />
          </template>
        </VTableCell>
        <VTableCell
          main-cell
          @click="emitEditBooking(booking)">
          {{ getRoomName(booking.room_id) }}
        </VTableCell>
        <VTableCell @click="emitEditBooking(booking)">
          {{ booking.title }}
        </VTableCell>
        <VTableCell @click="emitEditBooking(booking)">
          {{ formattedDateTime(booking.start) }}
        </VTableCell>
        <VTableCell @click="emitEditBooking(booking)">
          {{ formattedDateTime(booking.end) }}
        </VTableCell>
        <VTableCell v-if="canEdit">
          <VButton
            size="sm"
            icon="fa-pencil"
            @click="emitEditBooking(booking)" />
        </VTableCell>
        <VTableCell v-if="canEdit">
          <VButton
            size="sm"
            icon="fa-trash"
            @click="emitRemoveBooking(booking)" />
        </VTableCell>
      </VTableRow>
    </VTable>

    <HoverBox
      v-if="showBooking && displayBooking"
      :y-pos="pageY"
      :x-pos="pageX">
      <div class="flex gap-edge rounded border bg-content p-edge">
        <div v-if="displayBooking.other_uses && displayBooking.other_uses.length">
          <h3>Other Uses</h3>
          <div class="flex flex-col gap-edge-1/4">
            <div
              v-for="other_use in displayBooking.other_uses"
              :key="other_use.id">
              <i class="fa fa-fw fa-circle text-xs"></i>
              {{ other_use.event.name }}: {{ formattedDateTime(other_use.start) }} and
              {{ formattedDateTime(other_use.end) }}
            </div>
          </div>
        </div>
        <div v-if="displayBooking.restrictions && displayBooking.restrictions.length">
          <h3>Restrictions</h3>
          <div class="flex flex-col gap-edge-1/4">
            <div
              v-for="restriction in displayBooking.restrictions"
              :key="restriction.id">
              <i class="fa fa-fw fa-circle text-xs"></i>
              {{ restriction.message }} from {{ getRoomName(restriction.room_id) }} on event
              {{ restriction.eventName }}. In use: {{ formattedDateTime(restriction.start) }} -
              {{ formattedDateTime(restriction.end) }}
            </div>
          </div>
        </div>
      </div>
    </HoverBox>
    <div
      v-if="!roomBookings.length"
      class="sub-title italic text-soft">
      No Bookings Added
    </div>
  </div>
</template>

<script setup lang="ts">
import CrudModal from '@/components/Modals/CrudModal.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { ref } from 'vue';

type Props = {
  title: string;
  withoutButtonText?: boolean;
};

withDefaults(defineProps<Props>(), {
  withoutButtonText: false,
});

const emit = defineEmits<{
  (event: 'selected'): void;
}>();

const modalOpen = ref(false);

const showTemplate = () => {
  modalOpen.value = true;
};

const selectTemplate = (close: () => void) => {
  emit('selected');
  close();
};
</script>

<template>
  <VButton
    size="sm"
    icon="fa-eye"
    :title="withoutButtonText ? null : 'Preview'"
    @click="showTemplate">
  </VButton>

  <CrudModal
    v-if="modalOpen"
    :title="title"
    :hide-all-crud-modals="false"
    x-large
    create-button-text="Select"
    modal-in-modal
    @closed="modalOpen = false"
    @create="selectTemplate">
    <slot />
  </CrudModal>
</template>

<script lang="ts" setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import type { SortableEvent } from 'sortablejs';
import Sortable from 'sortablejs';
import { useToast } from 'vue-toastification';
import { useSmallScreen } from '@/composables/use-small-screen';
import { SortEmit } from '@/components/TestTable.vue';

export type Gap = '0' | '1' | 'edge-1/4' | 'edge-1/2' | 'edge' | 'edge-2x';

type Props = {
  modelValue: any[];
  itemKey?: string;
  columns?: number;
  gap?: Gap;
  canDrag?: boolean;
  dragHandle?: string | null;
  loading?: boolean;
  handleOutside?: boolean;
  showBorder?: boolean;
  minColumnWidth?: number | null;
  maxColumnWidth?: number | null;
  withOutBorder?: boolean;
  jsonObject?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  itemKey: 'id',
  columns: 1,
  gap: '1',
  canDrag: false,
  dragHandle: null,
  loading: false,
  showBorder: false,
  handleOutside: false,
  jsonObject: false,
  minColumnWidth: null,
  maxColumnWidth: null,
  withOutBorder: false,
});

const emit = defineEmits<{
  (event: 'update:modelValue', ...args: any[]): void;
  (event: 'jsonSorted', ...args: any[]): void;
  (event: 'reorder-all', arg: () => void): void;
  (event: 'newOrder', arg1: any, arg2: number): void;
  (event: 'sorted', arg1: SortEmit): void;
}>();

const gridTemplate = ref<HTMLElement | null>(null);

let sortableInstance: Sortable | null = null;

onMounted(() => {
  watch(
    () => props.canDrag,
    (canDrag) => {
      if (canDrag) {
        sortableInstance = new Sortable(gridTemplate.value, {
          handle: props.dragHandle,
          draggable: '.draggable',
          sort: true,
          ghostClass: 'invisible',
          disabled: !props.canDrag || props.loading,
          onEnd(event: SortableEvent) {
            if (props.jsonObject) {
              const array = [...props.modelValue];
              const movedItem = array.splice(event.oldIndex, 1)[0];
              array.splice(event.newIndex, 0, movedItem);
              emit('jsonSorted', array);
              return;
            }
            if (props.handleOutside) {
              const rowId = event.item.dataset.id;

              if (!rowId) {
                useToast().error('Something went wrong, please try again.');
                return;
              }

              const newOrder = sortableInstance?.toArray() || [];
              emit('sorted', { selectedItem: rowId, newOrder: newOrder });
              return;
            }

            const { oldIndex, newIndex } = event;
            if (oldIndex === newIndex) return;
            const itemId = event.item.getAttribute('data-id');
            if (!itemId) {
              useToast().error('Error while reordering items 1');
              return;
            }

            const selectedItem = props.modelValue.find((item) => item[props.itemKey] == itemId);
            const itemIdBefore = event.to.children.item(newIndex - 1)?.getAttribute('data-id');
            const itemIdAfter = event.to.children.item(newIndex + 1)?.getAttribute('data-id');
            if (itemIdBefore && itemIdAfter) {
              const i1 = props.modelValue?.find((item) => item[props.itemKey] == itemIdBefore);
              const i2 = props.modelValue?.find((item) => item[props.itemKey] == itemIdAfter);
              if (!i1 || !i1) {
                useToast().error('Error while reordering items 2');
                return;
              }
              const newOrder = Math.round((i1.order + i2.order) / 2);
              if (props.modelValue.some((item) => item.order === newOrder)) {
                useToast().error('Same order already exists');
                emit('reorder-all', () => {
                  sortableInstance?.sort(
                    props.modelValue?.map((i) => i[props.itemKey]),
                    true
                  );
                });
                return;
              }
              selectedItem.order = newOrder;
              const sortedItems = [...props.modelValue].sort((a, b) => a.order - b.order);
              emit('newOrder', selectedItem, newOrder);
              emit('update:modelValue', sortedItems);
            } else if (itemIdBefore) {
              // MOVED TO THE LAST POSITION
              const i1 = props.modelValue?.find((item) => item[props.itemKey] == itemIdBefore);
              if (!i1) {
                useToast().error('Error while reordering items 3');
                return;
              }
              const newOrder = i1.order + 10000;
              if (props.modelValue.some((item) => item.order === newOrder)) {
                useToast().error('Same order already exists');
                emit('reorder-all', () => {
                  sortableInstance?.sort(
                    props.modelValue?.map((i) => i[props.itemKey]),
                    true
                  );
                });
                return;
              }
              selectedItem.order = newOrder;
              const sortedItems = [...props.modelValue].sort((a, b) => a.order - b.order);
              const sortedIds = sortedItems.map((i) => String(i[props.itemKey]));
              emit('newOrder', selectedItem, newOrder);
              emit('update:modelValue', sortedItems);
            } else if (itemIdAfter) {
              // MOVED TO THE FIRST POSITION
              const i2 = props.modelValue?.find((item) => item[props.itemKey] == itemIdAfter);
              if (!i2) {
                useToast().error('Error while reordering items 4');
                return;
              }
              const newOrder = Math.round(i2.order / 2);
              if (props.modelValue.some((item) => item.order === newOrder) || newOrder < 0) {
                useToast().error('Same order already exists');
                emit('reorder-all', () => {
                  sortableInstance?.sort(
                    props.modelValue?.map((i) => i[props.itemKey]),
                    true
                  );
                });
                return;
              }
              selectedItem.order = newOrder;
              const sortedItems = [...props.modelValue].sort((a, b) => a.order - b.order);
              emit('newOrder', selectedItem, newOrder);
              emit('update:modelValue', sortedItems);
            } else {
              useToast().error('Something went wrong DRAG AND DROP');
            }
          },
          animation: 150,
        });
      } else {
        sortableInstance?.destroy();
      }
    },
    { immediate: true }
  );
});

watch(
  () => props.modelValue,
  async (array) => {
    if (array.length === 0 || !props.canDrag) {
      if (sortableInstance) {
        sortableInstance.option('disabled', true);
      }
      return;
    }
    if (sortableInstance) {
      sortableInstance.option('disabled', false);
    }
    await nextTick();
    try {
      if (JSON.stringify(array?.map((e) => String(e.id))) !== JSON.stringify(sortableInstance?.toArray())) {
        sortableInstance?.sort(
          array?.map((i) => i[props.itemKey]),
          false
        );
      }
    } catch (e) {
      throw e;
    }

    setTimeout(() => {
      try {
        if (JSON.stringify(array?.map((e) => String(e.id))) !== JSON.stringify(sortableInstance?.toArray())) {
          sortableInstance?.sort(
            array?.map((i) => i[props.itemKey]),
            false
          );
        }
      } catch (e) {
        console.error(e);
      }
    }, 500);
  },
  { deep: true }
);

watch(
  () => props.loading,
  (loading) => {
    if (sortableInstance) {
      sortableInstance.option('disabled', loading);
    }
  }
);
const smallScreen = useSmallScreen().isSmallScreen;

const classes = computed(() => (smallScreen.value ? '' : ` gap-${props.gap}`));

const translateClass = (item: any, index: number) => {
  if (smallScreen.value) return 'col-span-4';
  if (!item.class || smallScreen.value) return '';
  let classList = '';
  switch (item.class) {
    case 'col-md-3':
      classList += ' col-span-1';
      break;
    case 'col-md-6':
      classList += ' col-span-2';
      break;
    case 'col-md-12':
      classList += ' col-span-4';
      break;
    default:
      classList += ' col-span-1';
      break;
  }
  if (index > 0 && props.modelValue[index - 1].linebreak_after) {
    classList += ' !col-start-1';
  }
  return classList;
};

const currentStyle = computed(() => {
  if (props.minColumnWidth && props.maxColumnWidth) {
    return `grid-template-columns: repeat(${props.columns}, minmax(${props.minColumnWidth}px, ${props.maxColumnWidth}px)); overflow: auto;`;
  }
  return `grid-template-columns: repeat(${props.columns}, minmax(0, 1fr));`;
});
</script>

<template>
  <div
    ref="gridTemplate"
    class="grid"
    :style="currentStyle"
    :class="classes">
    <div
      v-for="(item, index) in modelValue"
      :key="item[itemKey]"
      :class="[translateClass(item, index), { 'border-transparent': !showBorder }, { 'border': !withOutBorder }]"
      class="draggable h-fit w-full overflow-hidden"
      :data-id="`${item[itemKey]}`">
      <slot
        :item="item"
        :index="index" />
    </div>
  </div>
</template>

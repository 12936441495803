<script setup lang="ts">
import moment from 'moment';
import { useToast } from 'vue-toastification';
import CrudModal from '@/components/Modals/CrudModal.vue';
import { dateTimeFormat } from '@/variables/date-format';
import TextInput from '@/components/Inputs/TextInput.vue';
import StartEndPicker from '@/components/Inputs/Date/StartEndPicker.vue';
import InfoIcon from '@/components/Icons/InfoIcon.vue';
import CheckBox from '@/components/Icons/CheckBox.vue';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';
import { useSmallScreen } from '@/composables/use-small-screen';
import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue';
import { getKey } from '@/util/globals';

type Props = {
  room: any;
  start?: string | null;
  end?: string | null;
  initBooking?: any;
  slug?: string | null;
  allowDoubleBooking?: boolean;
  differentiateRestrictions?: boolean;
  ignoreAllRestrictions?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  room: null,
  start: null,
  end: null,
  initBooking: null,
  slug: null,
  allowDoubleBooking: false,
  differentiateRestrictions: false,
  ignoreAllRestrictions: false,
});

const emit = defineEmits<{
  'closed': [];
  'create': [arg: any];
  'update': [arg: any];
  'delete': [arg: any];
}>();

const toast = useToast();

const isSmallScreen = useSmallScreen();

const modalOpen = ref(false);
const booking = ref<{
  id: number;
  title: string;
  start: string;
  end: string;
} | null>(null);
const roomRestricted = ref(false);
const otherUse = ref(false);
const loading = ref(false);
const firstLoadEditingBooking = ref(false);

const allowedAccordingToDoubleBooking = computed(() => {
  if (!props.allowDoubleBooking) {
    if (otherUse.value || roomRestricted.value) {
      return false;
    }
  }
  return true;
});

const canSave = computed(() => {
  const hasValidDates = booking.value && moment(booking.value.start).isValid() && moment(booking.value.end).isValid();
  return [hasValidDates, !loading.value, allowedAccordingToDoubleBooking.value].every((v) => v);
});

const savableBooking = computed(() => {
  if (!booking.value) return null;

  return {
    id: booking.value.id,
    room_id: props.room.id,
    title: booking.value.title,
    start: moment(booking.value.start).format(dateTimeFormat),
    end: moment(booking.value.end).format(dateTimeFormat),
  };
});

const checkIfRoomIsAvailable = async () => {
  if (loading.value) return;

  loading.value = true;
  otherUse.value = false;
  roomRestricted.value = false;

  const start = new Date().getTime();

  await nextTick();

  if (!props.room) {
    loading.value = true;
    return;
  }

  try {
    const { data } = await axios.post(`/event-requests/${props.slug}/available`, {
      room_id: props.room.id,
      start: booking.value.start,
      end: booking.value.end,
    });

    otherUse.value = getKey(data, 'other_uses', false);
    roomRestricted.value = props.ignoreAllRestrictions ? false : getKey(data, 'restrictions', false);
    const mseconds = 1500;
    const elapsed = new Date().getTime() - start;
    if (elapsed > mseconds) {
      loading.value = false;
    } else {
      let duration = mseconds - elapsed;
      if (!(duration < 1500 && duration < 0)) {
        duration = 500;
      }
      setTimeout(() => {
        loading.value = false;
      }, duration);
    }
  } catch (e) {
    toast.warning('Something went wrong, please try again later');
    console.error(e);
    loading.value = false;

    throw e;
  }
};

const showRoom = () => {
  otherUse.value = false;
  roomRestricted.value = false;

  booking.value = {
    id: props.initBooking.hasOwnProperty('id') ? props.initBooking.id : null,
    title: props.initBooking.hasOwnProperty('title') ? props.initBooking.title : null,
    start: props.initBooking.hasOwnProperty('start') && props.initBooking.start ? props.initBooking.start : props.start,
    end: props.initBooking.hasOwnProperty('end') && props.initBooking.end ? props.initBooking.end : props.end,
  };

  modalOpen.value = false;

  nextTick(() => {
    modalOpen.value = true;
  });

  setTimeout(() => {
    checkIfRoomIsAvailable();
  }, 200);
};

onMounted(async () => {
  await nextTick();
  showRoom();
});

const hideShowModal = () => {
  emit('closed');
  modalOpen.value = false;
};

const selectRoom = () => {
  if (loading.value) {
    return;
  }
  if (!canSave.value) {
    return;
  }
  emit('create', savableBooking.value);
  hideShowModal();
};

const patchRoomBooking = () => {
  if (!canSave.value) {
    return;
  }
  emit('update', savableBooking.value);
  hideShowModal();
};

const emitRemoveRoom = () => {
  emit('delete', booking.value);
  hideShowModal();
};

onUnmounted(() => {
  hideShowModal();
});
</script>

<template>
  <CrudModal
    v-if="modalOpen"
    :title="booking.id ? ` Request ${room?.name}` : `Edit booking of  '${room?.name}'`"
    create-button-text="Request Room"
    :update="booking.id !== null"
    close-button-text="Cancel"
    :disabled="!canSave"
    delete-button-string="Remove Room"
    @delete="emitRemoveRoom"
    @update="patchRoomBooking"
    @create="selectRoom"
    @closed="hideShowModal">
    <div class="form-layout">
      <TextInput
        v-model="booking.title"
        text-wrapper-class="col-span-2"
        label="Room Title" />
      <div
        :class="{ ' flex flex-col  ': isSmallScreen, 'grid grid-cols-3': !isSmallScreen }"
        class="col-span-2 gap-edge [&>div]:min-h-[60px]">
        <StartEndPicker
          v-model:start="booking.start"
          v-model:end="booking.end"
          with-time
          with-duration
          :vertical="!isSmallScreen"
          layout-outside
          required
          :allow-no-duration="false"
          @update:start="checkIfRoomIsAvailable()"
          @update:end="checkIfRoomIsAvailable()" />
      </div>
    </div>
    <div class="mt-edge-1/2 mb-edge-1/2">
      <h4 v-if="loading">
        <i class="fa fa-fw fa-circle-o-notch fa-spin fa-lg" />
        Checking restrictions and other bookings
      </h4>
      <div v-if="!loading">
        <div
          v-if="differentiateRestrictions"
          class="flex items-center gap-edge-1/4">
          <InfoIcon
            v-if="roomRestricted || otherUse"
            classes="fa-lg text-warning" />
          <IconWithLoading
            :loading="loading"
            icon="fa-check-double text-highlight" />
          <div v-if="differentiateRestrictions">
            <h3 v-if="roomRestricted || otherUse">
              {{ room.name }} is
              <template v-if="otherUse"> already in use in this time frame.</template>
              <template v-else-if="roomRestricted && !otherUse"> restricted for use in this time frame.</template>
              <template v-if="allowDoubleBooking">
                You can still request it, but most likely it might not be available.
              </template>
            </h3>
            <h3 v-else>{{ room.name }} seems to be available.</h3>
          </div>
          <div v-if="!differentiateRestrictions">
            <h3 v-if="roomRestricted || otherUse">
              {{ room.name }} is already in use in this time frame.
              <template v-if="allowDoubleBooking">
                You can still request it, but most likely it might not be available.
              </template>
            </h3>
            <h3 v-else>{{ room.name }} seems to be available.</h3>
          </div>
        </div>
        <div
          v-if="!differentiateRestrictions"
          class="flex items-center gap-edge-1/4">
          <InfoIcon
            v-if="roomRestricted || otherUse"
            classes="fa-lg text-warning" />
          <CheckBox
            v-else
            :can-edit="false"
            :model-value="true" />
          <h4 v-if="roomRestricted || otherUse">
            {{ room?.name }} is already in use in this time frame.
            <template v-if="allowDoubleBooking">
              You can still request it, but most likely it might not be available.
            </template>
          </h4>
          <h4 v-else>{{ room?.name }} seems to be available.</h4>
        </div>
      </div>
    </div>
  </CrudModal>
</template>

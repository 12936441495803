<script lang="ts" setup>
import { computed, getCurrentInstance, onMounted, provide, ref, watch } from 'vue';
import { mainRowType } from '@/provide/keys';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

type Props = {
  head?: boolean;
  noBackground?: boolean;
  clickable?: boolean;
  sticky?: boolean;
  classes?: string;
  mainRow?: boolean;
  canDrag?: boolean;
  hideEditButton?: boolean;
  hideAllButtons?: boolean;
};

const {
  sticky = false,
  head = false,
  noBackground = false,
  clickable = false,
  classes = '',
  mainRow = false,
  canDrag = false,
  hideEditButton = false,
  hideAllButtons = false,
} = defineProps<Props>();

defineEmits<{
  click: [arg: MouseEvent];
}>();

const instance = getCurrentInstance();
const addBackgroundClassesToRows = ref(true);

const getPropsFromVTable = () => {
  setTimeout(() => {
    try {
      if (instance.parent.props.hasOwnProperty('addBackgroundClassesToRows')) {
        addBackgroundClassesToRows.value = instance.parent.props.addBackgroundClassesToRows;
      }
    } catch (_) {}
  }, 50);
};

onMounted(() => {
  getPropsFromVTable();
});

const tableRow = cva(`${sticky ? 'sticky' : ''} ${clickable ? 'cursor-pointer' : ''} text-left`, {
  variants: {
    type: {
      'head': `uppercase capitalize text-sm font-mediumbold z-10`,
      'row': `text-base [&_td]:hover:bg-row-hover`,
    },
  },
});

const getClasses = () => {
  let string = '';

  // if (props.sticky) {
  //   string += ' sticky';
  // }
  // if (props.classes) {
  //   string += ` ${props.classes} `;
  // }

  // if (props.head) {
  //   return `${string} uppercase [&>*]:capitalize [&>*>*>*]:text-sm  [&>*>*]:text-sm [&>*]:py-edge-1/4  [&>*]:font-mediumbold z-10 `;
  // }

  // if (props.noBackground && addBackgroundClassesToRows.value) {
  //   if (!addBackgroundClassesToRows.value) {
  //     return string;
  //   }
  //   return `${string} !bg-transparent [&>td]:!bg-transparent`;
  // }

  // string += ' text-left ';

  // if (addBackgroundClassesToRows.value) {
  //   string += ' hover:!bg-row-hover [&:hover_td]:!bg-row-hover [&>*]:hover:!text ';
  // }

  // if (props.clickable) {
  //   string += ' cursor-pointer';
  // }

  // if (props.hideEditButton) {
  //   string +=
  //     ' group/item [&_td:last-child_button_i]:invisible [&>td:last-child_button_i]:hover:visible [&_td:last-child_button_i]:group-hover/item:!visible ';
  // }
  //
  // if (props.hideAllButtons) {
  //   string +=
  //     ' group/item [&_td_button_i]:invisible [&>td_button_i]:hover:visible [&_td_button_i]:group-hover/item:!visible ';
  // }

  return string;
};

provide(mainRowType, () => mainRow);
</script>

<template>
  <tr
    :class="twMerge(tableRow({ type: head ? 'head' : 'row' }), classes, $attrs.class)"
    :draggable="canDrag"
    @click="$emit('click', $event)">
    <slot :main-row="mainRow" />
  </tr>
</template>

'
<script setup lang="ts">
import { InvoiceBasesResource } from '@/types/invoice-row';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';
import { formatStampAsDate } from '../../../util/timeFunctions';

type Props = {
  inviteId: number;
  invoice: InvoiceBasesResource;
  canEdit?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  canEdit: false,
});

const emit = defineEmits<{
  (event: 'update:invoice', value: InvoiceBasesResource): void;
}>();

const setOrderDate = async (newDate) => {
  await axios.patch(`/api/invoice-bases/${props.invoice.id}`, {
    order_date: newDate,
  });

  const newInvoice = { ...props.invoice };
  newInvoice.order_date = newDate;
  emit('update:invoice', newInvoice);
};

const setInvoiceDate = async (newDate) => {
  await axios.patch(`/api/invoice-bases/${props.invoice.id}`, {
    invoiced_date: newDate,
  });

  const newInvoice = { ...props.invoice };
  newInvoice.invoiced_date = newDate;
  emit('update:invoice', newInvoice);
};
</script>

<template>
  <div>
    <div class="grid grid-cols-2 gap-edge">
      <div>
        <InputLabel label="Order Date"></InputLabel>
        <VDatepicker
          :model-value="invoice.order_date"
          placeholder="Order Date"
          class="mr-edge-1/2"
          :with-icon="false"
          is-hidden
          @update:model-value="setOrderDate">
          <template #override-input>
            <div class="hover:bg-row-hover rounded cursor-pointer px-edge-1/2 -mx-edge-1/2 py-edge-1/4">
              <span v-if="invoice.order_date">
                {{ formatStampAsDate(invoice.order_date) }}
              </span>
              <span
                v-else
                class="text-soft text-sm">
                Set Order Date
              </span>
            </div>
          </template>
        </VDatepicker>
      </div>
      <div>
        <InputLabel label="Invoiced Date"></InputLabel>
        <VDatepicker
          :model-value="invoice.invoiced_date"
          placeholder="Invoiced Date"
          class="mr-edge-1/2"
          :with-icon="false"
          is-hidden
          @update:model-value="setInvoiceDate">
          <template #override-input>
            <div class="hover:bg-row-hover rounded cursor-pointer px-edge-1/2 -mx-edge-1/2 py-edge-1/4">
              <span v-if="invoice.invoiced_date">
                {{ formatStampAsDate(invoice.invoiced_date) }}
              </span>
              <span
                v-else
                class="text-soft text-sm">
                Set Invoiced Date
              </span>
            </div>
          </template>
        </VDatepicker>
      </div>
    </div>
  </div>
</template>

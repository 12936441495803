<script lang="ts" setup>
import IntermediateStepMembersCalendarEventHover from '@/components/calendar/event-hover-items/IntermediateStepMembersCalendarEventHover.vue';
import CalendarShiftEvent from '@/components/calendar/events/CalendarShiftEvent.vue';
import VCalendar from '@/components/calendar/VCalendar.vue';
import VButton from '@/components/Inputs/VButton.vue';
import IntermediateStepSchedulerShiftList from '@/components/IntermediateStep/Tabs/Members/IntermediateStepSchedulerShiftList.vue';
import CrudModal from '@/components/Modals/CrudModal.vue';
import InviteMemberModal from '@/components/Modals/InviteMemberModal.vue';
import ShiftForm from '@/components/Modals/ShiftForm.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { useRecurringModal } from '@/composables/modals/use-recurring-modal';
import { useGroupProjectLeaders } from '@/composables/use-group-project-leaders';
import { useShiftTypes } from '@/composables/use-shift-types';
import { useSmallScreen } from '@/composables/use-small-screen';
import { getGroupAssignments, getGroupCrew, getGroupProjectLeaders, GroupCrewResource } from '@/services/api-group';
import { getShiftForInvite, postDeclineInvite } from '@/services/api-invite';
import { useEmitStore } from '@/store/EmitStore';
import { InviteResource } from '@/types/invite';
import { UserMinimalResource } from '@/types/user';
import { toHumanTimeFormat } from '@/util/date';
import type { CalendarApi, CalendarOptions, EventInput } from '@fullcalendar/core';
import type { ResourceApi, ResourceInput } from '@fullcalendar/resource';
import FullCalendar from '@fullcalendar/vue3';
import moment from 'moment';
import { onActivated, onMounted, reactive, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import { getKey } from '@/util/globals';

type Props = {
  invite?: InviteResource | null;
  isPastEvent: boolean;
  isRecurringEvent?: boolean;
  canEdit: boolean;
  groupId: number;
  hasShifts: boolean;
  startDate: string;
  eventId?: number | null;
};

const props = withDefaults(defineProps<Props>(), {
  isRecurringEvent: false,
  eventId: null,
  invite: null,
});

const { rootEmit } = useEmitStore();

const { isSmallScreen } = useSmallScreen();

const { shiftTypes, fetch: fetchShiftTypes } = useShiftTypes('Group', props.groupId, false);
fetchShiftTypes();

const { projectLeaders, fetchGroupProjectLeaders } = useGroupProjectLeaders(props.groupId);
fetchGroupProjectLeaders();

const allResources = ref<GroupCrewResource[]>([]);
const allShifts = ref(props.invite?.owner_shifts || []);
const fetchAllShifts = async () => {
  if (!props.invite?.id) return;

  const { data } = await getShiftForInvite(props.invite.id);
  allShifts.value = data;
};

const calendar = ref<{ api: CalendarApi; calendar: typeof FullCalendar } | null>(null);

const views = ['1_day', '3_days'] as const;
const initialView = ref(views[1]);

const selectedMemberToInvite = ref<{
  parentId: number | null;
  model_id: number;
  title: string;
} | null>(null);

const declineInvite = async (inviteId: number, global = false) => {
  try {
    const { data } = await postDeclineInvite(inviteId, global);
    calendar.value?.api.refetchEvents();
    calendar.value?.api.refetchResources();
    return data;
  } catch (e) {
    useToast().error('something went wrong');
  }
};

const calendarData = reactive({
  initialView: isSmallScreen.value ? 'smallScreen' : initialView.value,
  resourceOrder: 'order',
  initialDate: moment(props.startDate).subtract(1, 'day').format('YYYY-MM-DD'),
  resourceAreaWidth: '30%',
  nextDayThreshold: '06:00',
  resourcesInitiallyExpanded: true,
  selectable: false,
  editable: false,
  firstDay: 1,
  weekNumbers: true,
  fixedWeekCount: false,
  allDaySlot: false,
  eventAllow: () => false,
  slotLaneClassNames(arg) {
    if (moment(arg.date).isSame(props.startDate, 'day')) {
      return 'bg-[--color-background-calendar-today] ';
    }
    return '';
  },
  slotLabelClassNames(arg) {
    if (moment(arg.date).isSame(props.startDate, 'day')) {
      return 'bg-[--color-background-calendar-today]';
    }
    return '';
  },
  resourceLaneClassNames(arg) {
    if (getKey(arg.resource.extendedProps, 'model') === 'user') {
      return '[&_.fc-calendar-time-on-event-item]:hidden';
    }
    return '';
  },
  views: {
    '1_day': {
      buttonText: '1 day',
      type: 'resourceTimeline',
      viewClassNames: 'resource-timeline-day',
      duration: { days: 1 },
      dateIncrement: { days: 1 },
      slotDuration: '24:00:00',
      slotLabelFormat: [
        {
          month: 'long',
          day: 'numeric',
          weekday: 'long',
        },
      ],
    },
    '3_days': {
      buttonText: '3 days',
      type: 'resourceTimeline',
      viewClassNames: 'resource-timeline-day',
      duration: { days: 3 },
      slotDuration: { days: 1 },
      slotLabelFormat: [
        {
          weekday: 'short',
          day: 'numeric',
        },
      ],
    },
    smallScreen: {
      type: 'resourceTimelineDay',
      slotDuration: { days: 1 },
      slotLabelInterval: { days: 1 },
      viewClassNames: 'resource-timeline-day',
      resourceAreaWidth: '50%',
      buttonText: 'Day',
      slotLabelFormat: [
        {
          month: 'long',
          day: 'numeric',
          weekday: 'long',
        },
      ],
    },
  },
  async events(info, successCallback, errorCallback) {
    try {
      const start = moment(info.start.valueOf()).format('YYYY-MM-DD');
      const end = moment(info.end.valueOf()).format('YYYY-MM-DD');
      const params = {
        start,
        end,
        group_id: props.groupId,
        with_festivals: true,
        with_shifts: true,
      };

      const { data } = await getGroupAssignments(props.groupId, params);

      data.forEach((event) => {
        if (event.shift_id) {
          event.color = 'transparent';
        }
        event.resourceEditable = false;
      });

      successCallback(data as EventInput[]);
    } catch (e) {
      errorCallback(e);
      throw e;
    }
  },
  async resources(info, success, error) {
    try {
      const { data } = await getGroupCrew(props.groupId, {
        event_id: props.eventId,
        dashboard: false,
      });

      allResources.value = data;

      success(data as ResourceInput[]);
    } catch (e) {
      error(e);
      throw e;
    }
  },
}) as CalendarOptions;

const userInvited = (member) => {
  calendar.value?.api.refetchEvents();
  calendar.value?.api.refetchResources();
  const r = calendar.value?.api.getResourceById(member.key);
  if (r) {
    r.setExtendedProp('status', member.accepted ? 'accepted' : 'waiting');
  }
  rootEmit('invite-changed');
};

const listenForBroadcast = () => {
  Echo.join(`On.Group.${props.groupId}`)
    .listen('.members.updated', (e) => {
      calendar.value?.api.refetchResources();
      calendar.value?.api.refetchEvents();
      fetchAllShifts();
    })
    .listen('.shift.created', (e) => {
      calendar.value?.api.refetchResources();
      calendar.value?.api.refetchEvents();
      fetchAllShifts();
    })
    .listen('.shift.updated', (e) => {
      calendar.value?.api.refetchResources();
      calendar.value?.api.refetchEvents();
      fetchAllShifts();
    })
    .listen('.shift.deleted', (e) => {
      calendar.value?.api.refetchResources();
      calendar.value?.api.refetchEvents();
      fetchAllShifts();
    })
    .listen('.shift.responded', (e) => {
      calendar.value?.api.refetchResources();
      calendar.value?.api.refetchEvents();
      fetchAllShifts();
    })
    .listen('.invites.changed', (e) => {
      calendar.value?.api.refetchResources();
      calendar.value?.api.refetchEvents();
      fetchAllShifts();
    });
};

onMounted(() => {
  listenForBroadcast();
});

const getIcon = (resource: ResourceApi) => {
  if (resource.extendedProps.model_id === props.groupId) {
    return null;
  }
  if (props.isPastEvent) {
    return 'fa-ban';
  }
  switch (resource.extendedProps.status) {
    case 'waiting':
    case 'accepted': {
      return 'fa-minus';
    }
    case 'declined': {
      return 'fa-plus';
    }
    default: {
      return 'fa-plus';
    }
  }
};

const isUser = (resource: ResourceApi) => {
  return resource.extendedProps.model === 'user';
};

const inviteGroupToEvent = async (groupId: number, projectLeaderId: number | null = null) => {
  if (!groupId) {
    useToast().error('Error');
    return;
  }

  let global = false;
  if (props.isRecurringEvent) {
    const req = await useRecurringModal().recurringModal(
      '',
      'Decline Invite to all events',
      'Do you want to decline the invites on all recurrences of this event, or just this one?.'
    );
    if (req === 'cancel') return false;
    if (req === 'all') global = true;
  }

  return axios.post('/api/invites', {
    event_id: props.eventId,
    group_id: groupId,
    via: props.groupId,
    project_leader_id: projectLeaderId,
    is_global: global,
  });
};

const showProjectLeaderModal = ref(false);
const selectedGroup = ref<{
  title: string;
  id: number;
} | null>(null);
const selecteGroupProjectLeaders = ref<UserMinimalResource[]>([]);

const checkIfProjectLeader = async (groupId: number) => {
  const { data } = await getGroupProjectLeaders(groupId);
  if (data.length === 0) {
    useToast().info(`${selectedGroup.value?.title} does not any project leaders.
             The group has been invited without a project leader.`);
    const invited = await inviteGroupToEvent(groupId);
    if (!invited) return false;

    calendar.value?.api.refetchEvents();
    calendar.value?.api.refetchResources();
    rootEmit('invite-changed');
    return true;
  } else if (data.length === 1) {
    useToast().info(`${selectedGroup.value?.title} only has one project leader.
             The group has been invited with ${data[0].name} as the project leader.`);
    const invited = await inviteGroupToEvent(groupId, data[0].id);
    if (!invited) return false;

    calendar.value?.api.refetchEvents();
    calendar.value?.api.refetchResources();
    rootEmit('invite-changed');
    return true;
  } else {
    selecteGroupProjectLeaders.value = data;
    showProjectLeaderModal.value = true;
    return false;
  }
};

const toggleResource = async (resource: ResourceApi) => {
  if (props.isPastEvent) return;

  switch (resource.extendedProps.model) {
    case 'group': {
      switch (resource.extendedProps.status) {
        case 'waiting':
        case 'accepted': {
          const yes = await useCertaintyModal().assertCertain(
            'Remove Group from event',
            'Are you sure you want to do this? All assignments and access will be lost.'
          );
          if (yes) {
            let global = false;
            if (props.isRecurringEvent) {
              const req = await useRecurringModal().recurringModal(
                '',
                'Decline Invite to all events',
                'Do you want to decline the invites on all recurrences of this event, or just this one?.'
              );
              if (req === 'cancel') return;
              if (req === 'all') global = true;
            }

            await declineInvite(resource.extendedProps.invite_id, global);
            resource.setExtendedProp('status', 'declined');
            useToast().success('Invite Declined');
            calendar.value?.api.refetchEvents();
            calendar.value?.api.refetchResources();
            rootEmit('invite-changed');
          }
          break;
        }
        default: {
          if (resource.extendedProps.using_project_leaders) {
            selectedGroup.value = {
              title: resource.title,
              id: resource.extendedProps.model_id,
            };
            await checkIfProjectLeader(selectedGroup.value.id);
            return;
          } else {
            const invited = await inviteGroupToEvent(resource.extendedProps.model_id);
            if (!invited) return false;
            useToast().success('Invite Sent to group');
            calendar.value?.api.refetchEvents();
            calendar.value?.api.refetchResources();
            rootEmit('invite-changed');
          }
        }
      }
      break;
    }
    case 'user': {
      switch (resource.extendedProps.status) {
        case 'waiting':
        case 'accepted': {
          const yes = await useCertaintyModal().assertCertain(
            'Remove name from event',
            'Are you sure you want to do this? All assignments and access will be lost.'
          );
          if (yes) {
            let global = false;
            if (props.isRecurringEvent) {
              const req = await useRecurringModal().recurringModal(
                '',
                'Decline Invite to all events',
                'Do you want to decline the invites on all recurrences of this event, or just this one?.'
              );
              if (req === 'cancel') return false;
              if (req === 'all') global = true;
            }

            await declineInvite(resource.extendedProps.invite_id, global);
            useToast().success('Invite Removed');
            rootEmit('invite-changed');
          }
          break;
        }
        default: {
          const id = resource._resource.parentId.split('_')[1];
          let parentId = null;
          if (id) {
            parentId = Number(id);
          }
          selectedMemberToInvite.value = {
            model_id: resource.extendedProps.model_id,
            title: resource.title,
            parentId: parentId,
          };
          break;
        }
      }
      break;
    }
    default: {
      console.error('error');
      throw new Error('error');
    }
  }
};

const getBackgroundColor = (resource: ResourceApi) => {
  switch (resource.extendedProps.status) {
    case 'accepted': {
      return 'border-l-success';
    }
    case 'declined': {
      return 'border-l-warning';
    }
    case 'waiting': {
      return 'border-l-pending';
    }
    default: {
      return 'border-l-transparent';
    }
  }
};

onMounted(() => {
  setTimeout(() => {
    if (calendar.value?.api) {
      calendar.value.api.render();
    }
  }, 500);
});

onActivated(() => {
  if (calendar.value?.api) {
    calendar.value.api.render();
  }
});

const showShiftModal = ref(false);
const selectedShift = ref(null);

const openShiftModal = (shift: any | null = null, otherInfo = null) => {
  if (!props.invite?.event) return;

  if (shift?.id) {
    selectedShift.value = shift;
  } else {
    selectedShift.value = {
      start: props.invite.start,
      end: props.invite.end,
      via_type: 'App\\Group',
      invited_by: props.invite.project_leader_id,
      via_id: otherInfo?.parentId ?? props.invite.invitable?.id,
      user_id: otherInfo?.parentId ? otherInfo?.model_id : null,
      initial_events: [{ id: props.invite.event.id, name: props.invite.event.name }],
    };
  }
  showShiftModal.value = true;
};

const fetchAll = () => {
  calendar.value?.api.refetchEvents();
  calendar.value?.api.refetchResources();
  rootEmit('shifts-changed');
  fetchAllShifts();
};

const showCal = ref(true);

watch(isSmallScreen, () => {
  showCal.value = false;
  setTimeout(() => {
    showCal.value = true;
  }, 100);
});

watch(initialView, (v) => {
  if (v === '1_day') {
    calendar.value?.api.gotoDate(moment(props.startDate).format('YYYY-MM-DD'));
  } else {
    calendar.value?.api.gotoDate(moment(props.startDate).subtract(1, 'day').format('YYYY-MM-DD'));
  }
});
</script>

<template>
  <div class="h-full overflow-auto md:border-t md:border-none flex flex-col gap-edge p-edge">
    <IntermediateStepSchedulerShiftList
      v-if="hasShifts && allResources"
      :can-edit="canEdit"
      :crew="allResources"
      :group-id="groupId"
      :invite="invite"
      :is-past="isPastEvent"
      :is-recurring="false"
      :resource-id="null"
      :shift-types="shiftTypes"
      :shifts="allShifts"
      @fetch="fetchAll"
      @open-shift-modal="openShiftModal" />
    <BoxContainer :content-padding="false">
      <VCalendar
        v-if="showCal"
        ref="calendar"
        :has-event-hover="!isSmallScreen"
        :has-event-menu="false"
        :sticky="true"
        :options="calendarData"
        :show-title="!isSmallScreen"
        :show-view-selector="!isSmallScreen"
        :view="isSmallScreen ? 'smallScreen' : initialView"
        :views="views"
        :with-click-date="false"
        :with-click-event="false"
        class="[&_.fc-icon]:pl-[5px] [&>div>div:first-child]:z-[5000] [&>div>div:first-child]:bg [&>div>div]:overflow-visible [&>div]:overflow-visible"
        @update:view="initialView = $event">
        <template #resourceLabelContent="{ resource }">
          <div
            :class="[
              getBackgroundColor(resource),
              { '!pl-edge-2x': !isUser(resource) && !resource.id.includes('Group_' + groupId + '_un_assigned_shifts') },
            ]"
            class="border-l-[8px] flex-1">
            <div
              :class="[{ 'font-semibold': !isUser(resource) }]"
              class="flex justify-between pr-edge-1/2">
              <div class="truncate">{{ resource.title }}</div>
              <VButton
                v-if="canEdit && getIcon(resource)"
                :icon="getIcon(resource)"
                size="sm"
                @click="toggleResource(resource)">
              </VButton>
            </div>
            <div class="text-sm text-soft">
              {{ resource.extendedProps.sub_title }}
            </div>
          </div>
        </template>

        <template #event="{ event }">
          <div
            v-if="event.extendedProps.event_id"
            class="h-[20px] w-full">
            <div class="truncate px-edge-1/4 py-1 text-sm fc-event-text-dark">
              <span class="fc-calendar-time-on-event-item">
                {{ event.startStr ? toHumanTimeFormat(event.startStr) + ' - ' : '' }}
              </span>
              {{ event.title }}
            </div>
          </div>
          <CalendarShiftEvent
            v-else-if="event.extendedProps.shift_id"
            :event="event"
            :with-hover-background="false"
            :has-hover="false"
            :group-id="groupId"
            :has-menu="false"
            :shift-types="shiftTypes" />
          <div v-else>error</div>
        </template>

        <template #event-hover="{ event }">
          <IntermediateStepMembersCalendarEventHover
            v-if="event.extendedProps.event_id"
            :event="event" />
        </template>
      </VCalendar>
    </BoxContainer>

    <ShiftForm
      v-if="showShiftModal && hasShifts"
      :all-resources="allResources"
      :can-be-invited="true"
      :can-edit="canEdit"
      :in-timeline="false"
      :inital-shift="selectedShift"
      :is-recurring="isRecurringEvent"
      :model="'Group'"
      :model-id="groupId"
      :multiple-events="true"
      :project-leaders="projectLeaders"
      :shift-types="shiftTypes"
      :with-event-ids="true"
      :with-events="true"
      @closed="showShiftModal = false"
      @created="[fetchAll(), rootEmit('invite-changed'), rootEmit('shifts-changed')]"
      @deleted="[fetchAll(), rootEmit('invite-changed'), rootEmit('shifts-changed')]"
      @updated="[fetchAll(), rootEmit('invite-changed'), rootEmit('shifts-changed')]" />

    <InviteMemberModal
      v-if="selectedMemberToInvite"
      :has-shift="hasShifts"
      :invite="invite"
      :selected-member="selectedMemberToInvite"
      @closed="selectedMemberToInvite = null"
      @invited="userInvited"
      @create-shift="openShiftModal(null, $event)" />

    <CrudModal
      v-if="showProjectLeaderModal"
      v-slot="{ close }"
      :only-close-button="true"
      title="This group is using project leaders to organise their work. Select you contact"
      @closed="showProjectLeaderModal = false">
      <div>
        <VTable>
          <VTableRow
            v-for="leader in selecteGroupProjectLeaders"
            :key="leader.id"
            clickable
            @click="[inviteGroupToEvent(selectedGroup?.id, leader.id), close()]">
            <VTableCell>
              {{ leader.name }}
            </VTableCell>
          </VTableRow>
        </VTable>

        <h4>This is all the project leaders listed from {{ selectedGroup?.title }}.</h4>
      </div>
    </CrudModal>
  </div>
</template>

<script setup lang="ts">
import type { SectionContent } from '@/types/event-request';
import { Assignments } from '@/types/event-request';
import moment from 'moment';
import { computed, nextTick, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import { createUuId, tooShortOrLong } from '@/util/globals';
import { dateTimeFormat } from '@/variables/date-format';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import StartEndPicker from '@/components/Inputs/Date/StartEndPicker.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { changeAndFormatStamp, getDiffInInterval, timeStampsAreSame } from '@/util/timeFunctions';
import { useSmallScreen } from '@/composables/use-small-screen';

type Props = {
  editMode: boolean;
  isTemplate: boolean;
  isNewRequest: boolean;
  eventStart?: string | null;
  content: Assignments[] | null;
};

const props = withDefaults(defineProps<Props>(), {
  eventStart: null,
});

const emit = defineEmits<{
  (event: 'update:content', ...args: SectionContent[]): void;
}>();

const { isSmallScreen } = useSmallScreen();

const toast = useToast();
const deleteModal = useDeleteObjectModal();

const generateUniqueRowId = () => createUuId('row');

const newRowTitle = ref('');
const assignments = ref(
  props.content?.map((a) => ({
    title: a.title,
    description: a.description,
    start: a.start,
    end: a.end,
    id: generateUniqueRowId(),
  })) ?? []
);

const canSave = computed(() => {
  return props.editMode && !props.isTemplate && props.eventStart !== null;
});

const persistableContent = computed(() => {
  return assignments.value
    .filter((a) => a.title?.trim().length > 0)
    .map((a) => ({
      title: a.title,
      description: a.description,
      start: a.start,
      end: a.end,
    }));
});

const emitAssignments = () => {
  emit('update:content', persistableContent.value);
};

watch(
  assignments,
  () => {
    if (canSave.value) {
      emitAssignments();
    }
  },
  { deep: true }
);

const goToColumn = (rowId, title: string) => {
  const elem = document.getElementById(`${rowId}_${title}`);
  if (elem !== null) {
    elem.click();
  }
};

const goToNewRow = () => {
  // const elem = document.getElementById(
  //   `addNewRORowTo_${this.id}_on_ ${this.templateId}`
  // );
  // if (elem != null) {
  //   elem.focus();
  // }
};

const comparatorStart = (a, b) => {
  if (a.start < b.start) {
    return -1;
  }
  if (a.start > b.start) {
    return 1;
  }
  return 0;
};
const comparatorEnd = (a, b) => {
  if (a.end < b.end) {
    return -1;
  }
  if (a.end > b.end) {
    return 1;
  }
  return 0;
};

const sortAssignments = () => {
  nextTick(() => {
    assignments.value = assignments.value.sort(comparatorEnd).sort(comparatorStart);
  });
};

const addRow = () => {
  if (tooShortOrLong(newRowTitle.value, 'title')) return;

  let start = props.eventStart;
  let end = moment(props.eventStart).add(1, 'hour').format(dateTimeFormat);
  if (assignments.value.length) {
    let startOfAssignment = assignments.value[assignments.value.length - 1].end;
    if (!startOfAssignment) {
      startOfAssignment = assignments.value[assignments.value.length - 1].start;
    }

    start = startOfAssignment;
    end = changeAndFormatStamp({ stamp: startOfAssignment, addMinutes: 60 });
  }
  const rowId = generateUniqueRowId();
  assignments.value.push({
    title: newRowTitle.value,
    description: null,
    start,
    end,
    id: rowId,
  });
  newRowTitle.value = '';
  sortAssignments();

  nextTick(() => {
    const elem = document.getElementById(`${rowId}_description`);
    if (elem != null) {
      elem.focus();
    }
  });
};

const assignValue = (value: moment.MomentInput, rowId: string, columnId: string | number) => {
  const preIndex = _.findIndex(assignments.value, (a) => a.id === rowId);

  switch (columnId) {
    case 'description': {
      break;
    }
    case 'title': {
      if (value === null) return;
      break;
    }
    case 'start': {
      if (value === null) return;
      if (moment(assignments.value[preIndex].end).isBefore(value)) {
        assignments.value[preIndex].end = moment(value).add(1, 'minute').format(dateTimeFormat);
        toast.warning(`Updated end of ${assignments.value[preIndex].title}`);
      }
      break;
    }
    case 'end': {
      if (value !== null && moment(assignments.value[preIndex].start).isAfter(value)) {
        assignments.value[preIndex].start = moment(value).subtract(1, 'minute').format(dateTimeFormat);
        toast.warning(`Updated start of ${assignments.value[preIndex].title}`);
      }
      break;
    }
    default: {
      return;
    }
  }

  assignments.value[preIndex][columnId] = value;

  switch (columnId) {
    case 'start': {
      sortAssignments();
      nextTick(() => {
        goToColumn(rowId, 'end');
      });
      break;
    }
    case 'end': {
      sortAssignments();
      goToNewRow();
      break;
    }
    case 'description': {
      goToColumn(rowId, 'start');
      break;
    }
    case 'title': {
      goToColumn(rowId, 'description');
      break;
    }
    default: {
      break;
    }
  }
};

const removeRow = async (rowId) => {
  const deleteIt = await deleteModal.assertReadyToDeleteModal(
    'Remove Assignment',
    'Are you sure you want to delete this assignment? It can not be restored.'
  );
  if (!deleteIt) return;
  const index = _.findIndex(assignments.value, (a) => a.id === rowId);
  if (index === -1) return;
  assignments.value.splice(index, 1);
};

watch(
  () => props.eventStart,
  (newVal, oldVal) => {
    if (!timeStampsAreSame(newVal, oldVal, 'day')) {
      const diff = getDiffInInterval(newVal, oldVal, 'days', false);
      assignments.value.forEach((ass) => {
        ass.start = changeAndFormatStamp({
          stamp: ass.start,
          addDays: diff,
        });
        ass.end = changeAndFormatStamp({
          stamp: ass.end,
          addDays: diff,
        });
        // show.start = moment(show.start).add(diffInHours, 'hours').format(dateTimeFormat);
        // show.end = moment(show.end).add(diffInHours, 'hours').format(dateTimeFormat);
      });
    }
  }
);
</script>

<template>
  <h4
    v-if="isTemplate"
    class="text-soft">
    Assignments will come here.
  </h4>
  <div
    v-else
    style="margin-left: calc(var(16px) * -1); margin-right: calc(var(16px) * -1)"
    class="rto">
    <VTable
      v-if="isNewRequest || assignments.length > 0"
      row-size="large"
      rounded-pill-rows
      edge-to-edge
      header-hover
      border-spaced>
      <template #head>
        <VTableRow
          v-if="!isSmallScreen"
          head>
          <VTableCell style="width: calc(50% - 50px)"> Title</VTableCell>
          <VTableCell style="width: calc(50% - 50px)"> When</VTableCell>
          <VTableCell v-if="canSave"></VTableCell>
        </VTableRow>
      </template>

      <VTableRow
        v-for="assignment in assignments"
        :key="assignment.id"
        :no-background="isSmallScreen">
        <VTableCell main-cell>
          <div
            :class="{ ' border-b-2 pb-edge': isSmallScreen }"
            class="form-layout min-w-[200px]">
            <div class="flex items-center gap-edge">
              <TextInput
                :id="assignment.id + '_title'"
                text-wrapper-class="flex-1"
                :model-value="assignment.title"
                :can-edit="canSave"
                label="Title"
                placeholder="Assignment Title"
                @blur="assignValue($event, assignment.id, 'title')" />
              <VButton
                v-if="isSmallScreen"
                size="sm"
                icon="fa-trash"
                title="Delete"
                @click="removeRow(assignment.id)" />
            </div>

            <TextareaInput
              :id="assignment.id + '_description'"
              :model-value="assignment.description"
              :can-edit="canSave"
              :min-height="50"
              label="Description"
              :min-rows="1"
              placeholder="Assignment Description"
              @blur="[(assignment.description = $event)]" />

            <div
              v-if="isSmallScreen"
              class="flex flex-col gap-edge [&>div]:min-h-[60px]">
              <StartEndPicker
                :start="assignment.start"
                :end="assignment.end"
                with-time
                with-duration
                :can-edit="canSave"
                allow-no-duration
                layout-outside
                :vertical="false"
                required
                :show-end="assignment.end !== null"
                @update:start="assignValue($event, assignment.id, 'start')"
                @update:end="assignValue($event, assignment.id, 'end')" />
            </div>
          </div>
        </VTableCell>
        <VTableCell
          v-if="!isSmallScreen"
          classes="align-top"
          style="max-width: 500px">
          <div
            class="grid h-full gap-edge align-top"
            :class="assignment.end ? 'grid-cols-[auto_150px_auto]' : 'grid-cols-2'">
            <StartEndPicker
              :start="assignment.start"
              :end="assignment.end"
              with-time
              with-duration
              :can-edit="canSave"
              allow-no-duration
              layout-outside
              vertical
              required
              :show-end="assignment.end !== null"
              @update:start="assignValue($event, assignment.id, 'start')"
              @update:end="assignValue($event, assignment.id, 'end')" />
          </div>
        </VTableCell>
        <VTableCell
          v-if="canSave && !isSmallScreen"
          style="width: 50px">
          <VButton
            size="sm"
            icon="fa-trash"
            tool-tip-text="Remove assignment"
            @click="removeRow(assignment.id)" />
        </VTableCell>
      </VTableRow>

      <template
        v-if="canSave && isNewRequest"
        #footer>
        <tfoot>
          <VTableRow no-background>
            <VTableCell colspan="5">
              <TextInput
                v-model="newRowTitle"
                label="Title"
                :label-placement="isSmallScreen ? 'top' : 'left'"
                placeholder="Title of Assignment"
                action-buttons
                save-action-title="Add"
                with-save-action
                :with-clear-action="false"
                :with-delete-action="false"
                @save="addRow"
                @clear="newRowTitle = ''"
                @keydown.enter="addRow" />
            </VTableCell>
          </VTableRow>
        </tfoot>
      </template>
    </VTable>
    <div
      v-else
      class="text-center italic text-soft">
      No Assignments created.
    </div>
  </div>
</template>

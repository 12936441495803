<script lang="ts" setup>
import { inject, ref } from 'vue';

const props = defineProps({
  icon: {
    type: String,
    required: false,
    default: null,
  },
  title: {
    type: String,
    required: false,
    default: '',
  },
  openOnClick: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  header: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const emits = defineEmits(['click']);
const close = inject('close-dropdown');
const onClick = () => {
  if (props.disabled) {
    return;
  }
  emits('click');
  if (!props.openOnClick) {
    close();
  }
};

const html = ref(null);
defineExpose({
  html,
});
</script>

<template>
  <li
    ref="html"
    :class="
      (disabled ? ' disabled ' : '') +
      (header ? ' text-center underline ' : 'hover:bg-tertiary cursor-pointer') +
      ' p-edge-1/4'
    "
    @click="onClick">
    <slot>
      <i
        v-if="icon"
        class="fa fa-fw mr-edge-1/4"
        :class="icon" />
      <span>{{ title }}</span>
      <slot name="other-content" />
    </slot>
  </li>
</template>

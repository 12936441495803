<script setup lang="ts">
import { documentKey, maxUploadSizeKey, performanceDocumentsKey } from '@/provide/keys';
import { DocumentResource } from '@/types/document';
import { computed, inject, nextTick, ref } from 'vue';
import CrudModal from '@/components/Modals/CrudModal.vue';
import VTable from '@/components/Tables/VTable.vue';
import DocumentCellDocument from '@/components/Config/ColumnConfig/Cells/Files/DocumentCellDocument.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import DocumentCellFolder from '@/components/Config/ColumnConfig/Cells/Files/DocumentCellFolder.vue';
import { sortArrayBy } from '@/util/globals';
import DocumentUploader from '@/components/Documents/DocumentUploader.vue';

type Props = {
  selectedDocumentId: number | null;
  model?: string | null;
  modelId?: number | null;
  imageOnly?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  imageOnly: false,
  model: null,
  modelId: null,
  selectedDocumentId: null,
});

const emit = defineEmits<{
  (event: 'document:selected', arg: any): void;
  (event: 'closed'): void;
}>();

const maxUploadSize = inject(maxUploadSizeKey, 100);

const {
  fetch,
  folderDocuments,
  unconnectedDocuments,
  allDocuments,
  model: documentModel,
  modelId: documentModelId,
  loaded,
} = inject(documentKey, {
  fetch: async () => {},
  loading: ref(false),
  loaded: ref(false),
  folderDocuments: computed(() => []),
  unconnectedDocuments: computed(() => []),
  allDocuments: computed(() => []),
  documentModel: null,
  documentModelId: null,
});
const { fetchDocuments, loading, canView, hasLoaded, documents } = inject(performanceDocumentsKey, {
  fetchDocuments: async () => {},
  loading: ref(false),
  canView: ref(true),
  hasLoaded: ref(false),
  documents: computed(() => []),
});

const empty = computed(
  () => folderDocuments.value.length === 0 && unconnectedDocuments.value.length === 0 && documents.value.length === 0
);

const openFolderIds = ref<Set<number>>(new Set());
const getFolderIds = (folder) => {
  return [folder.id].concat(folder.children.map(getFolderIds).flat());
};

const documentAdded = async (doc: DocumentResource) => {
  emit('document:selected', doc);
  modalOpen.value = false;
  fetch(true);
  fetchDocuments(true);
};

const modalOpen = ref(true);
const showUploader = ref(true);
const searchTerm = ref('');
const localModel = ref(null);
const localModelId = ref(null);

const setup = async () => {
  modalOpen.value = false;
  await fetch(false);
  await fetchDocuments(false);
  if (empty.value || !canView) {
    showUploader.value = false;
    await nextTick();
    showUploader.value = true;
  }
  await nextTick();
  modalOpen.value = true;

  if (documentModel && documentModelId) {
    localModel.value = documentModel;
    localModelId.value = documentModelId;
  } else if (props.model && props.modelId) {
    localModel.value = props.model;
    localModelId.value = props.modelId;
  }
};
setup();
</script>
<template>
  <CrudModal
    v-if="modalOpen"
    title="Select Document"
    only-close-button
    @closed="modalOpen = false">
    <template #default="{ close }">
      <div
        v-if="empty"
        class="text-soft">
        No Documents or Folders has been created yet.
      </div>
      <div class="mb-edge">
        <TextInput
          v-model="searchTerm"
          set-focus
          :can-edit="!empty"
          placeholder="Search"
          left-icon="fa-search fa-regular !text">
          <template #end>
            <div class="max-w-[250px] pr-edge-1/4">
              <DocumentUploader
                v-if="showUploader && localModel && localModelId"
                :model="localModel"
                :model-id="localModelId"
                can-edit
                :max-m-b-size="maxUploadSize"
                :open-on-created="empty"
                @document-uploaded="documentAdded($event)" />
            </div>
          </template>
        </TextInput>
      </div>
      <div class="max-h-[50vh] overflow-auto">
        <VTable>
          <DocumentCellFolder
            v-for="folder in folderDocuments"
            :key="folder.id"
            v-model:open-ids="openFolderIds"
            :folder="folder"
            :search-term="searchTerm"
            :image-only="imageOnly"
            :selected-document-id="selectedDocumentId"
            :document-folder="folder"
            @select-document="documentAdded($event, close)" />

          <DocumentCellDocument
            v-for="doc in sortArrayBy(
              unconnectedDocuments
                .concat(documents)
                .filter((d) => (imageOnly ? d.mime_type.includes('image') : true))
                .filter((d) =>
                  searchTerm ? d.filename.toLowerCase().includes(searchTerm.toLowerCase().trim()) : true
                ),
              'filename'
            )"
            :key="doc.id"
            :search-term="searchTerm"
            :document="doc"
            :selected-document-id="selectedDocumentId"
            @select-document="documentAdded($event, close)" />
        </VTable>
      </div>
    </template>

    <!--    <template #footer="{ close }">-->
    <!--      <div class="p-edge h-[70px]">-->
    <!--        <div class="max-w-[250px] mx-auto">-->
    <!--          <DocumentUploader-->
    <!--            v-if="showUploader && localModel && localModelId"-->
    <!--            :model="localModel"-->
    <!--            :model-id="localModelId"-->
    <!--            can-edit-->
    <!--            :max-m-b-size="maxUploadSize"-->
    <!--            :open-on-created="empty"-->
    <!--            @document-uploaded="documentAdded($event)" />-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </template>-->
  </CrudModal>
</template>

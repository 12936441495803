<script setup lang="ts">
import { UserResource } from '@/types/user';
import { computed, ref } from 'vue';
import { getProfile, scramblePassword, updateEmail, updatePassword, updateProfile } from '@/services/api-profile';
import PhoneInput from '@/components/Inputs/PhoneInput.vue';
import AvatarUploader from '@/components/AvatarUploader.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { useToast } from 'vue-toastification';
import { formatStampAsDate, stampIsValid } from '@/util/timeFunctions';
import { usePage } from '@inertiajs/vue3';
import { updateItem } from '@/util/globals';
import VButton from '@/components/Inputs/VButton.vue';
import CrudModal from '@/components/Modals/CrudModal.vue';
import EmailInput from '@/components/Inputs/EmailInput.vue';
import PasswordInput from '@/components/Inputs/PasswordInput.vue';

const working = ref(false);

const profile = ref<UserResource | null>(null);

const setupForm = async () => {
  working.value = true;
  const { data } = await getProfile();
  profile.value = data;
  working.value = false;
};
setupForm();

const canSave = computed(() => {
  if (profile.value === null) return true;
  const dob = profile.value?.dob ? stampIsValid(profile.value?.dob) : true;
  const firstName = profile.value?.first_name.length > 0;
  const lastName = profile.value?.last_name.length > 0;
  return dob && firstName && lastName;
});

const banProfile = async () => {
  const certain = await useCertaintyModal().assertCertain(
    'Disable your Profile',
    'Are you sure you want to disable your profile? You will no longer be able to access Crescat.'
  );
  if (!certain) return;
  working.value = true;
  await axios.post('/api/profile/ban').catch((error) => {
    working.value = false;
    useToast().error('Could not disable your profile. Please try again later..');
  });

  working.value = false;
  useToast().success('Your profile has been disabled');
  setTimeout(() => {
    window.location = '/logout';
  }, 1000);
};

const saveProfile = async () => {
  if (!canSave.value) return;
  working.value = false;
  const data = {
    first_name: profile.value.first_name,
    last_name: profile.value.last_name,
    phone: profile.value.phone,
    country_code: profile.value.country_code,
    address: profile.value.address,
    city: profile.value.city,
    country: profile.value.country,
    job_title: profile.value.job_title,
    avatar_url: profile.value.avatar_url,
    dob: stampIsValid(profile.value.dob) ? formatStampAsDate(profile.value.dob) : null,
  };
  await updateProfile(data);

  useToast().success('Your profile has been updated');
  usePage().props.auth.user = updateItem(profile.value, usePage().props.auth.user);
};

const showChangeEmailModal = ref(false);
const newEmail = ref('');
const newEmailConfirmed = ref('');

const canSaveEmail = computed(() => {
  return newEmail.value?.length >= 5 && newEmail.value === newEmailConfirmed.value;
});

const changeEmail = async (closeFn: () => void) => {
  if (!canSaveEmail.value) {
    useToast().warning('Invalid email');
    return;
  }
  try {
    await updateEmail(newEmail.value);
    useToast().success('Your profile has been saved. You will get an email to re-verify your email.', {
      duration: 4500,
    });
    setTimeout(() => {
      window.location = '/logout';
    }, 2000);
  } catch (e) {
    if (e.response.status === 409) {
      useToast().warning('Your current email was not correct. Please try again.');
    } else if (e.response.data.message) {
      useToast().warning(e.response.data.message);
    } else {
      useToast().warning('Could not change your email');
    }
    working.value = false;
    throw e;
  }
  working.value = false;
  closeFn();
};

const showChangePasswordModal = ref(false);

const form = ref<{
  current_password: string | null;
  new_password: string | null;
  new_password_confirmation: string | null;
}>({
  current_password: '',
  new_password: '',
  new_password_confirmation: '',
});

const canSavePassword = computed(() => {
  const oldPassword = form.value.current_password?.length;
  const newPassword = form.value.new_password && form.value.new_password?.length >= 8;
  const confirmed = form.value.new_password === form.value.new_password_confirmation;

  return [oldPassword, newPassword, confirmed].every((v) => v);
});

const changePassword = async (closeFn: () => void) => {
  if (!canSave.value) return;
  try {
    await updatePassword(form.value);
    useToast().success('Your password has been changed.');
  } catch (e: any) {
    working.value = false;
    form.value.current_password = null;
    if (e.response.status === 409) {
      useToast().warning('Your current password was not correct. Please try again.');
    } else if (e.response.data.message) {
      useToast().warning(e.response.data.message);
      throw e;
    } else {
      useToast().warning('Could not change your password');
      throw e;
    }
  }
  closeFn();
};

const scramble = async () => {
  const res = await useCertaintyModal().assertCertain(
    'Scramble password',
    'Are you sure you want to scramble your password? We will randomly generate a 100 character password, so you have to log in through Google or Microsoft.',
    'Scramble it'
  );

  if (res) {
    try {
      await scramblePassword();
    } catch (e) {
      console.error('something went wrong');
      throw e;
    }
    return;
  }
};
</script>
<template>
  <div
    v-if="profile"
    class="space-y-edge">
    <div class="space-y-edge">
      <div class="flex items-center justify-start">
        <div>
          <AvatarUploader v-model:avatar-url="profile.avatar_url" />
        </div>
      </div>
      <div class="md:grid grid-cols-1 md:grid-cols-2 gap-edge space-y-edge md:space-y-0">
        <TextInput
          :model-value="profile.first_name"
          label="First Name"
          @blur="profile.first_name === $event ? null : [(profile.first_name = $event), saveProfile()]" />
        <TextInput
          :model-value="profile.last_name"
          label="Last Name"
          @blur="profile.last_name === $event ? null : [(profile.last_name = $event), saveProfile()]" />
        <PhoneInput
          v-model:country-code="profile.country_code"
          :phone="profile.phone"
          size="block"
          @phone-blur="profile.phone === $event ? null : [(profile.phone = $event), saveProfile()]"
          @update:country-code="saveProfile()" />
        <VDatepicker
          :model-value="profile.dob"
          label="Date of Birth"
          @update:model-value="profile.dob === $event ? null : [(profile.dob = $event), saveProfile()]" />
        <div class="col-span-2 grid grid-cols-4 gap-edge">
          <div class="col-span-2">
            <TextInput
              :model-value="profile.address"
              label="Address"
              @blur="profile.address === $event ? null : [(profile.address = $event), saveProfile()]" />
          </div>
          <TextInput
            :model-value="profile.city"
            label="City"
            @blur="profile.city === $event ? null : [(profile.city = $event), saveProfile()]" />
          <TextInput
            :model-value="profile.country"
            label="Country"
            @blur="profile.country === $event ? null : [(profile.country = $event), saveProfile()]" />
        </div>
      </div>
    </div>

    <div class="border-b pt-edge">
      <h3>Authentication</h3>
    </div>

    <div class="flex justify-between items-center">
      <div class="flex-1">
        <h4>Email</h4>
        <span class="text-soft text-sm">
          {{ usePage().props.auth.user.email }}
        </span>
      </div>
      <VButton
        title="Change Email"
        type="primary"
        size="sm"
        @click="showChangeEmailModal = true" />
    </div>
    <div class="flex justify-between items-center">
      <div class="flex-1">
        <h4>Password</h4>
        <span class="text-soft text-sm"> Change your login password </span>
      </div>
      <VButton
        title="Change Password"
        type="primary"
        size="sm"
        @click="showChangePasswordModal = true" />
    </div>
    <div class="flex justify-between items-center">
      <div class="flex-1">
        <h4>De-activate Account</h4>
        <span class="text-soft text-sm"> You will no longer be able to access Crescat </span>
      </div>
      <VButton
        title="De-activate Account"
        type="warning"
        size="sm"
        @click="banProfile" />
    </div>

    <!--    <div class="border-b pt-edge">-->
    <!--      <h3>Integrations</h3>-->
    <!--    </div>-->

    <!--    <div>-->
    <!--      <CalendarLinkModal-->
    <!--        :model-id="user.id"-->
    <!--        :is-admin-modal="false"-->
    <!--        :model-type="'App\\User'" />-->
    <!--    </div>-->

    <CrudModal
      v-if="showChangeEmailModal"
      title="Change Email"
      create-button-text="Update"
      small
      :disabled="!canSaveEmail"
      @create="changeEmail"
      @closed="showChangeEmailModal = false">
      <div class="space-y-edge">
        <p>Current Email: {{ usePage().props.auth.user.email }}</p>
        <div class="space-y-edge">
          <EmailInput
            v-model="newEmail"
            label="New Email" />
          <EmailInput
            v-model="newEmailConfirmed"
            label="Confirm New Email " />
        </div>
      </div>
    </CrudModal>

    <CrudModal
      v-if="showChangePasswordModal"
      title="Change Password"
      create-button-text="Update"
      :disabled="!canSavePassword"
      small
      @update="changePassword"
      @closed="showChangePasswordModal = false">
      <div class="space-y-edge">
        <PasswordInput
          v-model="form.current_password"
          label="Current Password"
          :check-for-complexity="false"
          placeholder="Current Password"
          :confirm="false" />

        <PasswordInput
          v-model="form.new_password"
          label="New Password"
          placeholder="New Password"
          :confirm="false" />
        <PasswordInput
          v-model="form.new_password_confirmation"
          label="Confirm password"
          placeholder="Confirm password"
          :confirm="true" />
      </div>
      <template #area-in-footer-for-buttons>
        <VButton
          title="Scramble Password"
          type="warning"
          size="lg"
          icon="fa-ban"
          @click="scramble" />
      </template>
    </CrudModal>
  </div>
</template>

<script setup lang="ts">
import { getPersonalFestivals } from '@/services/api-dashboard';
import { updateCustomHome } from '@/services/api-profile';
import VSelect from '@/components/Inputs/VSelect.vue';
import { computed, ref } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { getItemFromArrayBasedOnId } from '@/util/globals';
import { useToast } from 'vue-toastification';

const loading = ref(true);
const customHomes = ref([]);

const createValueString = (type, id) => {
  if (!type && !id) return null;
  return `${type}_${id}`;
};

const initFestivals = async () => {
  const { data: festivals } = await getPersonalFestivals();
  let homes = [];
  festivals.forEach((festival) => {
    homes.push({
      name: festival.name,
      type: 'App\\Festival',
      id: createValueString('App\\Festival', festival.id),
    });
  });
  if (homes.length > 0) {
    customHomes.value.push({
      label: 'Festivals',
      options: homes,
    });
  }
};

const addAllCustomHomeAlternatives = async () => {
  const groups = [];
  usePage().props.auth.user.groups.forEach((group) => {
    if (group.admin || group.read) {
      groups.push({
        name: group.name,
        type: 'App\\Group',
        id: createValueString('App\\Group', group.id),
      });
    }
  });
  if (groups.length > 0) {
    customHomes.value.push({
      label: 'Groups',
      options: groups,
    });
  }
  await initFestivals();
  loading.value = false;
};
addAllCustomHomeAlternatives();

const customHome = computed(() => {
  return {
    custom_home_type: usePage().props.auth.user.custom_home_type,
    custom_home_id: usePage().props.auth.user.custom_home_id,
  };
});
const changeCustomHome = async (newHome) => {
  const [type, id] = newHome !== null ? newHome.split('_') : [null, null];

  let custom_home_type = null;
  let custom_home_id = null;

  if (type === 'null') custom_home_type = null;
  if (id === 'null') custom_home_id = null;
  if (type && type !== 'null') custom_home_type = type;
  if (id && id !== 'null') custom_home_id = id;
  try {
    await updateCustomHome(custom_home_id, custom_home_type);
    const allCustomHome = customHomes.value.flatMap((c) => c.options);
    const item = getItemFromArrayBasedOnId(newHome, allCustomHome, { name: 'Personal Event Page' });
    if (item) {
      useToast().success('New Home Page is ' + item.name);
    }
    usePage().props.auth.user.custom_home_type = custom_home_type;
    usePage().props.auth.user.custom_home_id = custom_home_id;
  } catch (e) {
    if (e.response.data.message) {
      useToast().error(e.response.data.message);
    } else {
      useToast().error('Could not save your profile');
    }
    throw e;
  }
};
</script>
<template>
  <VSelect
    v-if="!loading"
    :options="customHomes"
    nullable
    nullable-display-text="Personal Event Page"
    groups
    :model-value="createValueString(customHome.custom_home_type, customHome.custom_home_id)"
    @update:model-value="changeCustomHome" />
</template>

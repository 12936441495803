<script lang="ts" setup>
import { TaskResource } from '@/types/tasks';
import { nextTick, ref } from 'vue';
import DisplayRichText from '@/components/Display/DisplayRichText.vue';
import FloatingWindowContainer from '@/components/Inputs/Components/FloatingWindowContainer.vue';
import { patchTask } from '@/services/api-tasks';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import VRichText from '@/components/Inputs/VRichText.vue';

type Props = {
  task: TaskResource;
  lockedKeys?: string[];
};

const props = withDefaults(defineProps<Props>(), {
  lockedKeys: () => [],
});
const emit = defineEmits<{
  (e: 'updated', value: TaskResource): void;
}>();

const windowOpen = ref(false);
const container = ref<HTMLElement | null>(null);
const openTextarea = () => {
  pageX.value = null;
  pageY.value = null;
  windowOpen.value = false;
  nextTick(() => {
    pageX.value = container.value.getBoundingClientRect().x;
    pageY.value = container.value.getBoundingClientRect().y;
    windowOpen.value = true;
  });
};

const pageX = ref(null);
const pageY = ref(null);

const updateTask = async (newDescription: string | null) => {
  if (props.task.description === newDescription) return;
  if (!props.task.description && newDescription === '<p></p>') return;
  const localTask = { ...props.task };
  localTask.description = newDescription;
  await patchTask(props.task.uuid, {
    description: localTask.description,
  });
  emit('updated', localTask);
  pageX.value = null;
  pageY.value = null;
  windowOpen.value = false;
};
</script>

<template>
  <div>
    <div
      ref="container"
      class="h-full w-full min-h-[34px] cursor-pointer px-edge-1/2 py-1 my-0"
      @click="openTextarea">
      <DisplayRichText
        v-if="task.description"
        :content="task.description" />
    </div>

    <FloatingWindowContainer
      v-if="windowOpen"
      v-show="pageX && pageY"
      :page-x="pageX"
      :page-y="pageY"
      @closed="[(pageX = null), (pageY = null), (windowOpen = false)]">
      <div class="min-w-[370px] bg">
        <div class="p-edge">
          <InputLabel
            super-text
            :label="'Description of ' + task.title" />
        </div>
        <VRichText
          :model-value="task.description"
          is-hidden
          set-focus
          placeholder="Task Description"
          :blur-delay="0"
          @blur="updateTask($event)" />
      </div>
    </FloatingWindowContainer>
  </div>
</template>

<script lang="ts" setup>
import moment from 'moment/moment';
import { formatStampAsDate } from '@/util/timeFunctions';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import { nextTick, ref } from 'vue';
import FloatingWindowContainer from '@/components/Inputs/Components/FloatingWindowContainer.vue';
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';
import { getIndexFromArrayBasedOnId, sortArrayByTime } from '@/util/globals';
import { ZIndexCrudModal } from '@/variables/z-indexes';

type Props = {
  holdDates: [];
  eventDate: string;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:holdDates', value: []): void;
}>();

const toggleDate = (allDates: string[]) => {
  if (!allDates.length) {
    emit('update:holdDates', []);
    return;
  }
  let newDates: Date[] = [];

  allDates.forEach((date) => {
    const i = _.findIndex(props.holdDates, (d) => moment(d.date).isSame(date, 'day'));
    if (i > -1) {
      newDates.push({ ...props.holdDates[i] });
    } else {
      newDates.push({
        date: date,
      });
    }
  });
  newDates = sortArrayByTime(newDates, 'date');
  emit('update:holdDates', newDates);
};
const removeDate = (date) => {
  const newDates = [...props.holdDates];
  const index = getIndexFromArrayBasedOnId(date.date, newDates, 'date');
  newDates.splice(index, 1);
  emit('update:holdDates', newDates);
};

const pageX = ref(null);
const pageY = ref(null);
const openDateSelector = async (event) => {
  pageX.value = null;
  pageY.value = null;
  nextTick(() => {
    pageX.value = event.target.getBoundingClientRect().x - 250;
    pageY.value = event.target.getBoundingClientRect().y + 23;
  });
};
</script>

<template>
  <BoxContainer
    title="Hold Dates"
    class="[&_.box-container]:min-h-[146px] [&_.box-container]:bg-inputs-background"
    header-size="h4"
    :actions="[
      {
        title: 'Add Date',
        icon: 'fa-plus',
        action: (event) => {
          openDateSelector(event);
        },
      },
    ]">
    <div class="flex flex-wrap gap-x-edge gap-y-edge-1/2">
      <div
        v-for="date in [{ date: eventDate, disabled: true }].concat(holdDates)"
        :key="date.date"
        :class="{ ' opacity-50 ': date.disabled }"
        class="text-sm border group gap-[3px] flex h-[27px] items-center min-w-[90px] rounded flex-shrink pl-edge-1/2 py-edge-1/4">
        {{ formatStampAsDate(date.date) }}
        <div class="h-[17px] w-[17px]">
          <div
            v-if="!date.disabled"
            class="hover:bg-content cursor-pointer hover:border-base border-transparent rounded-full border flex items-center justify-center h-[16px] w-[16px]"
            @click="removeDate(date)">
            <i class="fa fa-fw fa-times invisible group-hover:visible text-xs"></i>
          </div>
        </div>
      </div>
    </div>

    <FloatingWindowContainer
      v-if="pageX && pageY"
      :page-x="pageX"
      :page-y="pageY"
      :z-index="ZIndexCrudModal + 1"
      @closed="[(pageX = null), (pageY = null)]">
      <div class="bg p-edge">
        <h4 class="py-edge-1/4">Select Hold Dates</h4>
        <VDatepicker
          inline
          multi-dates
          :model-value="holdDates.map((d) => d.date)"
          :start-date="eventDate"
          :disabled-dates="[eventDate]"
          @update:model-value="toggleDate" />
      </div>
    </FloatingWindowContainer>
  </BoxContainer>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification';
import CrudModal from '@/components/Modals/CrudModal.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import { getNow, isSameOrBefore, timeStampsAreSame } from '@/util/timeFunctions';
import DateHourSelector from '@/components/Inputs/Date/DateHourSelector.vue';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import { ref } from 'vue';
import VButton from '@/components/Inputs/VButton.vue';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';

const props = defineProps({
  shiftSlot: {
    required: true,
    type: Object,
  },
  shift: {
    required: true,
    type: Object,
  },
  uri: {
    required: true,
    type: String,
  },
  allowUpdating: {
    required: false,
    type: Boolean,
    default: false,
  },
  isCheckOutModal: {
    required: false,
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['closed', 'checkedIn', 'checkedOut', 'checkCleared']);
const modalOpen = ref(true);
const working = ref(false);
const timeSlot = ref(null);
const message = ref(props.shiftSlot?.message);

const toast = useToast();

if (props.shiftSlot.check_in && props.shiftSlot.check_out && !props.allowUpdating) {
  toast.warning('Already Checked in and out of.');
  modalOpen.value = false;
}

if (props.isCheckOutModal) {
  timeSlot.value = props.shiftSlot.check_out ? props.shiftSlot.check_out : props.shiftSlot.shift.end;
} else {
  timeSlot.value = props.shiftSlot.check_in ? props.shiftSlot.check_in : props.shiftSlot.shift.start;
}

const closeModal = () => {
  emits('closed');
  modalOpen.value = false;
};
const checkIn = async () => {
  if (props.isCheckOutModal) return;
  working.value = true;
  await axios
    .post(`${props.uri}/check-in`, {
      check_in: timeSlot.value,
    })
    .catch((error) => {
      toast.warning(error.response.data.message ? error.response.data.message : 'Could not Check In.');
      working.value = false;
    });
  toast.success('Checked In.');
  emits('checkedIn', timeSlot.value);
  working.value = false;
  closeModal();
};
const checkOut = async () => {
  if (!props.isCheckOutModal) {
    return;
  }
  working.value = true;
  await axios
    .post(`${props.uri}/check-out`, {
      check_out: timeSlot.value,
      message: message.value,
    })
    .catch((error) => {
      toast.warning(error.response.data.message ? error.response.data.message : 'Could not Check Out.');
      working.value = false;
    });

  toast.success('Checked Out.');
  emits('checkedOut', { check_out: timeSlot.value, message: message.value });
  working.value = false;
  closeModal();
};

const clearSlotCheckInAndOut = async () => {
  if (!props.allowUpdating) return;
  const certain = await useCertaintyModal().assertCertain(
    'Clear Check in and out',
    'Are you sure you want to clear these values?'
  );
  if (!certain) return;
  working.value = true;
  await axios.post(`${props.uri}/clear-check-in-and-out`).catch((error) => {
    toast.warning(error.response.data.message ? error.response.data.message : 'Could not clear.');
    working.value = false;
  });

  toast.success('Cleared.');
  emits('checkCleared');
  working.value = false;
  closeModal();
};

const checkInOrOut = () => {
  if (props.isCheckOutModal) {
    checkOut();
  } else {
    checkIn();
  }
};

const setTimeNow = () => {
  if (props.isCheckOutModal) {
    if (!isSameOrBefore(props.shiftSlot.check_in, getNow())) {
      toast.warning('Cannot Check Out Before Checking In.');
      return;
    }
  }
  timeSlot.value = getNow();
};
</script>
<template>
  <CrudModal
    v-if="modalOpen"
    :hide-all-crud-modals="false"
    only-close-button
    modal-in-modal
    :title="'Check ' + (isCheckOutModal ? 'Out' : 'In')"
    @closed="closeModal">
    <div class="form-layout">
      <div>
        <div class="grid grid-cols-[auto_100px] align-bottom">
          <InputLabel :label="'When Would you like to check ' + (isCheckOutModal ? 'Out' : 'In') + '?'" />
          <VButton
            v-if="!timeStampsAreSame(isCheckOutModal ? shift.end : shift.start, timeSlot, 'minute')"
            size="xs"
            type="default"
            emphasized
            class="float-right"
            @click="timeSlot = isCheckOutModal ? shift.end : shift.start">
            Set Shift {{ isCheckOutModal ? 'end' : 'start' }}
          </VButton>
          <VButton
            v-else
            size="xs"
            title="Select Now"
            class="float-right"
            @click="setTimeNow()" />
        </div>
        <DateHourSelector
          v-model:date-time="timeSlot"
          :type="isCheckOutModal ? 'end' : 'start'"
          :vertical="false"
          :required="true"
          :earliest-date="shiftSlot.check_in"
          :latest-date="shiftSlot.check_out"
          :can-edit="true" />
      </div>

      <TextareaInput
        v-if="isCheckOutModal"
        v-model="message"
        label="Message" />
      <div class="grid w-full grid-cols-[75px_auto_75px]">
        <div></div>
        <div class="text-center">
          <VButton
            :icon="isCheckOutModal ? 'fa-check-double' : 'fa-check'"
            type="primary"
            emphasized
            :loading="working"
            :title="
              (isCheckOutModal && shiftSlot.check_out ? 'Update ' : '') +
              (!isCheckOutModal && shiftSlot.check_in ? 'Update ' : '') +
              'Check ' +
              (isCheckOutModal ? 'Out' : 'In')
            "
            @click="checkInOrOut" />
        </div>
        <VButton
          v-if="shiftSlot.check_in && allowUpdating"
          size="xs"
          tool-tip-text="Remove existing Check in and check out (if exists)."
          type="warning"
          icon="fa-times"
          title="Clear"
          @click="clearSlotCheckInAndOut"></VButton>
      </div>
    </div>
  </CrudModal>
</template>

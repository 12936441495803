<script setup lang="ts">
import EventEditSlideOut from '@/components/Event/Edit/EventEditSlideOut.vue';
import AppIntermediateStepInfo from '@/components/IntermediateStep/AppIntermediateStepInfo.vue';
import IntermediateStepDocumentTab from '@/components/IntermediateStep/Tabs/IntermediateStepDocumentTab.vue';
import IntermediateStepFestival from '@/components/IntermediateStep/Tabs/IntermediateStepFestival.vue';
import IntermediateStepMemberScheduler from '@/components/IntermediateStep/Tabs/IntermediateStepMemberScheduler.vue';
import IntermediateStepRoomScheduler from '@/components/IntermediateStep/Tabs/IntermediateStepRoomScheduler.vue';
import IntermediateStepTemplatesTab from '@/components/IntermediateStep/Tabs/IntermediateStepTemplatesTab.vue';
import CheckListList from '@/components/Models/CheckLists/CheckListList.vue';
import InvoiceBaseList from '@/components/Models/Invoices/InvoiceBaseList.vue';
import MetaDataList from '@/components/Models/MetaData/MetaDataList.vue';
import { useSmallScreen } from '@/composables/use-small-screen';
import { EventMinimalResource } from '@/types/event';
import { InviteResource } from '@/types/invite';
import { currentDateTime, isAfter, toBackendDateFormat } from '@/util/date';
import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue';
import { useToast } from 'vue-toastification';
import ActionButtonGroup from '@/components/Inputs/Components/ActionButtonGroup.vue';
import { storeToRefs } from 'pinia';
import { checkListStore } from '@/store/check-list-store';
import { metaDataStore } from '@/store/meta-data-store';
import { invoiceStore } from '@/store/invoice-store';
import IntermediateStepGuestLists from '@/components/IntermediateStep/Tabs/IntermediateStepGuestLists.vue';
import IntermedaiteStepTaskList from '@/components/Tasks/IntermedaiteStepTaskList.vue';

type Props = {
  isOpen: boolean;
  portalUUID: string;
  event: EventMinimalResource;
  groupId: number;
  currentGroupId: number;
  activeTab: string;
  invite: InviteResource;
  venueId?: number | null;
  admin: boolean;
  onEventPage?: boolean;
  performanceId: number | null;
  visited: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  onEventPage: false,
  performanceId: null,
  venueId: null,
});
const emit = defineEmits<{
  (event: 'updateEvent', arg: EventMinimalResource): void;
  (event: 'eventUpdated', arg: object): void;
  (event: 'eventCancelled', arg: object): void;
  (event: 'eventDuplicated'): void;
  (event: 'eventDeleted'): void;
  (event: 'reloadInvite'): void;
  (event: 'attributeUpdated', arg: { key: string; value: string }): void;
}>();

const { isSmallScreen } = useSmallScreen();

const toast = useToast();

const isPastEvent = computed(() => {
  if (props.event.end_date) {
    return isAfter(toBackendDateFormat(currentDateTime()), props.event.end_date);
  }
  return false;
});

const isRecurringEvent = computed(() => !!props.event.recurring_original_id);

const documents = ref([]);

const eventEditModalOpen = ref(false);
const isMounted = ref(false);

onMounted(() => {
  isMounted.value = true;
});

const accessLevels = computed(() =>
  props.invite?.access_levels === false
    ? {
        has_check_lists: false,
        has_meta_data: false,
        has_invoice_bases: false,
        has_partners: false,
        has_shifts: false,
        has_tasks: false,
      }
    : props.invite.access_levels
);

const listenForBroadcast = () => {
  Echo.channel(`private-Invite.${props.invite.id}`).listen('.intermediate.refresh', (e) => {
    if (props.visited) {
      if (props.isOpen) {
        toast.warning(e.message);
      }
      emit('reloadInvite');
    }
  });
};

onMounted(() => {
  listenForBroadcast();
});

const { list: checkList } = storeToRefs(checkListStore());
const { list: metaData } = storeToRefs(metaDataStore());
const { list: invoiceList } = storeToRefs(invoiceStore());

onUnmounted(() => {
  Echo.leaveChannel(`private-Invite.${props.invite.id}`);

  checkList.value.forEach((_, key) => {
    if (key.includes('Invite_')) {
      checkList.value.delete(key);
    }
  });

  metaData.value.forEach((_, key) => {
    if (key.includes('Invite_')) {
      metaData.value.delete(key);
    }
  });

  invoiceList.value.forEach((_, key) => {
    if (key.includes('Invite_')) {
      invoiceList.value.delete(key);
    }
  });
});

const openEditEventModal = () => {
  eventEditModalOpen.value = false;
  nextTick(() => {
    eventEditModalOpen.value = true;
  });
};
</script>

<template>
  <div
    v-if="visited"
    :class="{
      'border-t': ['templates'].includes(activeTab),
    }"
    class="bg-content-main min-h-full">
    <Teleport
      v-if="isMounted && !isSmallScreen && admin"
      :to="'#' + portalUUID">
      <div v-if="currentGroupId === groupId">
        <ActionButtonGroup
          :actions="[
            event && event?.owner_type === 'App\\Group' && event?.owner_id === groupId && activeTab === 'general-info'
              ? {
                  title: 'Edit Event',
                  icon: 'fa-pencil',
                  emphasized: true,
                  primary: false,
                  action: () => {
                    openEditEventModal();
                  },
                }
              : null,
          ]" />
      </div>
    </Teleport>
    <KeepAlive>
      <AppIntermediateStepInfo
        v-if="activeTab === 'general-info'"
        :admin="admin"
        :event="event"
        :group-id="groupId"
        :invite="invite"
        :venue-id="venueId"
        :festival="invite.festival"
        :performance-id="performanceId"
        :on-event="onEventPage"
        @edit-event="openEditEventModal()"
        @update-event="emit('updateEvent', $event)"
        @reload-invite="$emit('reloadInvite')"
        @attribute-updated="$emit('attributeUpdated', $event)" />

      <IntermediateStepMemberScheduler
        v-else-if="activeTab === 'members'"
        :can-edit="admin"
        :event-id="event.id"
        :is-recurring-event="isRecurringEvent"
        :is-past-event="isPastEvent"
        :start-date="event.start_date"
        :has-shifts="accessLevels.has_shifts"
        :group-id="groupId"
        :invite="invite" />

      <IntermediateStepFestival
        v-else-if="activeTab === 'festival' && invite.festival"
        :can-edit="admin"
        :event="event"
        :festival="invite.festival" />

      <IntermediateStepRoomScheduler
        v-else-if="activeTab === 'rooms'"
        :can-edit="admin"
        :event-id="event.id"
        :is-past-event="isPastEvent"
        :event-start="event.start_date"
        :group-id="groupId"
        :venue-id="venueId"
        :invite="invite" />

      <Suspense v-else-if="activeTab === 'meta-data' && accessLevels.has_meta_data">
        <MetaDataList
          model="Invite"
          :show-times="invite.show_times"
          :model-id="invite.id"
          :is-recurring="invite.recurring_original_id !== null"
          parent-model="Group"
          :is-for-child="invite.parent_id !== null"
          :parent-model-id="groupId"
          :is-venue="venueId !== null"
          :can-edit="admin"
          :documents="documents"
          :is-template="false" />
      </Suspense>

      <Suspense v-else-if="activeTab === 'check-lists' && accessLevels.has_check_lists">
        <CheckListList
          model="Invite"
          :model-id="invite.id"
          :can-edit="admin"
          :is-for-child="invite.parent_id !== null"
          :start="invite.start"
          :is-template="false"
          :is-recurring="invite.recurring_original_id !== null"
          :template-group-id="admin ? groupId : null" />
      </Suspense>

      <Suspense v-else-if="activeTab === 'invoices' && accessLevels.has_invoice_bases">
        <InvoiceBaseList
          :show-times="invite.show_times"
          :is-recurring="invite.event?.recurring_dates?.length > 0"
          :model-id="invite.id"
          model-type="Invite"
          :template-group-id="admin ? groupId : null"
          :can-edit="admin" />
      </Suspense>

      <IntermediateStepGuestLists
        v-else-if="activeTab === 'guest-lists'"
        :can-edit="admin"
        :invite="invite"
        :group-id="groupId" />

      <IntermediateStepDocumentTab
        v-else-if="activeTab === 'documents'"
        :can-edit="admin"
        :invite="invite" />

      <Suspense v-else-if="activeTab === 'tasks' && accessLevels.has_tasks">
        <IntermedaiteStepTaskList
          can-edit
          :group-id="groupId"
          :invite="invite" />
      </Suspense>

      <IntermediateStepTemplatesTab
        v-else-if="admin && activeTab === 'templates'"
        :invite="invite"
        :group-id="groupId"
        :access-levels="accessLevels" />
    </KeepAlive>
    <EventEditSlideOut
      v-if="eventEditModalOpen"
      :event-id="event.id"
      @duplicated="emit('eventDuplicated')"
      @deleted="emit('eventDeleted')"
      @cancelled="emit('eventCancelled', $event)"
      @updated="emit('eventUpdated', $event)" />
  </div>
</template>

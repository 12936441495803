<script setup lang="ts">
import { ref } from 'vue';
import BaseInput, { type BaseInputProps } from '@/components/Base/BaseInput.vue';

const { label = 'Password', canEdit = true } = defineProps<
  {
    label?: string;
  } & BaseInputProps
>();

const modelValue = defineModel<string>({ required: false, default: null });

const inputType = ref('password');

const changeType = () => {
  if (inputType.value === 'password') {
    inputType.value = 'text';
  } else {
    inputType.value = 'password';
  }
};

defineOptions({
  inheritAttrs: false,
});

console.log();
</script>

<template>
  <BaseInput
    v-bind="{ ...$attrs, ...$props }"
    v-model="modelValue"
    :input-type="inputType"
    :label="label"
    :can-edit="canEdit"
    placeholder="******************"
    :left-icon="inputType === 'password' ? 'fa-eye-slash' : 'fa-eye'"
    @left-icon-click="changeType()" />
</template>

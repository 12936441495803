<script setup lang="ts">
import VButton from '@/components/Inputs/VButton.vue';
import { nextTick, ref } from 'vue';
import { useToast } from 'vue-toastification';
import CrudModal from '@/components/Modals/CrudModal.vue';
import DateRangeSelector from '@/components/Inputs/DeleteLater/DateRangeSelector.vue';
import SettingToggle from '@/components/Inputs/Components/SettingToggle.vue';
import VSelect from '@/components/Inputs/VSelect.vue';

type Props = {
  withCancelled?: boolean | null;
  asCSV?: boolean | null;
  start?: string | null;
  end?: string | null;
  title: string;
  working: boolean;
  canDownload: boolean;
  withButton?: boolean;
  hasPreview?: boolean;
  titleHighlight?: string[] | string | null;
  small?: boolean | null;
  xLarge?: boolean | null;
  fullWidth?: boolean | null;
};

const props = withDefaults(defineProps<Props>(), {
  withCancelled: null,
  asCSV: null,
  start: null,
  end: null,
  withButton: true,
  titleHighlight: null,
  small: false,
  xLarge: false,
  fullWidth: false,
});

const emit = defineEmits<{
  (e: 'update:withCancelled', ...args: any[]): void;
  (e: 'update:asCSV', arg: boolean): void;
  (e: 'update:start', ...args: any[]): void;
  (e: 'update:end', ...args: any[]): void;
  (e: 'download', ...args: any[]): void;
  (e: 'preview', ...args: any[]): void;
}>();

const modalOpen = ref(false);
const toast = useToast();

const openModal = () => {
  modalOpen.value = false;
  nextTick(() => {
    modalOpen.value = true;
  });
};
if (!props.withButton) {
  openModal();
}

const closeModal = () => {
  modalOpen.value = false;
};
</script>

<template>
  <div>
    <VButton
      v-if="withButton"
      :tool-tip-text="title"
      title="Get Report"
      icon="fa-download"
      :loading="working"
      @click="openModal" />

    <CrudModal
      v-if="modalOpen"
      :disabled="working || !canDownload"
      :loading="working"
      :title="title"
      :small="small"
      :x-large="xLarge"
      :full-width="fullWidth"
      :title-highlight="titleHighlight"
      create-button-text="Download"
      @closed="closeModal"
      @create="[$emit('download'), (modalOpen = false)]">
      <div class="form-layout">
        <DateRangeSelector
          v-if="start && end"
          :start="start"
          :end="end"
          class="col-span-2"
          @update:start="$emit('update:start', $event)"
          @update:end="$emit('update:end', $event)" />

        <slot />

        <VSelect
          v-if="asCSV !== null"
          label="Format"
          title="Which format would you like to download this report in, CSV (Excel) or PDF?"
          :options="[
            {
              id: true,
              name: 'As CSV',
            },
            {
              id: false,
              name: 'As PDF',
            },
          ]"
          :model-value="asCSV"
          @update:model-value="$emit('update:asCSV', $event)" />

        <SettingToggle
          v-if="withCancelled !== null"
          :model-value="withCancelled"
          label="With Cancelled Events"
          title="If enabled, the report will include cancelled events."
          @update:model-value="$emit('update:withCancelled', $event)" />
      </div>

      <template #create-button-area>
        <div class="flex gap-edge">
          <VButton
            v-if="hasPreview"
            :disabled="working || !canDownload"
            icon="fa-eye"
            title="Preview"
            :emphasized="true"
            type="default"
            @click="$emit('preview')" />
          <VButton
            :disabled="working || !canDownload"
            :loading="working"
            icon="fa-download"
            title="Download"
            type="primary"
            :emphasized="true"
            @click="[$emit('download'), (modalOpen = false)]" />
        </div>
      </template>
    </CrudModal>
  </div>
</template>

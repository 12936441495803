'
<script setup lang="ts">
import { InvoiceBasesResource } from '@/types/invoice-row';
import { ref } from 'vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';

type Props = {
  inviteId: number;
  invoice: InvoiceBasesResource;
  canEdit?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  canEdit: false,
});

const emit = defineEmits<{
  (event: 'update:invoice', value: InvoiceBasesResource): void;
}>();

const loading = ref(false);
const setStatus = async (newStatus) => {
  if (props.invoice.status === newStatus) return;
  if (loading.value) return;
  loading.value = true;
  await axios.patch(`/api/invoice-bases/${props.invoice.id}`, {
    status: newStatus,
  });

  const newInvoice = { ...props.invoice };
  newInvoice.status = newStatus;
  emit('update:invoice', newInvoice);
  loading.value = false;
};
</script>

<template>
  <div>
    <InputLabel label="Status" />
    <VSelect
      placeholder="Status Unconfirmed"
      wrapper-class="-mx-edge-1/2 [&_.dropdown-element]:p-0"
      is-hidden
      :options="['Unconfirmed', 'Confirmed', 'Invoiced']"
      @update:model-value="setStatus($event)"
      :model-value="invoice.status" />
  </div>
</template>

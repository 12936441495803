<script lang="ts" setup>
import { computed, inject, nextTick, onBeforeUnmount, ref, watch } from 'vue';
import { TaskFieldResource, TaskOwnerModels, TaskResource, TaskTemplateListTaskResource } from '@/types/tasks';
import { useNavBarTitleStore } from '@/composables/use-nav-bar-title';
import TaskSlideout from '@/components/Tasks/TaskSlideout.vue';
import { getTask } from '@/services/api-tasks';
import {
  createUuId,
  exchangeValuesOfObject,
  getIndexFromArrayBasedOnId,
  getItemFromArrayBasedOnId,
  getKey,
  removeItemFromArrayBasedOnId,
} from '@/util/globals';
import { formatAppModel } from '@/util/format-app-model';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import TaskListTask from '@/components/Tasks/TaskListTask.vue';
import TableSearchRow from '@/components/Tables/Headers/TableSearchRow.vue';
import { useStorage } from '@vueuse/core';
import { useTaskFields } from '@/composables/use-task-fields';
import TableActionFilter from '@/components/Tables/Headers/TableActionFilter.vue';
import {
  eventMembersKey,
  festivalProjectLeadersKey,
  festivalSectionKey,
  festivalSectionsKey,
  groupKey,
  rolesKey,
} from '@/provide/keys';
import { usePage } from '@inertiajs/vue3';
import { changeAndFormatStamp, getNow } from '@/util/timeFunctions';
import { useToast } from 'vue-toastification';
import { EmitKey, useEmitStore } from '@/store/EmitStore';
import { EventGroupInviteResource, FestivalSectionInviteResource } from '@/types/event';
import { formatModelAndIdToId, formatUserForTaskListForEvent } from '@/util/task-functions';
import TaskListAuditSidebar from '@/components/Tasks/TaskListAuditSidebar.vue';
import TaskListTaskCreator from '@/components/Tasks/TaskListTaskCreator.vue';
import { sortTaskList } from '@/util/task-helper-functions';
import ContentTable from '@/components/Content/ContentTable.vue';
import { useSmallScreen } from '@/composables/use-small-screen';
import TaskModal from '@/components/Tasks/TaskModal.vue';
import { useTaskTemplateListsStore } from '@/store/TaskTemplateListStore';

const props = withDefaults(defineProps<Props>(), {
  eventId: null,
  performanceId: null,
  taskListTitle: null,
  inviteId: null,
  canEdit: false,
  progressBarVisible: false,
  lockedKeys: () => [],
  filters: () => [],
  columnsToShow: () => [],
});

type Props = {
  ownerModel: TaskOwnerModels;
  ownerId: number;
  taskListTitle?: string | null;

  eventId?: number | null;
  inviteId?: number | null;
  performanceId?: number | null;

  canEdit?: boolean | null;
  progressBarVisible?: boolean | null;
  lockedKeys?: string[];

  filters?: string[];

  columnsToShow?: string[];
};

const searchLoading = ref(false);
const loading = ref(false);
const firstLoad = ref(false);
const totalCount = ref(null);
const lastPage = ref(null);
const perPage = ref(25);
const currentPage = ref(1);
const tasks = ref<TaskResource[]>([]);
const newlyCreatedIds = ref<number[]>([]);
const { isSmallScreen } = useSmallScreen();
const group = inject(groupKey, null);
const eventMembers = inject(eventMembersKey, null);
const festivalSectionInjected = inject(festivalSectionKey, null);

const allRoles = inject(rolesKey, null);
const allFestivalSections = inject(festivalSectionsKey, null);
const festivalProjectLeaders = inject(festivalProjectLeadersKey, null);

const activeFieldFilters = useStorage(
  `activeFieldFilters_tasks_${props.ownerModel}_${props.ownerId}_${props.eventId ? '_event_' + props.eventId : ''}${props.inviteId ? '_invite_' + props.inviteId : ''}`,
  []
);
const activePreferenceFilters = useStorage(
  `activePreferenceFilters_tasks_${props.ownerModel}_${props.ownerId}_${props.eventId ? '_event_' + props.eventId : ''}${props.inviteId ? '_invite_' + props.inviteId : ''}`,
  []
);

const { taskFields: fields, fetchTaskFields } = useTaskFields(props.ownerModel, props.ownerId, false, true);

const getParams = () => {
  let params = {
    per_page: perPage.value,
    page: currentPage.value,
  };
  if (props.inviteId) {
    params.model_type = formatAppModel('Invite');
    params.model_id = props.inviteId;
  } else if (props.eventId) {
    params.model_type = formatAppModel('Event');
    params.model_id = props.eventId;
    params.include_public = true;
  } else if (props.performanceId) {
    params.model_type = formatAppModel('Performance');
    params.model_id = props.performanceId;
  } else if (props.ownerModel && props.ownerId) {
    params.model_type = formatAppModel(props.ownerModel);
    params.model_id = props.ownerId;
  }

  params.completion_status = 'false';
  if (activeFieldFilters.value.length > 0) {
    params.task_fields_filters = activeFieldFilters.value.map((f) => {
      return {
        task_field_id: f.id,
        value: f.value,
        operator: f.operator,
      };
    });
  }
  if (activePreferenceFilters.value.length > 0) {
    for (const filter of activePreferenceFilters.value) {
      switch (filter.id) {
        case 'task_assigned_group': {
          const split = filter.value.split('_');
          params[`context_type`] = split[0];
          params[`context_id`] = split[1];
          // params[`context_type`] = 'App\\Group';
          // params[`context_id`] = filter.value;
          break;
        }
        case 'task_my_tasks': {
          params[`user_ids`] = [usePage().props.auth.user.id];
          break;
        }
        case 'tasks_event_tasks': {
          params.include_event_tasks = true;
          break;
        }
        case 'tasks_child_tasks': {
          params.include_children_tasks = true;
          break;
        }
        case 'task_assigned_group_user': {
          if (filter.value) {
            params[`user_ids`] = [filter.value];
          }
          break;
        }
        case 'task_assigned_context': {
          if (filter.value) {
            const split = filter.value.split('_');
            params[`context_type`] = split[0];
            params[`context_id`] = split[1];
          }
          break;
        }
        case 'task_completed_tasks': {
          switch (getKey(filter, 'value')) {
            case 0: {
              params.completion_status = null;
              break;
            }
            case 1: {
              params.completion_status = 'true';
              break;
            }
            case 2: {
              params.completion_status = 'false';
              break;
            }
          }
          break;
        }
        case 'task_due_date': {
          switch (getKey(filter, 'value')) {
            case 'past': {
              params.due_date = 'past';
              break;
            }

            case 'today': {
              params.due_date_interval = {
                start: changeAndFormatStamp({
                  stamp: getNow(),
                  startOf: 'day',
                }),
                end: changeAndFormatStamp({
                  stamp: getNow(),
                  endOf: 'day',
                }),
              };
              break;
            }
            case 'past 7 days': {
              params.due_date_interval = {
                start: changeAndFormatStamp({
                  stamp: getNow(),
                  startOf: 'day',
                  subDays: 7,
                }),
                end: changeAndFormatStamp({
                  stamp: getNow(),
                  startOf: 'day',
                }),
              };
              break;
            }
            case 'next 7 days': {
              params.due_date_interval = {
                start: changeAndFormatStamp({
                  stamp: getNow(),
                  startOf: 'day',
                }),
                end: changeAndFormatStamp({
                  stamp: getNow(),
                  startOf: 'day',
                  addDays: 7,
                }),
              };
              break;
            }
            case 'future': {
              params.due_date = 'future';
              break;
            }
            case 'N/A': {
              params.due_date = 'none';
              break;
            }
          }
          break;
        }
        default: {
          break;
        }
      }
    }
  }
  return params;
};
const fetchData = async (page = 1) => {
  loading.value = true;
  searchLoading.value = false;
  currentPage.value = page;

  const params = getParams();

  const { data } = await window.axios.get('/api/tasks', {
    params,
  });
  tasks.value = data.data;
  lastPage.value = getKey(data.meta, 'last_page', null);
  totalCount.value = getKey(data.meta, 'total', null);

  loading.value = false;
  firstLoad.value = true;
};
const checkToUpdateSlideOut = (uuid: string, action = 'created') => {
  if (!showSlideoutTask.value) return;
  if (uuid !== showSlideoutTask.value.uuid) return;
  const index = getIndexFromArrayBasedOnId(uuid, tasks.value, 'uuid');
  if (index === -1) {
    if (action === 'deleted') {
      useEmitStore().rootEmit('task-deleted', { uuid: uuid });
    }
    return;
  }
  setTimeout(() => {
    useEmitStore().rootEmit('task-changed', tasks.value[index]);
  }, 20);
};

const taskTemplateLists = ref<TaskTemplateListTaskResource | null[]>([]);
const { fetchTaskTemplateLists } = useTaskTemplateListsStore();
const fetchAllTaskTemplateLists = async () => {
  if (!['Group'].includes(props.ownerModel)) {
    return;
  }
  if (!props.inviteId) {
    return;
  }
  taskTemplateLists.value = await fetchTaskTemplateLists(props.ownerModel, props.ownerId, false);
};
const fetchItAll = async () => {
  await fetchData();
  await fetchTaskFields();
  if (eventMembers) {
    await eventMembers.fetch(false);
  }
  if (allRoles) {
    await allRoles.fetchRoles(false);
  }
  if (allFestivalSections) {
    await allFestivalSections.fetchSections(false);
  }
  if (festivalProjectLeaders && ['Festival', 'FestivalSection'].includes(props.ownerModel)) {
    await festivalProjectLeaders.fetchProjectLeaders(false);
  }
  await fetchAllTaskTemplateLists();
};
fetchItAll();
const listenForBroadcasts = () => {
  if (props.ownerModel === 'User') return;
  let echo = null;
  if (props.inviteId) {
    echo = Echo.channel(`private-Invite.${props.inviteId}`);
  } else if (props.eventId) {
    echo = Echo.join(`On.Event.${props.eventId}`);
  } else if (props.ownerModel === 'Group') {
    echo = Echo.join(`On.Group.${props.ownerId}`);
  }
  if (!echo) return;
  echo
    .listen(`.task.created`, async (data) => {
      const uuid = getKey(data, 'uuid');
      if (!uuid) return;
      const response = await getTask(uuid);
      tasks.value = exchangeValuesOfObject(response.data, tasks.value);
    })
    .listen(`.task.updated`, async (data) => {
      const uuid = getKey(data, 'uuid');
      if (!uuid) return;
      const response = await getTask(uuid);
      tasks.value = exchangeValuesOfObject(response.data, tasks.value);
      checkToUpdateSlideOut(uuid);
    })
    .listen(`.task.completed`, async (data) => {
      const uuid = getKey(data, 'uuid');
      if (!uuid) return;
      const index = getIndexFromArrayBasedOnId(uuid, tasks.value, 'uuid');
      if (index > -1) {
        tasks.value[index].completed_at = getNow();
        checkToUpdateSlideOut(uuid);
      }
    })
    .listen(`.task.field`, async (data) => {
      const uuid = getKey(data, 'uuid');
      if (!uuid) return;
      const index = getIndexFromArrayBasedOnId(uuid, tasks.value, 'uuid');
      if (index === -1) return;
      const taskFieldId = getKey(data, 'task_field_id');
      if (!taskFieldId) return;
      const fieldIndex = getIndexFromArrayBasedOnId(
        taskFieldId,
        tasks.value[index].task_task_field_pivots,
        'task_field_id'
      );
      const value = getKey(data, 'value');
      if (fieldIndex > -1) {
        tasks.value[index].task_task_field_pivots[fieldIndex].value = value;
        tasks.value[index].task_task_field_pivots[fieldIndex].task_field_option_id = getKey(
          data,
          'task_field_option_id'
        );
      } else {
        tasks.value[index].task_task_field_pivots.push({
          value: value,
          task_id: tasks.value[index].id,
          task_field_id: taskFieldId,
          task_field_option_id: getKey(data, 'task_field_option_id'),
        });
      }
      checkToUpdateSlideOut(uuid);
    })
    .listen(`.task.deleted`, async (data) => {
      const uuid = getKey(data, 'uuid');
      if (!uuid) return;
      const index = getIndexFromArrayBasedOnId(uuid, tasks.value, 'uuid');
      if (index > -1) {
        const userName = getKey(data, 'userName');
        if (userName && tasks.value[index].title) {
          useToast().info('Task "' + tasks.value[index].title + '" deleted by ' + userName);
        }
        tasks.value.splice(index, 1);
      }
      checkToUpdateSlideOut(uuid, 'deleted');
    });
};
listenForBroadcasts();

const showSlideoutTask = ref(null);
const openTask = async (task: TaskResource) => {
  showSlideoutTask.value = null;
  await nextTick();
  showSlideoutTask.value = { id: task.id, uuid: task.uuid };
};
const useNavBarTitleStore1 = useNavBarTitleStore();
if (['Group', 'Festival'].includes(props.ownerModel) && !props.inviteId && !props.eventId && !props.performanceId) {
  useNavBarTitleStore1.navBarMinimum = true;
  useNavBarTitleStore1.allowItemsInSubNav = false;
}
onBeforeUnmount(() => {
  useNavBarTitleStore1.reset();
});

const orderBy = ref<string>('');
const orderDirection = ref<'desc' | 'asc'>('desc');

const sortedTasks = ref([]);

const sortTasks = () => {
  let localRows = [...tasks.value];
  sortedTasks.value = sortTaskList(localRows, orderBy.value, orderDirection.value !== 'desc', newlyCreatedIds.value);
};
watch(
  () => tasks.value,
  () => {
    sortTasks();
  },
  { deep: true }
);
watch(
  () => orderBy.value,
  () => {
    sortTasks();
  },
  { deep: true }
);
watch(
  () => orderDirection.value,
  () => {
    sortTasks();
  },
  { deep: true }
);

const sortBy = (prop: string | null) => {
  if (!props.eventId && !props.inviteId) return null;
  if (orderBy.value === prop && orderDirection.value === 'asc') {
    orderBy.value = null;
    orderDirection.value = 'asc';
    return;
  }
  orderDirection.value = orderDirection.value === 'desc' ? 'asc' : 'desc';
  orderBy.value = prop ?? '';
};
const hideColumns = useStorage(`hideColumnsForTaskList${props.ownerModel}_${props.ownerId}`, ['Description']);
const displayTaskFields = useStorage(`displayTaskFieldsForTaskList${props.ownerModel}_${props.ownerId}`, []);

const getDropdown = () => {
  if (isSmallScreen.value) {
    return [];
  }
  const sections = [];

  sections.push({
    title: 'Static Fields',
    type: 'header',
  });
  [
    props.columnsToShow.includes('events') ? 'Event' : null,
    props.columnsToShow.includes('group_selector') ? 'Group' : null,
    props.columnsToShow.includes('own_groups') ? 'Group' : null,
    props.columnsToShow.includes('performance') ? 'Performance' : null,
    props.ownerModel === 'User' ? null : 'User',
    'Due Date',
    'Description',
  ]
    .filter((i) => i !== null)
    .forEach((c) => {
      sections.push({
        title: c,
        value: c,
        selected: !hideColumns.value.includes(c),
        action: () => {
          hideColumns.value.includes(c)
            ? hideColumns.value.splice(hideColumns.value.indexOf(c), 1)
            : hideColumns.value.push(c);
        },
      });
    });
  if (fields.value && fields.value.length > 0) {
    sections.push({
      title: 'Fields',
      type: 'header',
    });
    fields.value.forEach((field: TaskFieldResource) => {
      sections.push({
        title: field.title,
        value: field.id,
        selected: displayTaskFields.value.includes(field.id),
        action: () => {
          displayTaskFields.value.includes(field.id)
            ? displayTaskFields.value.splice(displayTaskFields.value.indexOf(field.id), 1)
            : displayTaskFields.value.push(field.id);
        },
      });
    });
  }
  return sections;
};

const getFieldStyle = (field: TaskFieldResource) => {
  switch (field.component) {
    case 'field-number': {
      return 'min-width: 80px;';
    }
    case 'field-time': {
      return 'min-width: 90px;';
    }
    case 'field-toggle': {
      return 'min-width: 100px;';
    }
    case 'field-date': {
      return 'min-width: 110px;';
    }
    case 'field-list': {
      return 'min-width: 140px;';
    }

    case 'field-text':
    default: {
      return 'min-width: 150px;';
    }
  }
};

const newlyCompletedTasks = ref([]);

const getOptionsForUserFilterOnFestival = () => {
  if (props.ownerModel !== 'Festival') return [];
  let array = [];

  if (
    festivalProjectLeaders &&
    festivalProjectLeaders.festivalProjectLeaders.value &&
    festivalProjectLeaders.festivalProjectLeaders.value.length > 0
  ) {
    array.push({
      formattedModelId: null,
      label: 'All',
      options: festivalProjectLeaders.festivalProjectLeaders?.value.map(function (m) {
        return {
          id: m.user.id,
          name: m.user.name,
        };
      }),
    });

    const filteredRoles = allRoles?.roles?.value;
    if (filteredRoles && filteredRoles.length > 0) {
      for (const role of filteredRoles) {
        array.push({
          formattedModelId: formatModelAndIdToId('App\\Role', role.id),
          label: role.name,
          options: festivalProjectLeaders?.festivalProjectLeaders?.value
            .filter((m) => m.role_id === role.id)
            .map(function (m) {
              return {
                id: m.user.id,
                name: m.user.name,
              };
            }),
        });
      }
    }
  }

  const festivalSections = allFestivalSections
    ? allFestivalSections?.festivalSections?.value?.filter((f) => f.users.length > 0)
    : [];
  if (festivalSections) {
    for (const festivalSection of festivalSections) {
      array.push({
        formattedModelId: formatModelAndIdToId('App\\Models\\Festivals\\FestivalSection', festivalSection.id),
        label: festivalSection.name,
        options: festivalSection.users.map(function (m) {
          return {
            id: m.id,
            name: m.name,
          };
        }),
      });
    }
  }

  return array;
};

const getOptionsForContextSelector = () => {
  if (props.ownerModel !== 'Festival') return [];
  let array = [];

  const filteredFestivalSection = allFestivalSections?.festivalSections?.value;
  if (filteredFestivalSection && filteredFestivalSection.length > 0) {
    array.push({
      label: 'Festival Sections',
      options: filteredFestivalSection.map((r) => {
        return {
          id: formatModelAndIdToId('App\\Models\\Festivals\\FestivalSection', r.id),
          name: r.name,
        };
      }),
    });
  }
  const filteredRoles = allRoles?.roles?.value;
  if (filteredRoles && filteredRoles.length > 0) {
    array.push({
      label: 'Roles',
      options: filteredRoles.map((r) => {
        return {
          id: formatModelAndIdToId('App\\Role', r.id),
          name: r.name,
        };
      }),
    });
  }

  return array;
};

const getPreferencesForActionFilter = () => {
  return [
    props.ownerModel === 'Group' && group && group.childGroups.length > 0
      ? {
          id: 'task_assigned_group',
          name: 'Group',
          isRegularFilter: false,
          isQuickFilter: true,
          options: [
            {
              id: formatModelAndIdToId('App\\Group', group.id),
              name: group.name,
            },
          ].concat(
            group.childGroups.map((child) => {
              return {
                id: formatModelAndIdToId('App\\Group', child.id),
                name: child.name,
              };
            })
          ),
        }
      : null,
    props.ownerModel === 'Festival' && getOptionsForContextSelector().length > 0 && !props.eventId
      ? {
          id: 'task_assigned_context',
          name: 'Group',
          isRegularFilter: false,
          isQuickFilter: true,
          options: getOptionsForContextSelector(),
        }
      : null,
    eventMembers &&
    eventMembers.loaded &&
    eventMembers.invites &&
    (eventMembers?.invites.value?.groups.length > 0 || eventMembers?.invites.value?.festival_sections.length > 0)
      ? {
          id: 'task_assigned_group',
          name: 'Group',
          isRegularFilter: false,
          isQuickFilter: true,
          options: eventMembers?.invites.value?.groups
            ?.flatMap((groupInvite: EventGroupInviteResource) => {
              return [
                {
                  name: groupInvite.invitable.name,
                  id: formatModelAndIdToId(groupInvite.invitable_type, groupInvite.invitable_id),
                },
              ].concat(
                groupInvite.invitable.invited_subgroups.map((g) => {
                  return {
                    id: formatModelAndIdToId('App\\Group', g.id),
                    name: g.name + ' (' + groupInvite.invitable.name + ')',
                  };
                })
              );
            })
            .concat(
              eventMembers?.invites.value?.festival_sections.map(
                (festivalSectionInvite: FestivalSectionInviteResource) => {
                  return {
                    name: festivalSectionInvite.invitable.name,
                    id: formatModelAndIdToId(festivalSectionInvite.invitable_type, festivalSectionInvite.invitable_id),
                  };
                }
              )
            ),
        }
      : null,
    props.ownerModel === 'User'
      ? null
      : {
          id: 'task_my_tasks',
          name: 'My Tasks',
          isQuickFilter: true,
          isRegularFilter: false,
        },
    {
      id: 'task_completed_tasks',
      name: 'Completion Status',
      quickFilterName: 'Show Completed Tasks',
      isQuickFilter: true,
    },
    {
      id: 'task_due_date',
      name: 'Due Date',
      isQuickFilter: true,
    },
    group
      ? {
          id: 'task_assigned_group_user',
          name: 'Assigned User',
          options: [
            {
              formattedModelId: formatModelAndIdToId('App\\Group', group.id),
              label: group.name,
              options: group.members.map((m) => {
                return {
                  id: m.id,
                  name: m.name,
                };
              }),
            },
          ].concat(
            group.childGroups.map((child) => {
              return {
                formattedModelId: formatModelAndIdToId('App\\Group', child.id),
                label: child.name,
                options: child.members.map((m) => {
                  return {
                    id: m.id,
                    name: m.name,
                  };
                }),
              };
            })
          ),
        }
      : null,
    eventMembers && eventMembers.loaded && eventMembers.invites.value
      ? {
          id: 'task_assigned_group_user',
          name: 'Assigned User',
          options: formatUserForTaskListForEvent(eventMembers.invites.value, eventMembers.allMembers.value),
        }
      : null,
    festivalSectionInjected && festivalSectionInjected.users.length > 0
      ? {
          id: 'task_assigned_group_user',
          name: 'Assigned User',
          options: [
            {
              formattedModelId: null,
              label: 'All',
              options: festivalSectionInjected.users.map(function (m) {
                return {
                  id: m.id,
                  name: m.name,
                };
              }),
            },
          ],
        }
      : null,
    props.ownerModel === 'Festival' && getOptionsForUserFilterOnFestival().length > 0
      ? {
          id: 'task_assigned_group_user',
          name: 'Assigned User',
          options: getOptionsForUserFilterOnFestival(),
        }
      : null,
    props.filters.includes('event_tasks')
      ? {
          id: 'tasks_event_tasks',
          name: 'Show Event Tasks',
          isQuickFilter: true,
          isRegularFilter: false,
        }
      : null,
    props.filters.includes('child_tasks')
      ? {
          id: 'tasks_child_tasks',
          name: 'Show Sub Group Tasks',
          isQuickFilter: true,
          isRegularFilter: false,
        }
      : null,
  ].filter((i) => i !== null);
};

const getFilterFields = () => {
  return [
    {
      id: 'task_fields',
      name: 'Task Fields',
      type: 'task_fields',
      fields: fields.value.map((field: TaskFieldResource) => {
        return {
          id: field.id,
          name: field.title,
          type: 'task_field',
          component: field.component,
          options: getKey(field, 'task_field_options', []).map((o) => o.title),
        };
      }),
    },
  ];
};

const taskTitle = ref('Tasks');

const getTitle = () => {
  if (props.taskListTitle) {
    taskTitle.value = props.taskListTitle;
  }
  if (props.inviteId) {
    taskTitle.value = 'Private Event Tasks';
  } else if (props.eventId) {
    taskTitle.value = 'Event Tasks';
  }

  switch (props.ownerModel) {
    case 'Group': {
      taskTitle.value = 'Group Tasks';
      break;
    }
    case 'Festival':
    case 'FestivalSection': {
      taskTitle.value = 'Festival Tasks';
      break;
    }
    default:
      break;
  }
};

getTitle();

const getSort = (type: string, direction = 'asc') => {
  if (!props.eventId && !props.inviteId) return null;
  newlyCreatedIds.value = [];
  return orderBy.value === type && orderDirection.value === direction;
};
const hasPaginator = () => {
  return lastPage.value !== null && totalCount.value !== null;
};

const getTaskFromState = (state) => {
  const task = state.item?.payload;
  const taskListOrigin = getKey(task, 'taskListOrigin');
  if (taskListOrigin && taskListOrigin === origin) {
    return null;
  }
  return task;
};
const auditsOpen = ref(false);

const origin = createUuId('task_list_');

useEmitStore().$subscribe((_, state) => {
  switch (state.item?.key) {
    case 'task-created': {
      if (!props.eventId && !props.inviteId) return;
      if (activeFieldFilters.value.length > 0 || activePreferenceFilters.value.length > 0) return;
      const task = getTaskFromState(state);
      if (!task) return;
      if (!props.inviteId && task.public) {
        tasks.value = exchangeValuesOfObject(task, tasks.value, ['id'], 'id', true, true);
      }
      break;
    }
    case 'task-changed': {
      const task = getTaskFromState(state);
      if (!task) return;
      if (!props.inviteId && props.eventId) {
        if (task.public) {
          tasks.value = exchangeValuesOfObject(task, tasks.value, ['id'], 'id');
        } else {
          tasks.value = removeItemFromArrayBasedOnId(task.id, tasks.value);
        }
        return;
      }
      tasks.value = exchangeValuesOfObject(task, tasks.value, ['id'], 'id', props.eventId !== null);
      break;
    }
    case 'task-deleted': {
      const task = getTaskFromState(state);
      if (!task) return;
      tasks.value = removeItemFromArrayBasedOnId(task.id, tasks.value);
      break;
    }
    default:
      break;
  }
});

const rootEmitChanges = (task: TaskResource, event: EmitKey = 'task-changed') => {
  useEmitStore().rootEmit(event, { ...task, taskListOrigin: origin });
};

const getEmptyTaskListText = computed(() => {
  if (tasks.value.length > 0 || !firstLoad.value || loading.value) return null;

  if (activeFieldFilters.value.length > 0 || activePreferenceFilters.value.length > 0) {
    return 'No Tasks matching your search found.';
  }
  switch (props.ownerModel) {
    case 'User': {
      return 'No Tasks Has Been Assigned To You';
    }
    default: {
      return 'No Tasks Created Yet';
    }
  }
});

const showModal = ref(false);

const openCreateModal = () => {
  showModal.value = true;
};

const executeTaskTemplate = async (taskTemplateList: TaskTemplateListTaskResource) => {
  loading.value = true;
  await axios.post('/api/task-template-lists/' + taskTemplateList.id + '/execute', {
    model_type: 'App\\Invite',
    model_id: props.inviteId,
  });
  await fetchData();
  loading.value = false;
};

defineOptions({
  inheritAttrs: false,
});
</script>

<template>
  <ContentTable
    :loading="loading"
    super-header="(BETA)"
    :teleport="false"
    :title="taskTitle"
    :first-load="firstLoad"
    :actions="[
      ownerModel === 'Group' && taskTemplateLists.length > 0 && inviteId
        ? {
            title: 'Add Template',
            icon: 'fa-plus fa-regular',
            dropdownHeader: 'Add Task Templates',
            loading: loading,
            dropdown: taskTemplateLists.map((taskTemplateList) => {
              return {
                title: taskTemplateList.title,
                action: (close: () => void) => {
                  executeTaskTemplate(taskTemplateList);
                  close();
                },
              };
            }),
          }
        : null,
      ownerModel === 'Group' && canEdit
        ? {
            title: 'Audits',
            icon: 'fa-history fa-regular',
            emphasized: true,
            action: () => {
              auditsOpen = false;
              nextTick(() => {
                auditsOpen = true;
              });
            },
          }
        : null,
    ]"
    :paginator-data="
      hasPaginator()
        ? {
            perPage: perPage,
            total: totalCount,
            pages: lastPage,
            page: currentPage,
            action: (newPage) => {
              fetchData(newPage);
            },
          }
        : null
    "
    :empty-message="getEmptyTaskListText"
    class="[&_.content-table-header]:border-b [&_.content-header-under-title-container]:!p-0"
    content-slot-classes="!py-0 !gap-0"
    empty-icon="fa-tasks fa-regular">
    <template #underTitle>
      <div>
        <TableSearchRow
          :with-search="false"
          :loading="loading"
          :padding-left="!canEdit"
          :padding-right="true"
          :can-create="isSmallScreen"
          :columns="getDropdown()"
          @create="openCreateModal">
          <template
            v-if="!isSmallScreen"
            #afterSearch>
            <div class="flex gap-edge items-center">
              <TaskListTaskCreator
                v-if="canEdit"
                :owner-model="ownerModel"
                :owner-id="ownerId"
                :event-id="eventId"
                :invite-id="inviteId"
                :performance-id="performanceId"
                :active-preference-filters="activePreferenceFilters"
                @created="[newlyCreatedIds.push($event.id), tasks.unshift($event)]" />
              <TableActionFilter
                v-if="!isSmallScreen"
                v-model:field-filters="activeFieldFilters"
                v-model:preference-filters="activePreferenceFilters"
                v-model:loading="loading"
                :preferences="getPreferencesForActionFilter()"
                :sections="getFilterFields()"
                :close-on-scroll="true"
                @search="fetchData()" />
            </div>
          </template>
        </TableSearchRow>
      </div>
    </template>

    <TaskModal
      v-if="showModal"
      :invite-id="inviteId"
      :event-id="eventId"
      :owner-model="ownerModel"
      :owner-id="ownerId"
      :performance-id="performanceId"
      :active-preference-filters="activePreferenceFilters"
      @created="[newlyCreatedIds.push($event.id), tasks.unshift($event)]"
      @closed="showModal = false" />

    <template
      v-if="!getEmptyTaskListText && firstLoad"
      #head>
      <VTableRow head>
        <VTableCell
          :sort-down="getSort('title', 'asc')"
          :sort-up="getSort('title', 'desc')"
          style="min-width: 370px; max-width: 25%"
          class="bg"
          @clicked="sortBy('title')">
          Task Name
        </VTableCell>
        <VTableCell
          v-if="columnsToShow.includes('private_status') && !isSmallScreen"
          style="min-width: 55px; max-width: 55px">
          <i class="fa fa-fw fa-lock fa-regular"></i>/
          <i class="fa fa-fw fa-lock-open fa-regular"></i>
        </VTableCell>
        <VTableCell
          v-if="!hideColumns.includes('Due Date') && !isSmallScreen"
          style="min-width: 140px"
          :sort-down="getSort('Due Date', 'asc')"
          :sort-up="getSort('Due Date', 'desc')"
          @clicked="sortBy('Due Date')">
          Due Date
        </VTableCell>

        <VTableCell
          v-if="!hideColumns.includes('Description') && !isSmallScreen"
          style="min-width: 140px">
          Description
        </VTableCell>

        <VTableCell
          v-if="columnsToShow.includes('group_selector') && !hideColumns.includes('Group') && !isSmallScreen"
          :sort-down="getSort('group', 'asc')"
          :sort-up="getSort('group', 'desc')"
          style="min-width: 200px"
          @clicked="sortBy('group')">
          Group
        </VTableCell>
        <VTableCell
          v-if="columnsToShow.includes('own_groups') && !hideColumns.includes('Group') && !isSmallScreen"
          :sort-down="getSort('group', 'asc')"
          :sort-up="getSort('group', 'desc')"
          style="min-width: 200px"
          @clicked="sortBy('group')">
          Group
        </VTableCell>
        <VTableCell
          v-if="columnsToShow.includes('user_selector') && !hideColumns.includes('User') && !isSmallScreen"
          :sort-down="getSort('user', 'asc')"
          :sort-up="getSort('user', 'desc')"
          style="min-width: 200px"
          @clicked="sortBy('user')">
          User
        </VTableCell>
        <VTableCell
          v-if="columnsToShow.includes('events') && !hideColumns.includes('Event') && !isSmallScreen"
          style="min-width: 170px">
          Event
        </VTableCell>
        <VTableCell
          v-if="columnsToShow.includes('performance') && !hideColumns.includes('Performance') && !isSmallScreen"
          style="min-width: 170px">
          Performance
        </VTableCell>

        <VTableCell
          v-for="displayablefield in displayTaskFields
            .map((f) => getItemFromArrayBasedOnId(f, fields))
            .filter((field) => field !== null)"
          v-if="!isSmallScreen"
          :style="getFieldStyle(displayablefield)"
          :sort-down="getSort('task_field_' + displayablefield.id, 'asc')"
          :sort-up="getSort('task_field_' + displayablefield.id, 'desc')"
          @clicked="sortBy('task_field_' + displayablefield.id)">
          {{ displayablefield.title }}
        </VTableCell>
      </VTableRow>
    </template>
    <TaskListTask
      v-for="task in sortedTasks"
      :key="task.id"
      :columns-to-show="columnsToShow"
      :task="task"
      :owner-id="ownerId"
      :owner-model="ownerModel"
      :locked-keys="lockedKeys"
      :hide-columns="hideColumns"
      :display-task-fields="displayTaskFields"
      :task-fields="fields"
      :event-id="eventId"
      :invite-id="inviteId"
      @completed="newlyCompletedTasks.push(task.id)"
      @open="openTask(task)"
      @add-or-update-task="[exchangeValuesOfObject($event, tasks), rootEmitChanges($event)]" />
  </ContentTable>
  <TaskSlideout
    v-if="showSlideoutTask"
    :owner-model="ownerModel"
    :initial-task="showSlideoutTask"
    :owner-id="ownerId"
    :locked-keys="lockedKeys"
    @updated="tasks = exchangeValuesOfObject($event, tasks)"
    @deleted="[(tasks = removeItemFromArrayBasedOnId($event, tasks)), rootEmitChanges({ id: $event }, 'task-deleted')]"
    @closed="showSlideoutTask = null" />

  <TaskListAuditSidebar
    v-if="auditsOpen"
    :owner-model="ownerModel"
    :owner-id="ownerId"
    :event-id="eventId"
    :invite-id="inviteId"
    @closed="auditsOpen = false" />
</template>

<script setup lang="ts">
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import DocumentCellDocument from '@/components/Config/ColumnConfig/Cells/Files/DocumentCellDocument.vue';
import { DocumentFolderResource, DocumentResource } from '@/types/document';
import { computed } from 'vue';
import { sortArrayBy } from '@/util/globals';

type Props = {
  documentFolder: DocumentFolderResource;
  openIds: Set<number>;
  number?: number;
  selectedDocumentId: number | string | null;
  imageOnly?: boolean;
  searchTerm?: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  number: 1,
  imageOnly: false,
  searchTerm: '',
});

const emit = defineEmits<{
  (event: 'update:openIds', arg: Set<number>): void;
  (event: 'selectDocument', arg: DocumentResource): void;
}>();

const toggleOpenId = (id: number) => {
  const newOpenIds = new Set(props.openIds);
  if (newOpenIds.has(id)) {
    newOpenIds.delete(id);
  } else {
    newOpenIds.add(id);
  }
  emit('update:openIds', newOpenIds);
};

const onTest = (e: Set<number>) => {
  emit('update:openIds', e);
};

const folderIsOpen = computed(() => props.openIds.has(props.documentFolder.id) || props.searchTerm);

defineOptions({
  inheritAttrs: false,
});
</script>

<template>
  <VTableRow
    :main-row="folderIsOpen"
    clickable
    @click="toggleOpenId(documentFolder.id)">
    <VTableCell>
      <div :style="'padding-left: ' + number + 'px;'">
        <i
          v-if="folderIsOpen"
          class="fa fa-folder-open fa-fw" />
        <i
          v-else
          class="fa fa-folder fa-fw" />
        {{ documentFolder.name }}
      </div>
    </VTableCell>
    <VTableCell style="width: 80px">
      <div class="flex items-center justify-between">
        <div
          :class="
            documentFolder.documents.filter((d) => (imageOnly ? d.mime_type.includes('image') : true)).length < 1 &&
            'opacity-20'
          "
          :title="
            'There are ' +
            documentFolder.documents.filter((d) => (imageOnly ? d.mime_type.includes('image') : true)).length +
            ' documents in this folder.'
          ">
          <small class="relative">{{
            documentFolder.documents.filter((d) => (imageOnly ? d.mime_type.includes('image') : true)).length
          }}</small>
          <i
            :class="
              documentFolder.documents.filter((d) => (imageOnly ? d.mime_type.includes('image') : true)).length > 0 &&
              'text-highlight'
            "
            class="fa fa-file fa-fw fa-xs fa-regular mt-edge-1/2" />
        </div>
        <div
          :class="documentFolder.children.length < 1 && 'opacity-20'"
          :title="'There are ' + documentFolder.children.length + ' sub-folders of this folder.'">
          <small class="relative text-[hsl(var(--blue-100))]">{{ documentFolder.children.length }}</small>
          <i
            :class="documentFolder.children.length > 0 && 'text-highlight'"
            class="fa fa-folder fa-fw fa-xs fa-regular" />
        </div>
      </div>
    </VTableCell>
    <VTableCell style="width: 85px" />
  </VTableRow>
  <DocumentCellDocument
    v-for="document in folderIsOpen
      ? sortArrayBy(
          documentFolder.documents
            .filter((d) => (imageOnly ? d.mime_type.includes('image') : true))
            .filter((d) => (searchTerm ? d.filename.toLowerCase().includes(searchTerm.toLowerCase().trim()) : true)),
          'filename'
        )
      : []"
    :key="document.id"
    :image-only="imageOnly"
    :document="document"
    :search-term="searchTerm"
    :selected-document-id="selectedDocumentId"
    @select-document="$emit('selectDocument', $event)" />
  <DocumentCellFolder
    v-for="docFolder in folderIsOpen ? documentFolder.children : []"
    :key="docFolder.id"
    :image-only="imageOnly"
    :number="number + 10"
    :open-ids="openIds"
    :search-term="searchTerm"
    :document-folder="docFolder"
    :selected-document-id="selectedDocumentId"
    @update:open-ids="onTest"
    @select-document="$emit('selectDocument', $event)" />
</template>

import { sendAnErrorToSlack } from '@/util/error-handler';

export const safeHtmlStringify = (value) => {
  if (!value) return value;

  // Create a temporary DOM element to parse the HTML
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = value;

  // Function to recursively sanitize a DOM node
  const sanitizeNode = (node) => {
    // Remove script tags and their content
    if (node.nodeName === 'SCRIPT') {
      sendAnErrorToSlack('User Sanitized away SCRIPT', '<@U5KFQVBU5>');
      if (node.parentNode) {
        node.parentNode.removeChild(node);
      }
      return;
    }

    // Remove inline event handlers
    Array.from(node.attributes || []).forEach((attr) => {
      if (attr.name.startsWith('on')) {
        sendAnErrorToSlack('User Sanitized away on', ' <@U5KFQVBU5>');
        node.removeAttribute(attr.name);
      }
    });

    // Recursively sanitize child nodes
    Array.from(node.childNodes).forEach((childNode) => {
      sanitizeNode(childNode);
    });
  };

  // Sanitize all child nodes of the temporary element
  Array.from(tempDiv.childNodes).forEach((childNode) => {
    sanitizeNode(childNode);
  });

  // Return the sanitized HTML as a string
  return tempDiv.innerHTML;
};

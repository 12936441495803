'
<script setup lang="ts">
import { InvoiceBasesResource } from '@/types/invoice-row';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import { useGroupProjectLeaders } from '@/composables/use-group-project-leaders';
import VSelect from '@/components/Inputs/VSelect.vue';

type Props = {
  inviteId: number;
  groupId: number;
  invoice: InvoiceBasesResource;
  canEdit?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  canEdit: false,
});

const emit = defineEmits<{
  (event: 'update:invoice', value: InvoiceBasesResource): void;
}>();

const { projectLeaders, fetchGroupProjectLeaders } = useGroupProjectLeaders(props.groupId, false);
fetchGroupProjectLeaders();

const setPartnerContact = async (projectLeaderId) => {
  await axios.patch(`/api/invoice-bases/${props.invoice.id}`, {
    project_leader_id: projectLeaderId,
  });

  const newInvoice = { ...props.invoice };
  newInvoice.project_leader_id = projectLeaderId;
  newInvoice.project_leader = null;
  emit('update:invoice', newInvoice);
};
</script>

<template>
  <div>
    <InputLabel label="Project Leader"></InputLabel>
    <VSelect
      :model-value="invoice.project_leader_id"
      :can-edit="canEdit"
      :is-hidden="true"
      option-value="name"
      nullable
      placeholder="-- Select --"
      wrapper-class="-mx-edge-1/2 [&_.dropdown-element]:p-0"
      nullable-display-text="N/A"
      :in-box="true"
      :options="projectLeaders"
      @update:model-value="setPartnerContact" />
  </div>
</template>

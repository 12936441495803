<script lang="ts" setup>
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import { ref } from 'vue';
import { getKey } from '@/util/globals';
import VButton, { ButtonProps } from '@/components/Inputs/VButton.vue';
import TextInput from '@/components/Inputs/TextInput.vue';

export type ActionButtonProps = {
  icon: string;
  title: string;
  imgURL?: string;
  type?: ButtonProps['type'];
  postIcon?: ButtonProps['postIcon'];
  action: () => void;
  hoverText?: string;
  class?: string;
  disabled?: boolean;
  loading?: boolean;
  dropdown?: ActionButtonProps[];
  isDanger?: boolean;
  primary?: boolean;
  size?: ButtonProps['size'];
};

const { actions } = defineProps<{ actions: ActionButtonProps[] }>();

const dropdown = ref(null);
const componentKey = ref(0);
[50, 100, 500, 1000].forEach((time) => {
  setTimeout(() => {
    componentKey.value++;
  }, time);
});

const searchTerm = ref('');
</script>

<template>
  <div class="flex gap-edge items-center action-button-group-container">
    <template
      v-for="action in actions.filter((a) => a !== null)"
      :key="action.title">
      <template v-if="action.hasOwnProperty('dropdown')">
        <VDropdown
          :close-on-click="action.hasOwnProperty('closeOnClick') ? action.closeOnClick : true"
          :max-height-dropdown="getKey(action, 'maxHeightDropdown', '300px')"
          :highlight-text="searchTerm"
          :page-x-modifier="action.position ?? 0"
          :items="
            action.dropdown.filter((d) => {
              return !searchTerm || searchTerm.length === 0 || getKey(d, 'type') === 'header'
                ? true
                : d.title.toLowerCase().includes(searchTerm.toLowerCase());
            })
          ">
          <template #click-area>
            <div ref="dropdown">
              <VButton
                :loading="action.loading"
                :class="getKey(action, 'buttonClasses', '')"
                :type="action.type ?? 'default'"
                :size="getKey(action, 'size', 'md')"
                :emphasized="getKey(action, 'emphasized', true)"
                :post-icon="getKey(action, 'postIcon')"
                :title="getKey(action, 'title', '')"
                :icon="getKey(action, 'icon', '')"
                :tool-tip-text="getKey(action, 'hoverText', null)"
                :disabled="getKey(action, 'disabled', false)" />
            </div>
          </template>

          <template
            v-if="getKey(action, 'filterable', false) || getKey(action, 'dropdownHeader') !== null"
            #aboveDropdown>
            <h4
              v-if="getKey(action, 'dropdownHeader') !== null"
              class="px-edge py-edge-1/2 bg-[--color-background-modal-header]">
              {{ action.dropdownHeader }}
            </h4>
            <TextInput
              v-if="getKey(action, 'filterable', false)"
              v-model="searchTerm"
              placeholder="Search"
              with-clear
              set-focus
              square />
          </template>
        </VDropdown>
      </template>
      <div v-else-if="getKey(action, 'buttonDropdown', []).length > 0">
        <div class="flex items-center">
          <VButton
            :type="action.type ?? 'default'"
            :icon="action.icon"
            :disabled="getKey(action, 'disabled', false)"
            :title="action.title"
            :size="getKey(action, 'size', 'md')"
            :emphasized="true"
            class="rounded-r-none"
            @click="
              (event) => {
                if (action.hasOwnProperty('action')) {
                  action.action(event);
                } else if (action.hasOwnProperty('onClick')) {
                  action.onClick(event);
                }
              }
            " />
          <VDropdown
            :max-height-dropdown="getKey(action, 'maxHeightDropdown', '300px')"
            :page-x-modifier="action.position ?? undefined"
            :items="action.buttonDropdown">
            <template #click-area>
              <VButton
                :type="action.type ?? 'default'"
                :tool-tip-text="getKey(action, 'buttonDropdownTitle')"
                :emphasized="true"
                :only-icon-emphasized="true"
                :disabled="getKey(action, 'disabled', false)"
                :size="getKey(action, 'size', 'md')"
                class="rounded-l-none border-l border-l-soft hover:border-l-soft"
                post-icon="fa-chevron-down" />
            </template>
            <template
              v-if="getKey(action, 'filterable', false) || getKey(action, 'dropdownHeader') !== null"
              #aboveDropdown>
              <h4
                v-if="getKey(action, 'dropdownHeader') !== null"
                class="px-edge py-edge-1/2 bg-[--color-background-modal-header]">
                {{ action.dropdownHeader }}
              </h4>
              <TextInput
                v-if="getKey(action, 'filterable', false)"
                v-model="searchTerm"
                placeholder="Search"
                with-clear
                set-focus
                square />
            </template>
          </VDropdown>
        </div>
      </div>
      <VButton
        v-else
        :type="action.icon === 'fa-trash' && !action.type ? 'warning' : action.type"
        :class="(getKey(action, 'icon', null) === 'fa-trash' ? '' : '') + ' ' + getKey(action, 'buttonClasses', '')"
        :loading="getKey(action, 'loading', false)"
        :ping-indicator="getKey(action, 'additionalItems', []).includes('ping-indicator')"
        :disabled="getKey(action, 'disabled', false)"
        :icon="action.icon"
        :title="action.title"
        :post-icon="getKey(action, 'postIcon', undefined)"
        :size="getKey(action, 'size', 'md')"
        :emphasized="getKey(action, 'emphasized', true)"
        :tool-tip-text="getKey(action, 'toolTopText', getKey(action, 'hoverText'))"
        :stop-click="true"
        @click="
          (event) => {
            if (action.hasOwnProperty('action')) {
              action.action(event);
            } else if (action.hasOwnProperty('onClick')) {
              action.onClick(event);
            }
          }
        " />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed, getCurrentInstance, inject, onMounted, provide, ref } from 'vue';
import { createUuId } from '@/util/globals';
import { twMerge } from 'tailwind-merge';
import { cva } from 'class-variance-authority';
import { currentHoverIdType, inputInTable, mainRowType } from '@/provide/keys';

export type TableCellProps = {
  componentType?: 'td' | 'th';
  mainCell?: boolean;
  noBackground?: boolean;
  classes?: string;
  clickable?: boolean;
  sortUp?: boolean;
  sortDown?: boolean;
  noPadding?: boolean;
  hasInput?: boolean;
};

const {
  componentType = 'td',
  mainCell = false,
  classes = '',
  clickable = false,
  sortUp = null,
  sortDown = null,
  noPadding = false,
  hasInput = false,
} = defineProps<TableCellProps>();

defineEmits<{
  clicked: [arg: MouseEvent];
}>();

const currentHoverId = inject(currentHoverIdType, null);
const isMainRow = inject(mainRowType, () => false);

const id = createUuId('cell_');
const instance = getCurrentInstance();
const cell = ref<HTMLElement | null>(null);
const isHeader = ref(false);

const getNumber = () => {
  setTimeout(() => {
    try {
      if (instance.parent.props.hasOwnProperty('head') && componentType !== 'th') {
        isHeader.value = instance.parent.props.head;
      } else if (props.componentType === 'th') {
        isHeader.value = true;
      }
    } catch (_) {}
  }, 50);
};

onMounted(() => {
  getNumber();
});

const mouseAction = () => {
  if (!currentHoverId) return;

  const hoverId = isHeader.value ? id : cell.value?.getAttribute('data-column-hover-id');
  if (hoverId) {
    currentHoverId?.update(hoverId);
  }
};

const currentlyHovered = computed(
  () => currentHoverId && currentHoverId.currentHoverId && id && currentHoverId.currentHoverId.value === id
);

const tableCell = cva(`${clickable ? 'cursor-pointer' : ''} text-left`, {
  variants: {
    type: {
      'th': `text-sm text relative truncate align-bottom `,
      'td': `text-base bg`,
    },
    main: {
      true: 'text',
      false: 'text-soft',
    },
  },
});

const filterCurrentlyActive = computed(() => isHeader.value && (sortDown || sortUp));

provide(inputInTable, hasInput);
</script>

<template>
  <component
    :is="isHeader ? 'th' : componentType"
    :id="isHeader ? id : null"
    ref="cell"
    :class="
      twMerge(
        tableCell({
          type: isHeader || componentType === 'th' ? 'th' : 'td',
          main: mainCell || isMainRow() || isHeader,
        }),
        classes,
        $attrs.class,
        noPadding || hasInput ? ' !p-0' : ''
      )
    "
    @click="$emit('clicked', $event)"
    @mouseover="mouseAction(true)"
    @mouseleave="mouseAction(false)">
    <div
      :class="[
        { 'flex items-center': sortDown !== null && sortUp !== null },
        { 'text-sm truncate font-semibold': isHeader || componentType === 'th' },
      ]">
      <slot />
      <i
        v-if="sortDown !== null && sortUp !== null"
        class="fa fa-fw"
        :class="
          (sortDown ? 'fa-sort-down' : '') +
          (sortUp ? 'fa-sort-up' : '') +
          (sortDown === false && sortUp === false ? 'fa-sort' : '')
        " />
      <div
        v-if="(isHeader && currentlyHovered) || filterCurrentlyActive"
        class="absolute bottom-0 left-0 w-full border-highlight border-b" />
    </div>
  </component>
</template>

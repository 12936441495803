<script setup lang="ts">
import { ref } from 'vue';
import SearchSelect from '@/components/Inputs/SearchSelect.vue';
import CrudModal from '@/components/Modals/CrudModal.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import VenueCreateModal from '@/components/Modals/VenueCreateModal.vue';
import { getKey } from '@/util/globals';

type Props = {
  venue: any;
  unclaimed?: boolean;
  canCreate?: boolean;
  withLabel?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  unclaimed: false,
  canCreate: false,
  withLabel: true,
});

const emit = defineEmits<{
  (e: 'selectedVenue', arg: any): void;
}>();

const showProjectLeaderModal = ref(false);
const currentVenue = ref(null);
const currentGroup = ref(null);
const currentProjectLeaders = ref([]);

const url = '/api/venues';
const params = {
  unclaimed: props.unclaimed,
};

const fetchVenue = async (id) => {
  const { data } = await axios.get(`/api/venues/${id}`);
  return data;
};
const fetchProjectLeaders = async (groupId) => {
  const { data } = await axios.get(`/api/groups/${groupId}/projectLeaders`);
  return data;
};

const onUpdate = async (venue) => {
  if (!venue.id) {
    emit('selectedVenue', [null, null, null]);
    return;
  }
  if (venue.isClaimed) {
    const newVenue = await fetchVenue(venue.id);
    if (newVenue.group?.using_project_leaders) {
      const projectLeaders = await fetchProjectLeaders(newVenue.group.id);
      if (!projectLeaders.length) {
        emit('selectedVenue', [venue, null, null]);
      } else if (projectLeaders.length === 1) {
        emit('selectedVenue', [venue, projectLeaders[0].id, projectLeaders[0].name]);
      } else {
        currentVenue.value = newVenue;
        currentGroup.value = newVenue.group;
        currentProjectLeaders.value = projectLeaders;
        if (!newVenue.group.force_project_leader_on_invite) {
          currentProjectLeaders.value.push({
            id: null,
            name: 'No one at this point.',
          });
        }
        showProjectLeaderModal.value = true;
      }
    }
  } else {
    emit('selectedVenue', [venue, null, null]);
  }
};

const closeProjectLeaderModal = () => {
  showProjectLeaderModal.value = false;
  currentVenue.value = null;
  currentGroup.value = null;
  currentProjectLeaders.value = [];
};

const onLeaderClick = (leader) => {
  emit('selectedVenue', [currentVenue.value, leader.id, leader.name]);
  closeProjectLeaderModal();
};

const showCreateVenueModal = ref(false);
const newVenueName = ref('');

const onCreateVenue = (name) => {
  newVenueName.value = name;
  showCreateVenueModal.value = true;
};

const onVenueCreated = (venue) => {
  emit('selectedVenue', [venue, null, null]);
  showCreateVenueModal.value = false;
};

const onCreateVenueClose = () => {
  showCreateVenueModal.value = false;
};
</script>

<template>
  <div>
    <SearchSelect
      :model-value="getKey(venue, 'id', null) === null ? null : venue"
      :url="url"
      is-object
      :label="withLabel ? 'Venue' : ''"
      :can-create="canCreate"
      :params="params"
      with-clear
      @create="onCreateVenue"
      @update:model-value="onUpdate" />

    <CrudModal
      v-if="showProjectLeaderModal"
      title="Set Project leader"
      only-close-button
      @closed="closeProjectLeaderModal">
      <div>
        <p class="mb-edge-1/4">
          {{ currentVenue.name }} uses project leaders to organise their work. Select your contact
        </p>
        <VTable>
          <VTableRow
            v-for="leader in currentProjectLeaders"
            :key="leader.id">
            <VTableCell
              clickable
              @click="onLeaderClick(leader)">
              {{ leader.name }}
            </VTableCell>
          </VTableRow>
        </VTable>
        <h4 class="mt-edge-1/4 text-center">This is all the project leaders listed from the company.</h4>
      </div>
    </CrudModal>
    <VenueCreateModal
      v-if="showCreateVenueModal"
      :with-button="false"
      :new-venue-name="newVenueName"
      @created="onVenueCreated"
      @closed="onCreateVenueClose" />
  </div>
</template>

<script setup lang="ts">
import { documentKey } from '@/provide/keys';
import { DocumentResource } from '@/types/document';
import { computed, inject, nextTick, ref } from 'vue';
import ImageViewModal from '@/components/Documents/ImageViewModal.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import DocumentSelectOrUploadModal from '@/components/Documents/DocumentSelectOrUploadModal.vue';

type Props = {
  modelValue: string | number | null;
  canEdit: boolean;
  isShowMode: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:modelValue', arg: any): void;
  (event: 'focus'): void;
}>();

const { fetch, folderDocuments, unconnectedDocuments, allDocuments, model, modelId } = inject(documentKey, {
  fetch: async () => {},
  loading: ref(false),
  folderDocuments: computed(() => []),
  unconnectedDocuments: computed(() => []),
  allDocuments: computed(() => []),
  model: 'Event',
  modelId: 0,
});
fetch();

const empty = computed(() => folderDocuments.value.length === 0 && unconnectedDocuments.value.length === 0);

const modalOpen = ref(false);
const loading = ref(false);

const document = computed(() => allDocuments.value.find((doc) => doc.id === Number(props.modelValue)));

const removeDocument = async () => {
  const removeIt = await useDeleteObjectModal().assertReadyToDeleteModal(
    'Remove Document',
    'Are you sure you want to remove this document?'
  );
  if (!removeIt) return;

  emit('update:modelValue', null);
};

const openModal = async () => {
  modalOpen.value = false;
  await nextTick();
  modalOpen.value = true;
};

const isImage = computed(() => {
  if (!document.value) return false;
  return document.value.mime_type.startsWith('image');
});
const showImage = ref(false);
const documentAdded = async (doc: DocumentResource) => {
  emit('update:modelValue', doc.id);
  await fetch(true);
};
</script>
<template>
  <div
    v-if="document"
    class="flex items-center gap-edge-1/4">
    <div
      class="w-full truncate"
      :title="document.filename"
      :class="{ 'cursor-pointer': isImage }"
      @click="showImage = true">
      <img
        :src="document.download_url + '/-/resize/x200/'"
        style="max-width: 100%; max-height: 100%; width: auto; height: auto" />
    </div>
    <div v-if="canEdit">
      <VButton
        size="xs"
        icon="fa-times fa-regular"
        @focus="[emit('focus')]"
        @click="removeDocument" />
    </div>
    <ImageViewModal
      v-if="isImage && showImage"
      :image="document"
      :can-edit="false"
      @close="showImage = false" />
  </div>
  <div
    v-else-if="!isShowMode"
    class="flex h-full items-center justify-center">
    <VButton
      v-if="canEdit"
      size="sm"
      icon="fa-image fa-regular"
      tool-tip-text="Select or Upload image to this cell"
      @focus="[emit('focus')]"
      @click="openModal" />

    <DocumentSelectOrUploadModal
      v-if="modalOpen"
      image-only
      :selected-document-id="modelValue"
      @document:selected="documentAdded($event)" />
  </div>
</template>

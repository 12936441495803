<script lang="ts" setup>
import VTimePicker from '@/components/Inputs/Date/VTimePicker.vue';
import { ref, watch } from 'vue';
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';
import { formatStampAsDate, formatStampAsDateTime, formatStampAsTime, stampIsValid } from '@/util/timeFunctions';

type PropType = {
  dateTime?: string;
  label?: string;
  required?: boolean;
  isHidden?: boolean;
  canEdit?: boolean;
  withDate?: boolean;
  setFocus?: boolean;
  setFocusDate?: boolean;
  vertical?: boolean;
  minDate?: string;
  highlightDays?: string[];
};

const props = withDefaults(defineProps<PropType>(), {
  dateTime: '',
  label: '',
  required: false,
  isHidden: false,
  canEdit: true,
  withDate: true,
  vertical: false,
  setFocus: false,
  setFocusDate: false,
  minDate: '',
  highlightDays: () => [],
});

const emit = defineEmits<{
  (event: 'update:dateTime', val: string): void;
  (event: 'blur', val: string): void;
  (event: 'enter'): void;
  (event: 'clear'): void;
  (event: 'cancelEdit'): void;
}>();

const theDate = ref('');
const time = ref('');

watch(
  () => props.dateTime,
  (e) => {
    if (stampIsValid(e)) {
      theDate.value = formatStampAsDate(e);
      time.value = formatStampAsTime(e);
    } else {
      if (!props.required) {
        theDate.value = null;
        time.value = null;
      }
    }
  },
  { immediate: true }
);

watch([theDate, time], () => {
  if (!!theDate.value && !!time.value) {
    emit('update:dateTime', formatStampAsDateTime(`${theDate.value} ${time.value}`));
  } else if (theDate.value && !time.value) {
    emit('update:dateTime', formatStampAsDateTime(`${theDate.value} 12:00`));
  } else {
    emit('update:dateTime', null);
  }
});
const emitBlur = () => {
  if (!time.value) {
    time.value = '12:00';
  }
  if (!!theDate.value && !!time.value) {
    emit('blur', formatStampAsDateTime(`${theDate.value} ${time.value}`));
  } else {
    emit('blur', null);
  }
};

const dateTimeCleared = () => {
  if (props.required) return;
  time.value = null;
  theDate.value = null;
  emit('clear');
  emitBlur();
};
</script>

<template>
  <div :class="{ 'flex items-end': !vertical }">
    <div
      v-if="withDate"
      class="flex-1">
      <VDatepicker
        v-model="theDate"
        :label="label"
        :is-hidden="isHidden"
        :can-edit="canEdit"
        :set-focus="setFocusDate"
        :highlight-days="highlightDays"
        :input-class="!vertical ? '!rounded-r-none !rounded-b-none !rounded-bl' : '!rounded-b-none'"
        :earliest-date="minDate"
        :required="required"
        :class="{ ' [&_.datepicker]:mb-[2px]': vertical }"
        @clear="dateTimeCleared"
        @update:model-value="emitBlur" />
    </div>
    <div :class="vertical || !withDate ? 'w-full' : 'w-1/3 min-w-[110px]'">
      <VTimePicker
        :model-value="time"
        :can-edit="canEdit"
        :set-focus="setFocus"
        :input-class="
          (!vertical && withDate
            ? 'rounded-l-none rounded-t-none border-t-0 border-t rounded-tr'
            : withDate
              ? 'border-t-0 rounded-t-none'
              : '') +
          (!vertical ? '  ' : '') +
          ' [&>*]:pl-edge'
        "
        :is-hidden="isHidden"
        :label="withDate ? null : label"
        :required="required"
        @clear="dateTimeCleared"
        @blur="[(time = $event), emitBlur()]" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { TaskResource } from '@/types/tasks';
import { formatAppModel } from '@/util/format-app-model';
import { patchTask } from '@/services/api-tasks';
import { useToast } from 'vue-toastification';

type Props = {
  task: TaskResource;
  lockedKeys?: string[];
  inList?: boolean;
  emitResults?: boolean;
  ownerModel: 'Group' | 'User';
  ownerId: number;
  inviteId?: number;
  eventId?: number;
};

const props = withDefaults(defineProps<Props>(), {
  lockedKeys: () => [],
  inList: true,
  emitResults: true,
  eventId: null,
  inviteId: null,
});
const emit = defineEmits<{
  (e: 'updated', value: TaskResource): void;
}>();

const canChangeState =
  formatAppModel(props.task.owner_type) === formatAppModel(props.ownerModel) &&
  props.task.owner_id === props.ownerId &&
  props.task.event_id &&
  props.task.invite_id;

const getTitle = () => {
  return props.task.public
    ? 'Task is public, available for everyone with read access to the event.' +
        (canChangeState ? 'Click to make private.' : '')
    : 'Task is private only to internal and those added either as group or user.' +
        (canChangeState ? 'Click to make public' : '');
};

// const patchEntity = async (entity: TaskEntityResource, newEntityType: string, newEntityId: number) => {
//   await patchTaskEntityPivot(props.task.uuid, entity.id, {
//     entity_id: newEntityId,
//     entity_type: newEntityType,
//   });
// };
const changeState = async () => {
  if (!props.inviteId || !props.eventId) return;
  const localTask = { ...props.task };
  localTask.public = !localTask.public;
  await patchTask(props.task.uuid, { public: localTask.public });
  if (localTask.public) {
    useToast().info('Public');
  } else {
    useToast().info('Private');
  }
  emit('updated', localTask);
};
</script>

<template>
  <div
    v-if="inList"
    class="p-1">
    <button
      v-if="canChangeState"
      :class="{
        'text-soft': task.completed_at,
        'hover:!bg-row-alternate': inList,
        ' ': !inList,
      }"
      :title="getTitle()"
      class="btn btn-tiny ring-warning btn-info text border-transparent bg-transparent !ring-transparent truncate py-[2px] min-w-[50px] border-none shadow-none text-center"
      @click="changeState">
      <i
        :class="task.public ? 'fa-lock-open text-success' : 'fa-lock text-warning'"
        class="fa fa-fw fa-regular" />
    </button>
    <div
      v-else
      :title="getTitle()"
      class="text-soft text-sm ml-[17px]">
      <i
        :class="task.public ? 'fa-lock-open' : 'fa-lock'"
        class="fa fa-fw fa-regular" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import CrudModal from '@/components/Modals/CrudModal.vue';
import DisplayPhone from '@/components/Display/DisplayPhone.vue';
import DisplayEmail from '@/components/Display/DisplayEmail.vue';

type User = {
  name?: string | null;
  phone?: string | null;
  email?: string | null;
  avatar_url?: string | null;
  country_code?: string | null;
  first_name?: string | null;
  last_name?: string | null;
};

type Props = {
  name?: string | null;
  phone?: string | null;
  countryCode?: string | null;
  email?: string | null;
  user?: User | null;
  showImage?: boolean;
  internalToggle?: boolean;
  avatarUrl?: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  name: null,
  phone: null,
  countryCode: null,
  email: null,
  user: null,
  showImage: true,
  internalToggle: false,
  avatarUrl: null,
});

defineEmits<{
  (event: 'closed'): void;
}>();

const isOpen = ref(!props.internalToggle);

const userName = computed(() => {
  if (props.user?.name) return props.user.name;
  if (props.user?.first_name) return `${props.user.first_name} ${props.user?.last_name}`;
  if (props.name) return props.name;
  return 'error';
});
</script>
<template>
  <div
    v-if="internalToggle"
    class="cursor-pointer"
    @click="isOpen = !isOpen">
    <slot name="toggle" />
  </div>
  <CrudModal
    v-if="isOpen"
    small
    only-close-button
    :title="userName"
    @closed="[$emit('closed'), internalToggle ? (isOpen = false) : null]">
    <div class="grid grid-cols-[160px_auto] items-center gap-edge">
      <div class="text-center">
        <img
          v-if="showImage && (avatarUrl || (user && user.avatar_url))"
          :src="user && user.hasOwnProperty('avatar_url') ? user.avatar_url : avatarUrl"
          alt="Default user avatar"
          class="responsive-image rounded-full object-cover max-h-[160px] max-w-[160px]" />
        <img
          v-if="!showImage"
          :src="$page.props.asset_url + 'assets/images/default-avatar.png'"
          alt="User Profile image"
          class="responsive-image rounded-full h-[160px] w-[160px]" />
      </div>
      <div>
        <div class="info-wrapper flex flex-col gap-edge-1/2">
          <DisplayEmail :email="user && user.hasOwnProperty('email') ? user.email : email" />
          <DisplayPhone
            :phone-number="user && user.hasOwnProperty('phone') ? user.phone : phone"
            :country-code="user && user.hasOwnProperty('country_code') ? user.country_code : countryCode" />
        </div>
      </div>
    </div>
    <slot name="underContent" />
  </CrudModal>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import CrudModal from '@/components/Modals/CrudModal.vue';
import MyAccount from '@/components/Navbar/ProfileSettingModal/MyAccount.vue';
import MySettings from '@/components/Navbar/ProfileSettingModal/MySettings.vue';
import NotificationList from '@/components/NotificationList.vue';
import ModeSelector from '@/components/Inputs/Components/ModeSelector.vue';
import ManageAffiliationsModal from '@/components/ManageAffiliationsModal.vue';
import LoadingScreen from '@/components/LoadingScreen.vue';

type Props = {
  initialTab?: string | null;
};
const props = defineProps<Props>();

const sections = ref([
  {
    value: 'my-account',
    name: 'Profile',
  },
  {
    value: 'my-settings',
    name: 'Account',
  },
  {
    value: 'affiliations',
    name: 'Affiliations',
  },
  {
    value: 'notifications',
    name: 'Notifications',
  },
]);
const activeSection = ref(props.initialTab ? props.initialTab : sections.value[0].value);
</script>

<template>
  <CrudModal
    only-close-button
    :with-first-input-focus="false"
    large
    title="User Settings">
    <div
      :class="{ ' bg-content-main rounded-b ': ['notifications', 'affiliations'].includes(activeSection) }"
      class="h-[70vh] md:h-[700px] md:max-h-[80vh] flex flex-col -m-edge">
      <ModeSelector
        v-model="activeSection"
        class="border-b px-edge pt-edge bg-content"
        :modes="sections" />

      <div class="flex-1 overflow-auto p-edge">
        <KeepAlive>
          <MyAccount v-if="activeSection === 'my-account'" />

          <MySettings v-else-if="activeSection === 'my-settings'" />

          <ManageAffiliationsModal v-else-if="activeSection === 'affiliations'" />

          <Suspense v-else-if="activeSection === 'notifications'">
            <NotificationList />
            <template #fallback>
              <div class="flex items-center justify-center mt-[100px]">
                <LoadingScreen />
              </div>
            </template>
          </Suspense>
        </KeepAlive>
      </div>
    </div>
  </CrudModal>
</template>

export const groupRoomTabs = () => {
  return [
    {
      key: 'rooms',
      title: 'Calendar',
    },
    {
      key: 'rooms_manager',
      title: 'Rooms Manager',
    },
  ];
};

import type { PageTab } from '@/types/page-tab';

export const groupMemberTabs = (hasShifts: boolean, admin: boolean): PageTab[] => {
  const array = [
    {
      key: 'members',
      title: 'Calendar',
    },
    {
      key: 'members_manager',
      title: 'Members Manager',
    },
  ];
  if (hasShifts && admin) {
    array.push({
      key: 'shifts_list',
      title: 'Shifts list',
    });
  }
  if (admin) {
    array.push({
      key: 'invite-links',
      title: 'Invite Links',
    });
  }
  return array;
};

<script lang="ts" setup>
import DateHourSelector from '@/components/Inputs/Date/DateHourSelector.vue';
import DurationSelector from '@/components/Inputs/Date/DurationSelector.vue';
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';
import { changeAndFormatStamp, formatStampAsDateTime, getDiffInInterval } from '@/util/timeFunctions';
import { dateTimeFormat, timeFormat } from '@/variables/date-format';
import moment from 'moment';
import { nextTick, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import { useSmallScreen } from '@/composables/use-small-screen';

type Props = {
  minDate?: string | null;
  withTime?: boolean;
  withDate?: boolean;
  start?: string | null;
  end?: string | null;
  required?: boolean;
  canEdit?: boolean;
  withDuration?: boolean;
  vertical?: boolean;
  isHidden?: boolean;
  layoutOutside?: boolean;
  showEnd?: boolean;
  setFocus?: boolean;
  setFocusEnd?: boolean;
  allowNoDuration?: boolean;
  earliestStart?: string | null;
  earliestEnd?: string | null;
  labelStart?: string;
  labelEnd?: string;
  outsideDuration?: number | null;
  durationOptions?: number[] | null;
  allowUpdateOfStart?: (() => void) | null;
  overrideEmitFormat?: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  minDate: null,
  withTime: false,
  withDate: true,
  start: null,
  end: null,
  required: false,
  canEdit: true,
  withDuration: false,
  vertical: false,
  isHidden: false,
  layoutOutside: false,
  showEnd: true,
  setFocus: false,
  setFocusEnd: false,
  allowNoDuration: true,
  earliestStart: null,
  earliestEnd: null,
  labelStart: 'Start',
  labelEnd: 'End',
  outsideDuration: null,
  durationOptions: null,
  allowUpdateOfStart: null,
  overrideEmitFormat: null,
});

const emit = defineEmits<{
  (event: 'update:start', value: string | null): void;
  (event: 'update:end', value: string | null): void;
  (event: 'duration', value: number): void;
}>();

const toast = useToast();
const duration = ref(0);

const getDurations = () => {
  if (!props.end) return;
  duration.value = moment(props.end).diff(props.start, 'minutes');
};

const getDateTimeEmitFormat = () => {
  if (props.overrideEmitFormat) {
    return props.overrideEmitFormat;
  }
  if (props.withTime && props.withDate) {
    return dateTimeFormat;
  }
  if (props.withTime) {
    return timeFormat;
  }
  if (props.withDate) {
    return dateTimeFormat;
  }
  return dateTimeFormat;
};

getDurations();
watch(
  () => props.outsideDuration,
  (e) => {
    if (!props.start) return;
    if (!props.outsideDuration) return;
    emit(
      'update:end',
      changeAndFormatStamp({
        stamp: props.start,
        addMinutes: props.outsideDuration,
      })
    );
    nextTick(() => {
      getDurations();
    });
  }
);
watch(
  () => props.start,
  (e) => {
    if (!props.end) return;
    if (moment(props.end).isBefore(e)) {
      emit('update:end', changeAndFormatStamp({ stamp: e, addMinutes: duration.value ? duration.value : 60 }));
      toast.info('End time changed');
    }
    nextTick(() => {
      getDurations();
    });
  }
);

watch(
  () => props.end,
  (e) => {
    if (!props.start) return;
    if (moment(props.start).isAfter(e)) {
      emit('update:start', changeAndFormatStamp({ stamp: e, subMinutes: duration.value ? duration.value : 60 }));
      toast.info('Start Time changed');
    }
    nextTick(() => {
      getDurations();
    });
  }
);

watch(duration, (newDuration) => {
  if (isNaN(newDuration)) return;
  if (newDuration === 0 && !props.allowNoDuration) return;
  if (newDuration === 0) {
    emit('update:end', null);
  } else {
    const data = moment(props.start).add(newDuration, 'minutes');
    emit('update:end', formatStampAsDateTime(data));
  }
  emit('duration', newDuration);

  nextTick(() => {
    getDurations();
  });
});

const showIt = ref(true);
const startUpdated = async (newStart: string) => {
  if (props.allowUpdateOfStart !== null) {
    const allowed = await props.allowUpdateOfStart(newStart);
    if (!allowed) {
      showIt.value = false;
      await nextTick();
      showIt.value = true;
      return;
    }
  }
  if (!props.allowNoDuration && !props.end) {
    if (!props.end || !moment(props.end).isValid() || moment(newStart).isSameOrAfter(props.end, 'minute')) {
      emit('update:end', moment(newStart).add(60, 'minutes').format(getDateTimeEmitFormat()));
    }
  }
  emit('update:start', newStart);
  await nextTick();
  getDurations();
};

const endUpdated = (newEnd: string, oldNewEnd: string | null = null) => {
  if (!props.start || !moment(props.start).isValid()) {
    emit('update:start', moment(oldNewEnd).subtract(1, 'hour').format(getDateTimeEmitFormat()));
  } else if (newEnd && moment(newEnd).isSameOrBefore(props.start, 'minute')) {
    if (oldNewEnd) {
      emit('update:start', moment(oldNewEnd).subtract(1, 'hour').format(getDateTimeEmitFormat()));
    } else if (props.withTime) {
      const minutesAfterMidnight = getDiffInInterval(newEnd, changeAndFormatStamp({ stamp: newEnd, startOf: 'day' }));
      if (minutesAfterMidnight < 60 * 6) {
        endUpdated(changeAndFormatStamp({ stamp: newEnd, addDays: 1 }), newEnd);
        return;
      } else {
        emit('update:start', moment(newEnd).subtract(1, 'hour').format(getDateTimeEmitFormat()));
      }
    }
  }
  emit('update:end', newEnd);
  nextTick(() => {
    getDurations();
  });
};
if (props.start && !props.end && props.withDuration && !props.allowNoDuration) {
  emit('update:end', changeAndFormatStamp({ stamp: props.start, addMinutes: 60 }));
}

const { isSmallScreen } = useSmallScreen();
</script>

<template>
  <div
    v-if="!layoutOutside && showIt"
    :class="
      withDuration
        ? vertical
          ? 'md:grid-cols-3'
          : 'md:grid-cols-5'
        : withTime && withDate
          ? 'md:grid-cols-4'
          : 'md:grid-cols-2'
    "
    class="grid gap-edge">
    <VDatepicker
      v-if="!withTime"
      :label="labelStart"
      :earliest-date="earliestStart"
      :model-value="start"
      :required="required"
      :can-edit="canEdit"
      :is-hidden="isHidden"
      class="flex-1"
      @update:model-value="startUpdated" />
    <DateHourSelector
      v-else
      :label="labelStart"
      :required="required"
      :min-date="earliestStart"
      :with-date="withDate"
      :set-focus="setFocus"
      :date-time="start"
      :class="vertical ? 'col-span-1' : 'col-span-2'"
      :can-edit="canEdit"
      :is-hidden="isHidden"
      :vertical="vertical"
      @update:date-time="startUpdated" />

    <div
      v-if="withDuration"
      class="duration-selector"
      :style="!isSmallScreen ? 'height: 40px' : ''">
      <DurationSelector
        v-model="duration"
        :is-hidden="isHidden"
        :durations="durationOptions ? durationOptions : undefined"
        :allow-no-duration="allowNoDuration"
        is-minutes
        :can-edit="canEdit && !!start" />
    </div>

    <VDatepicker
      v-if="!withTime"
      :model-value="end"
      :label="labelEnd"
      :earliest-date="earliestEnd"
      :required="required"
      :can-edit="canEdit"
      :is-hidden="isHidden"
      class="flex-1"
      @update:model-value="endUpdated" />
    <DateHourSelector
      v-else-if="!withDuration || (withDuration && (duration !== 0 || !allowNoDuration))"
      :label="labelEnd"
      :required="required"
      :with-date="withDate"
      :date-time="end"
      :min-date="earliestEnd"
      :class="vertical ? 'col-span-1' : 'col-span-2'"
      :is-hidden="isHidden"
      :can-edit="canEdit && !!start"
      :vertical="vertical"
      @update:date-time="endUpdated" />
  </div>
  <template v-else-if="showIt && layoutOutside">
    <VDatepicker
      v-if="!withTime"
      :label="labelStart"
      :model-value="start"
      :required="required"
      :earliest-date="earliestStart"
      :can-edit="canEdit"
      :is-hidden="isHidden"
      class="flex-1"
      @update:model-value="startUpdated" />
    <DateHourSelector
      v-if="withTime"
      :label="labelStart"
      :required="required"
      :min-date="earliestStart"
      :date-time="start"
      :with-date="withDate"
      :can-edit="canEdit"
      :is-hidden="isHidden"
      :vertical="vertical"
      @update:date-time="startUpdated" />
    <div v-if="withDuration">
      <DurationSelector
        v-model="duration"
        :is-hidden="isHidden"
        :durations="durationOptions ? durationOptions : undefined"
        :allow-no-duration="allowNoDuration"
        is-minutes
        :can-edit="canEdit && !!start" />
    </div>
    <VDatepicker
      v-if="!withTime && showEnd"
      :model-value="end"
      :label="labelEnd"
      :set-focus="setFocusEnd"
      :required="required"
      :can-edit="canEdit"
      :earliest-date="earliestEnd"
      :is-hidden="isHidden"
      class="flex-1"
      @update:model-value="endUpdated" />
    <DateHourSelector
      v-if="withTime && showEnd && (!withDuration || (withDuration && duration !== 0))"
      :set-focus="setFocusEnd && !withDate"
      :label="labelEnd"
      :required="required"
      :with-date="withDate"
      :min-date="earliestEnd"
      :date-time="end"
      :is-hidden="isHidden"
      :can-edit="canEdit && !!start"
      :vertical="vertical"
      @update:date-time="endUpdated" />
  </template>
</template>

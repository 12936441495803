<script lang="ts" setup>
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import { computed, nextTick, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import { TaskFieldResource, TaskOwnerModels, TaskResource } from '@/types/tasks';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { changeCompleteStatusOfTask, getTask, postTaskComment } from '@/services/api-tasks';
import { useTaskFields } from '@/composables/use-task-fields';
import TaskUserCell from '@/components/Tasks/TaskUserCell.vue';
import TaskDateCell from '@/components/Tasks/TaskDateCell.vue';
import { getIndexFromArrayBasedOnId, getItemFromArrayBasedOnId, sortArrayBy, updateItem } from '@/util/globals';
import { allAvailableFieldTypes } from '@/util/fields';
import VRichText from '@/components/Inputs/VRichText.vue';
import ActionButtonGroup from '@/components/Inputs/Components/ActionButtonGroup.vue';
import TaskTaskFieldCell from '@/components/Tasks/TaskTaskFieldCell.vue';
import { useEmitStore } from '@/store/EmitStore';
import { usePage } from '@inertiajs/vue3';
import TaskSlideoutCommentsAndActivity from '@/components/Tasks/TaskSlideoutCommentsAndActivity.vue';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import TaskContextCell from '@/components/Tasks/TaskContextCell.vue';
import { useSmallScreen } from '@/composables/use-small-screen';
import { openRoute } from '@/util/route';
import TaskPerformanceCell from '@/components/Tasks/Cells/TaskPerformanceCell.vue';
import TaskEventCell from '@/components/Tasks/Cells/TaskEventCell.vue';
import CrudSlideout from '../Slideout/CrudSlideout.vue';

type Props = {
  initialTask: {
    id?: number | null;
    uuid: string;
    title: string | null;
  };
  ownerModel?: TaskOwnerModels | null;
  ownerId?: number;
  lockedKeys?: string[];
};

const props = withDefaults(defineProps<Props>(), {
  ownerId: null,
  ownerModel: null,
  lockedKeys: () => [],
});
const emit = defineEmits<{
  (e: 'closed'): void;
  (e: 'created', value: TaskResource): void;
  (e: 'updated', value: TaskResource): void;
  (e: 'deleted', value: number): void;
}>();

const { isSmallScreen } = useSmallScreen();
const loading = ref(false);
const modalOpen = ref(true);
const newComment = ref(null);
const allTaskFields = ref([]);

const task = ref<TaskResource | null>(null);

const deleteTask = async () => {
  if (!props.initialTask.uuid) return;

  const certain = await useDeleteObjectModal().assertReadyToDeleteModal(
    'Delete Task',
    'Are you sure you want to delete this task? This cannot be undone.'
  );
  if (!certain) return;
  loading.value = true;
  await axios.delete<string>(`/api/tasks/${props.initialTask.uuid}`);
  emit('deleted', props.initialTask.id);
  modalOpen.value = false;
  useToast().info('Task Deleted');
};

const { taskFields: fields, fetchTaskFields } = useTaskFields(props.ownerModel, props.ownerId, false, true);

const completeTask = async () => {
  if (!task.value) return;
  task.value = await changeCompleteStatusOfTask(task.value);
  emitTask();
};

const updateTask = async (key: string, newValue: string | number) => {
  if (!key) return;
  if (!task.value) return;
  if (!task.value.uuid) return;
  if (task.value[key] === newValue) return;

  loading.value = true;

  const params = {
    [key]: newValue,
  };
  await axios.patch<string>(`/api/tasks/${props.initialTask.uuid}`, params);
  task.value[key] = newValue;
  emitTask();
};
const emitTask = () => {
  emit('updated', { ...props.initialTask, ...task.value, task_comments_count: task.value.task_comments.length });
};

const createComment = async (event) => {
  if (event && (event.ctrlKey || event.shiftKey)) {
    return;
  }
  loading.value = true;

  if (!newComment.value || !newComment.value.trim()) {
    return;
  }

  const { data } = await postTaskComment(task.value.uuid, { comment: newComment.value });
  task.value.task_comments.push(data);
  newComment.value = '';
  // emitTask();
  emit('updated', { id: task.value.id, uuid: task.value.uuid, task_comments_count: task.value.task_comments.length });
  currentMode.value = 'comments';
  setTimeout(() => {
    const element = document.getElementById(`task_comment_${data.id}`);
    if (!element) return;
    element.scrollIntoView({ behavior: 'instant', block: 'start', inline: 'start' });
  }, 50);
};

const fetchTask = async () => {
  if (!props.initialTask || !props.initialTask.uuid) return;
  loading.value = true;
  try {
    const { data } = await getTask(props.initialTask.uuid, props.ownerModel === 'User');

    task.value = data;
    if (task.value.deleted_at) {
      useToast().info('"' + task.value.title + '" has been deleted.');
      emit('closed');
      modalOpen.value = false;
      return;
    }

    loading.value = false;
  } catch (e) {
    console.log(e);
  }
};

const getAllTaskFields = () => {
  allTaskFields.value = fields.value;
  task.value?.task_task_field_pivots.forEach((pivot) => {
    if (!getItemFromArrayBasedOnId(pivot.task_field_id, allTaskFields.value) && pivot.task_field) {
      allTaskFields.value.push(pivot.task_field);
    }
  });
  task.value?.owner_task_fields.forEach((field) => {
    if (!getItemFromArrayBasedOnId(field.id, allTaskFields.value)) {
      allTaskFields.value.push(field);
    }
  });
  sortArrayBy(allTaskFields.value, 'order');
};
watch(
  () => fields,
  () => {
    getAllTaskFields();
  },
  { deep: true, immediate: true }
);

const fetchAll = async () => {
  await fetchTask();
  await fetchTaskFields();
  getAllTaskFields();
};
fetchAll();

useEmitStore().$subscribe((mutation, state) => {
  switch (state.item?.key) {
    case 'task-changed': {
      const localTask = state.item.payload;
      if (localTask) {
        task.value = updateItem(localTask, task.value);
      } else {
        fetchTask();
      }
      break;
    }
    case 'task-deleted': {
      modalOpen.value = false;
      emit('closed');
      break;
    }
    default:
      break;
  }
});

const filteredActiveUsers = computed(() => {
  if (!task.value) return [];
  let uniqueUsers = [];
  task.value.task_subscribers.forEach((user) => {
    if (!uniqueUsers.some((u) => u.id === user.id)) {
      uniqueUsers.push(user);
    }
  });
  return uniqueUsers;
});

const iAmConnectedUser = computed(() => {
  return task.value.task_subscribers.some((u) => u.id === usePage().props.auth.user.id);
});
const toggleMeAsUser = async () => {
  if (iAmConnectedUser.value) {
    await axios.delete<string>(`/api/tasks/${props.initialTask.uuid}/subscribers/${usePage().props.auth.user.id}`);
    const index = getIndexFromArrayBasedOnId(usePage().props.auth.user.id, task.value?.task_subscribers);
    if (index > -1) {
      task.value.task_subscribers.splice(index, 1);
    }
    useToast().info('Un-subscribed');
  } else {
    await axios.post<string>(`/api/tasks/${props.initialTask.uuid}/subscribers/${usePage().props.auth.user.id}`);
    task.value.task_subscribers.push({
      id: usePage().props.auth.user.id,
      name: usePage().props.auth.user.name,
      avatar_url: usePage().props.auth.user.avatar_url,
    });
    useToast().info('Subscribed');
  }
};

const currentMode = ref('comments');
const getClassForField = (field: TaskFieldResource) => {
  switch (field.component) {
    case 'field-list': {
      return '!pl-[13px]';
    }
    case 'field-toggle': {
      return '!pl-edge';
    }
    case 'field-number':
    case 'field-text':
    case 'field-time':
    case 'field-date': {
      return '!pl-edge-1/4';
    }
    default: {
      return '';
    }
  }
};

const userOpen = ref(false);
const toggleUserOpen = async () => {
  userOpen.value = false;
  await nextTick();
  userOpen.value = true;
};

const openPerformance = (event) => {
  if (!task.value.performance || !task.value.performance.url) {
    return;
  }
  openRoute(task.value.performance.url + '#tasks', event);
};
</script>

<template>
  <CrudSlideout
    v-model="modalOpen"
    :title="!!initialTask?.uuid ? 'Update Task' : 'Create Task'"
    small
    :update="!!initialTask?.uuid"
    :loading="loading"
    :footer-classes="'!min-h-[120px] h-auto '"
    :base-z-index="200"
    @closed="$emit('closed')">
    <template #titleSlot>
      <div class="p-edge flex justify-between border-b">
        <template v-if="task">
          <!--        <i-->
          <!--          :class="{ 'fa-regular fa-thin': !task.completed_at }"-->
          <!--          class="fa fa-fw fa-badge-check text-lg"></i>-->

          <VButton
            :icon="task.completed_at ? 'fa-check-circle text-success' : 'fa-check-circle fa-regular fa-thin'"
            size="sm"
            emphasized
            :class="task.completed_at ? 'ring-success' : ''"
            :title="task.completed_at ? 'Completed' : 'Mark Complete'"
            @click="completeTask" />
          <ActionButtonGroup
            :actions="[
              {
                icon: 'fa-bell ' + (iAmConnectedUser ? 'text-success' : 'fa-regular'),
                emphasized: true,
                hoverText: iAmConnectedUser
                  ? 'You are subscribed to updates to this task. Click to remove yourself.'
                  : 'Would you like subscribe to changes on this task?',
                action: () => {
                  toggleMeAsUser();
                },
              },
              {
                title: 'Delete',
                icon: 'fa-trash fa-regular text-warning',
                emphasized: true,
                action: () => {
                  deleteTask();
                },
              },
              isSmallScreen
                ? {
                    title: 'Close',
                    icon: 'fa-arrow-right fa-regular',
                    action: () => {
                      modalOpen = false;
                      $emit('closed');
                    },
                  }
                : null,
            ]" />
        </template>
        <template v-else>
          <div class="w-[140px] bg-content rounded border h-[28px] animate-pulse"></div>
        </template>
      </div>
      <div
        class="w-full h-[30px] border-t bg-content text-soft border-b flex gap-edge-1/2 items-center px-edge justify-between">
        <template v-if="task">
          <span
            v-if="task.public && task.event_id"
            class="font-headers text-sm">
            <i class="fa fa-fw fa-unlock text-xs" />
            Visible to all <span class="text">event members</span>
          </span>
          <span
            v-else-if="task.owner_type === 'App\\User'"
            class="font-headers text-sm">
            <i class="fa fa-fw fa-user-lock text-xs fa-regular" />
            Personal Task
          </span>
          <span
            v-else
            class="font-headers text-sm">
            <i class="fa fa-fw fa-lock text-xs fa-regular" />
            Internal Task
          </span>
        </template>
      </div>
    </template>
    <div
      v-if="!task"
      class="pt-edge">
      <div class="w-full bg-content h-[50px] animate-pulse"></div>
    </div>
    <div
      v-if="task"
      class="flex flex-col min-h-full overflow-x-hidden">
      <div
        class="flex flex-col [&>div]:px-edge [&>div]:grid gap-edge-1/4 [&_label]:mb-0 [&>div]:items-center [&>div]:grid-cols-[130px_auto] [&>div]:min-h-[33px] [&_.fa]:mr-edge-1/2">
        <div class="!px-[1px] pt-edge-1/2 pb-edge-1/4">
          <TextareaInput
            wrapper-class="col-span-2 [&_textarea]:!text-3xl [&_*]:!rounded-none"
            :model-value="task.title"
            is-hidden
            :min-height="30"
            placeholder="Add Task"
            @blur="updateTask('title', $event)" />
        </div>
        <div v-if="task.owner_type !== 'App\\User'">
          <InputLabel
            super-text
            icon="fa-group fa-regular"
            label="Group" />
          <TaskContextCell
            :task="task"
            :in-list="false"
            :locked-keys="lockedKeys"
            @change="$event ? toggleUserOpen() : null"
            @updated="[(task = $event), emitTask()]" />
        </div>
        <div v-if="task.owner_type !== 'App\\User'">
          <InputLabel
            super-text
            icon="fa-user fa-regular"
            label="Assignee" />
          <TaskUserCell
            :task="task"
            :locked-keys="lockedKeys"
            :open-dropdown="userOpen"
            :in-list="false"
            @updated="[(task = $event), emitTask()]" />
        </div>

        <div v-if="task.event">
          <InputLabel
            super-text
            icon="fa-folder fa-regular"
            label="Event" />

          <TaskEventCell
            pill-classes="max-w-[200px] text-sm"
            :owner-model="ownerModel"
            :task="task"
            :owner-id="ownerId"
            always-open-event-on-click
            @close="[(modalOpen = false), emit('closed')]" />
        </div>
        <div v-if="task.performance">
          <InputLabel
            super-text
            icon="fa-music fa-regular"
            label="Performance" />
          <TaskPerformanceCell
            class="pl-edge-1/2"
            :task="task"
            pill-classes="max-w-[200px] text-sm"
            :with-label="false"
            @close="[(modalOpen = false), emit('closed')]" />
        </div>

        <div>
          <InputLabel
            super-text
            icon="fa-calendar fa-regular"
            label="Due Date" />

          <TaskDateCell
            :task="task"
            :owner-model="ownerModel"
            :in-list="false"
            @updated="[(task = $event), emitTask()]" />
        </div>

        <div v-for="field in allTaskFields">
          <div class="w-[128px]">
            <InputLabel
              super-text
              :icon="
                getItemFromArrayBasedOnId(field.component, [...allAvailableFieldTypes], { icon: null }, 'component')
                  .icon + ' fa-regular'
              "
              :label="field.title" />
          </div>
          <TaskTaskFieldCell
            :task="task"
            :task-field="field"
            :class="getClassForField(field)"
            @updated="[(task = $event), emitTask()]" />
        </div>
        <div>
          <div class="col-span-2 flex flex-col gap-1 mt-edge-1/4">
            <InputLabel
              super-text
              label="Description" />
            <VRichText
              :model-value="task.description"
              is-hidden
              placeholder="Task Description"
              :min-height="30"
              @blur="updateTask('description', $event)" />
          </div>
        </div>
      </div>
      <TaskSlideoutCommentsAndActivity
        v-model:current-mode="currentMode"
        :task="task" />
    </div>
    <template #footer>
      <div class="flex flex-col gap-edge justify-between min-h-[100px] p-edge">
        <TextareaInput
          v-model="newComment"
          :min-height="50"
          class="max-h-[400px] overflow-y-auto"
          placeholder="Add a Comment" />
        <div class="flex justify-between">
          <div class="px-edge-1/4 flex gap-edge-1/4 items-center mt-edge-1/2">
            <span class="text-soft mr-edge-1/4 text-sm font-headers">Collaborators:</span>
            <img
              v-for="user in filteredActiveUsers"
              class="inline-block h-[25px] w-[25px] rounded-full border"
              :title="user.name"
              :src="user.avatar_url"
              :alt="user.name" />
          </div>
          <div class="py-edge-1/4">
            <VButton
              size="xs"
              :disabled="!newComment"
              type="primary"
              title="Comment"
              emphasized
              @click="createComment" />
          </div>
        </div>
      </div>
    </template>
  </CrudSlideout>
</template>

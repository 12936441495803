<script setup lang="ts">
import { ref } from 'vue';
import LocalStorageHelper from '@/components/Helpers/LocalStorageHelper.vue';
import ReportModal from '@/components/Modals/ReportModal.vue';
import SettingToggle from '@/components/Inputs/Components/SettingToggle.vue';
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';
import { changeAndFormatStamp } from '@/util/timeFunctions';
import { useToast } from 'vue-toastification';
import { downloadFile } from '@/helpers/downloadFileFunctions';
import { GuestListResource, GuestListSettingResource } from '@/types/festival';
import VMultiselect from '@/components/Inputs/VMultiselect.vue';

type Props = {
  downloading: boolean;
  hasAccreditations?: boolean | null;

  guestList?: GuestListResource | null;
  guestLists?: GuestListResource[] | null;
  startDate?: string | null;

  guestListSettings: GuestListSettingResource;
};

const props = withDefaults(defineProps<Props>(), {
  downloading: false,
  hasAccreditations: false,
  startDate: null,
  guestList: null,
  guestLists: () => [],
});

const emits = defineEmits(['update:downloading']);
const modalOpen = ref(true);
const asCsv = ref(true);
const date = ref(null);
const withEventNames = ref(false);
const use_pseudonyms = ref(true);
const withAccreditations = ref(false);
const selectedGuestListIds = ref([]);

if (props.guestLists) {
  selectedGuestListIds.value = props.guestLists.map((g) => g.id);
}

const downloadReport = async () => {
  if (props.downloading) return;
  emits('update:downloading', true);
  const response = await axios
    .post(`/api/guest-list-settings/${props.guestListSettings.id}/reports`, {
      asCsv: asCsv.value,
      start: date.value ? changeAndFormatStamp({ stamp: date.value, startOf: 'day', addMinutes: 10 * 60 }) : null,
      end: date.value ? changeAndFormatStamp({ stamp: date.value, startOf: 'day', addMinutes: 26 * 60 }) : null,
      with_event_names: withEventNames.value,
      use_pseudonyms: use_pseudonyms.value,
      with_accreditations: props.hasAccreditations ? withAccreditations.value : false,
      guest_list_ids: props.guestList ? [props.guestList.id] : selectedGuestListIds.value,
    })
    .catch((error) => {
      console.error(error);
      emits('update:downloading', false);
      useToast().warning('Something went wrong, please try again later');
    });
  if (response.status === 204) {
    useToast().warning(response.statusText);
    emits('update:downloading', false);
    return;
  }
  await downloadFile(response.data.url, response.data.name);
  emits('update:downloading', false);
  modalOpen.value = false;
};
</script>
<template>
  <ReportModal
    v-if="modalOpen"
    v-model:as-c-s-v="asCsv"
    small
    :with-button="false"
    title="Download Guest List Report"
    :working="downloading"
    :can-download="true"
    @download="downloadReport">
    <SettingToggle
      v-model="use_pseudonyms"
      label="Use Pseudonyms"
      title="Instead of getting the names of the performances, show their pseudonyms (if any) instead" />

    <SettingToggle
      v-model="withEventNames"
      label="Include names of Events"
      title="Include the names of events the performances that has guest lists are connected to" />

    <VMultiselect
      v-if="guestLists.length > 0"
      v-model="selectedGuestListIds"
      :options="
        guestLists.map((g, index) => {
          return {
            id: g.id,
            title: g.title ? g.title : 'Guest List #' + (index + 1),
          };
        })
      "
      label="Guest Lists to include"
      option-label="title" />

    <SettingToggle
      v-if="hasAccreditations"
      v-model="withAccreditations"
      label="Accreditations of Guests"
      title="Include the accreditations of each guest" />

    <SettingToggle
      v-if="startDate"
      :model-value="date !== null"
      label="Just One Day"
      title="Download for just one day?"
      @update:model-value="date = date === null ? startDate : null" />
    <transition name="slide-down">
      <div v-if="date !== null">
        <VDatepicker
          v-model="date"
          required
          :start-date="startDate" />
      </div>
    </transition>
  </ReportModal>
  <LocalStorageHelper
    :local-storage-string="'guest_list_report_modal_for_list_' + (guestList ? guestList.id : 'all')"
    :value="{
      asCsv: asCsv,
      withEventNames: withEventNames,
      use_pseudonyms: use_pseudonyms,
      withAccreditations: withAccreditations,
    }"
    @new-value="[
      $event.hasOwnProperty('asCsv') ? (asCsv = $event.asCsv) : null,
      $event.hasOwnProperty('withEventNames') ? (withEventNames = $event.withEventNames) : null,
      $event.hasOwnProperty('use_pseudonyms') ? (use_pseudonyms = $event.use_pseudonyms) : null,
      $event.hasOwnProperty('withAccreditations') ? (withAccreditations = $event.withAccreditations) : null,
    ]" />
</template>

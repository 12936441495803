import { UserResource } from '@/types/user';

export const getProfile = async () => axios.get<UserResource>('/api/profile');

export const updateCustomHome = async (customHomeId: number, customHomeType: string) => {
  const { data } = await axios.patch('/api/profile/custom-home', {
    custom_home_id: customHomeId,
    custom_home_type: customHomeType,
  });
  return data;
};

type ProfileData = {
  first_name: string;
  last_name: string;
  country_code: string | null;
  phone: string | null;
  job_title: string | null;
  dob: string | null;
  bio: string | null;
  address: string | null;
  city: string | null;
  country: string | null;
};

export const updateProfile = async (profile: ProfileData) => axios.patch<'Updated'>('/api/profile', profile);

export const updateEmail = async (email: string) => axios.patch<'Updated'>('/api/profile/email', { email });

export const deleteProfile = async () => {
  const { data } = await axios.post('/api/profile/ban');
  return data;
};

export const updatePassword = async (form) => {
  const { data } = await axios.patch('/api/profile/password', form);
  return data;
};

export const scramblePassword = async () => {
  const { data } = await axios.post('/api/profile/scramble-password');
  return data;
};

<script setup lang="ts">
import VSelect from '@/components/Inputs/VSelect.vue';
import { useTheme } from '@/util/changeTheme';
import CustomHomeInModal from '@/components/Navbar/ProfileSettingModal/CustomHomeInModal.vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/store/UserStore';
import { watch } from 'vue';
import CalendarLinkModal from '@/components/Modals/CalendarLinkModal.vue';
import { usePage } from '@inertiajs/vue3';

const { changeTheme, currentTheme } = useTheme();

const { themeTopLeft } = storeToRefs(useUserStore());
const { THEMES_TOP_LEFT } = useUserStore();

watch(currentTheme, (newTheme) => {
  if (newTheme === 'base') {
    themeTopLeft.value = THEMES_TOP_LEFT[0];
  }
});
</script>
<template>
  <div class="space-y-edge">
    <div class="border-b">
      <h3>Display</h3>
    </div>
    <div class="grid grid-cols-[300px_auto] items-center">
      <div class="flex-1">
        <h4>Home Page</h4>
        <span class="text-soft text-sm"> Set your default landing page. </span>
      </div>
      <div class="truncate">
        <CustomHomeInModal />
      </div>
    </div>
    <div class="grid grid-cols-[300px_auto] items-center">
      <div class="flex-1">
        <h4>Theme</h4>
        <span class="text-soft text-sm"> Customize how Crescat looks on your device </span>
      </div>
      <VSelect
        :options="[
          {
            id: 'base',
            name: 'Dark Mode',
            icon: 'fa-moon fa-regular',
          },
          {
            id: 'light',
            name: 'Light Mode',
            icon: 'fa-sun-bright',
          },
        ]"
        :model-value="currentTheme"
        @update:model-value="changeTheme($event)" />
    </div>
    <div
      v-if="currentTheme === 'light'"
      class="grid grid-cols-[300px_auto] items-center">
      <div class="flex-1">
        <h4>Sidebar & Topbar color</h4>
        <span class="text-soft text-sm"> Customize the color of the sidebar and topbar </span>
      </div>
      <VSelect
        v-model="themeTopLeft"
        :options="[
          {
            id: THEMES_TOP_LEFT[0],
            name: 'Blue',
            icon: 'fa-moon fa-regular',
          },
          {
            id: THEMES_TOP_LEFT[1],
            name: 'Gray',
            icon: 'fa-sun-bright',
          },
        ]" />
    </div>

    <div class="border-b pt-edge-2x">
      <h3>Integrations</h3>
    </div>

    <div>
      <CalendarLinkModal
        :model-id="usePage().props.auth.user.id"
        :is-admin-modal="false"
        :model-type="'App\\User'" />
    </div>
  </div>
</template>

import { useLocalStorage } from '@vueuse/core';
import { ref } from 'vue';
import { useUserStore } from '@/store/UserStore';
import { storeToRefs } from 'pinia';
import { usePage } from '@inertiajs/vue3';

export const useTheme = (store: boolean = false, storageString: string = 'theme') => {
  const html = document.documentElement;
  const currentThemeData = html.getAttribute('data-theme') as 'light' | 'base';
  const THEMES = ['light', 'base'] as const;

  const currentTheme = store
    ? useLocalStorage<'light' | 'base'>(storageString, currentThemeData || 'base')
    : ref<'light' | 'base'>(currentThemeData || 'base');

  html.setAttribute('data-theme', currentTheme.value);

  const changeTheme = (theme = null) => {
    const newTheme = theme ? theme : currentTheme.value === 'base' ? 'light' : 'base';
    html.setAttribute('data-theme', newTheme);
    currentTheme.value = newTheme;
    const userStore = useUserStore();
    userStore.theme = newTheme;
    if (newTheme === 'base' && usePage().props?.auth?.user?.id) {
      const { themeTopLeft } = storeToRefs(useUserStore());
      const { THEMES_TOP_LEFT } = useUserStore();
      themeTopLeft.value = THEMES_TOP_LEFT[0];
    }
  };

  return {
    currentTheme,
    themes: THEMES,
    changeTheme,
  };
};

<script lang="ts" setup>
import { DocumentModelType, DocumentResource } from '@/types/document';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import { uploadFile } from '@/util/file-upload';
import VButton from '@/components/Inputs/VButton.vue';

type Props = {
  buttonText?: string;
  folderId?: number | null;
  withIcon?: boolean;
  canEdit?: boolean;
  uploading?: boolean;
  workingOutside?: boolean;
  model?: DocumentModelType | null;
  modelId?: number | null;
  maxMBSize?: number;
  multiple?: boolean;
  openOnCreated?: boolean;
  emphasized?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  buttonText: 'Upload file',
  folderId: null,
  withIcon: true,
  canEdit: false,
  uploading: false,
  model: null,
  modelId: null,
  maxMBSize: 100,
  multiple: false,
  workingOutside: false,
  openOnCreated: false,
  emphasized: true,
});

const emit = defineEmits<{
  (event: 'documentUploaded', arg: DocumentResource | UploadCareResponse): void;
  (event: 'update:uploading', arg: boolean): void;
}>();

const toast = useToast();

const loading = ref(false);

const openDialog = async () => {
  loading.value = true;
  emit('update:uploading', true);

  const files = await uploadFile({
    modelType: props.model,
    modelId: props.modelId,
    folderId: props.folderId,
    maxUploadSize: props.maxMBSize,
    multiple: props.multiple,
  });

  if (!files) {
    emit('update:uploading', false);
    loading.value = false;
    return;
  }
  emit('documentUploaded', files);
  toast.success('Document Uploaded');
  loading.value = false;

  emit('update:uploading', false);
};
if (props.openOnCreated) {
  openDialog();
}
</script>

<template>
  <VButton
    title="Upload file"
    type="primary"
    icon="fa-upload fa-regular"
    :disabled="!canEdit || workingOutside"
    :is-block="true"
    :emphasized="emphasized"
    :loading="loading || workingOutside"
    @click="openDialog" />
</template>

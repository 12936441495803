<script lang="ts" setup>
import { TaskFieldResource, TaskResource } from '@/types/tasks';
import VButton from '@/components/Inputs/VButton.vue';
import { changeCompleteStatusOfTask, patchTask } from '@/services/api-tasks';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import { getRoute, openRoute } from '@/util/route';
import { computed, nextTick, ref } from 'vue';
import { formatAppModel } from '@/util/format-app-model';
import TaskUserCell from '@/components/Tasks/TaskUserCell.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import { getItemFromArrayBasedOnId } from '@/util/globals';
import TaskTaskFieldCell from '@/components/Tasks/TaskTaskFieldCell.vue';
import TaskDateCell from '@/components/Tasks/TaskDateCell.vue';
import TaskPrivatePublicCell from '@/components/Tasks/TaskPrivatePublicCell.vue';
import TaskContextCell from '@/components/Tasks/TaskContextCell.vue';
import { useSmallScreen } from '@/composables/use-small-screen';
import TaskPerformanceCell from '@/components/Tasks/Cells/TaskPerformanceCell.vue';
import TaskEventCell from '@/components/Tasks/Cells/TaskEventCell.vue';
import TaskDescriptionCell from '@/components/Tasks/TaskDescriptionCell.vue';

const props = withDefaults(defineProps<Props>(), {
  columnsToShow: () => [],
  hideColumns: () => [],
  lockedKeys: () => [],
  displayTaskFields: () => [],
  taskFields: () => [],
  eventId: null,
  inviteId: null,
});

const emit = defineEmits<{
  (e: 'open', value: TaskResource): void;
  (e: 'addOrUpdateTask', value: TaskResource): void;
  (e: 'completed'): void;
}>();

type Props = {
  ownerModel: 'Group' | 'User';
  ownerId: number;
  eventId?: number;
  inviteId?: number;
  task: TaskResource;
  columnsToShow?: string[];
  lockedKeys?: string[];
  hideColumns?: string[];
  displayTaskFields?: number[];
  taskFields?: TaskFieldResource[];
};
const { isSmallScreen } = useSmallScreen();

const completeTask = async (task) => {
  const newTask = await changeCompleteStatusOfTask(task);
  emit('completed');
  emit('addOrUpdateTask', newTask);
};
const updateTaskTitle = async (task: TaskResource, newTitle: string) => {
  if (task.title === newTitle) return;
  task.title = newTitle;
  await patchTask(task.uuid, {
    title: task.title,
  });
  emit('addOrUpdateTask', task);
};

const openEvent = async () => {
  if (props.task.invite_id) {
    await openInviteById(props.task.invite_id);
    return;
  }
  if (['Group', 'Festival'].includes(props.ownerModel) && props.task.event_id) {
    const { data } = await axios.get<{ id: number }>(`/api/invites/`, {
      params: {
        event_id: props.task.event_id,
        model_type: formatAppModel(props.ownerModel),
        model_id: props.ownerId,
      },
    });
    if (data && data.id) {
      await openInviteById(data.id);
      return;
    }
  }
  if (props.task.event) {
    openRoute(getRoute('events.show', props.task.event.slug));
  }
};

// const openPerformance = async () => {
//   if (!props.task.performance || !props.task.performance.url) {
//     return;
//   }
//   openRoute(props.task.performance.url + '#tasks');
// };
const selectedInviteId = ref(null);
const openInviteById = async (inviteId: number) => {
  selectedInviteId.value = null;
  await nextTick();
  selectedInviteId.value = inviteId;
};

const userOpen = ref(false);
const toggleUserOpen = async () => {
  userOpen.value = false;
  await nextTick();
  userOpen.value = true;
};
const taskCommentCount = computed(() => {
  let count = 0;
  if (props.task.task_comments_count && props.task.task_comments_count > 0) {
    count = props.task.task_comments_count;
  }
  if (props.task.task_comments && props.task.task_comments > 0) {
    if (props.task.task_comments.length > count) {
      count = props.task.task_comments.length;
    }
  }
  return count;
});
</script>

<template>
  <VTableRow classes="group/item">
    <VTableCell
      class="!pl-edge !pr-edge-1/4 bg"
      :has-input="true"
      :main-cell="task.completed_at === null"
      @click.self="emit('open', task)">
      <div
        :style="
          '    grid-template-columns: 20px auto ' +
          (task.task_comments_count && task.task_comments_count > 0 ? '20px' : '') +
          ' 30px;'
        "
        class="grid gap-edge-1/4 items-center">
        <div
          class="text cursor-pointer"
          @click="completeTask(task)">
          <i
            :class="{
              'hover:font-bold': !task.completed_at,
              'fa-regular fa-thin': !task.completed_at,
              'text-success': task.completed_at,
            }"
            class="fa fa-fw fa-check-circle text-lg" />
        </div>

        <TextInput
          v-if="!isSmallScreen"
          wrapper-class=" [&_input]:px-edge-1/2 [&_input]:py-edge-1/4"
          :square="true"
          :model-value="task.title"
          is-hidden
          @blur="updateTaskTitle(task, $event)" />

        <div
          v-if="isSmallScreen"
          class="px-edge-1/2 py-edge-1/4"
          @click.self="emit('open', task)">
          {{ task.title }}
        </div>

        <div
          v-if="taskCommentCount > 0"
          :title="'Show ' + taskCommentCount + ' comments'"
          class="relative inline-block text-center"
          @click="emit('open', task)">
          <div class="absolute w-[22px] h-[17px] flex items-center justify-center">
            <span class="text-xxs">
              {{ taskCommentCount }}
            </span>
          </div>
          <i class="fa fa-fw fa-message fa-regular fa-lg fa-thin"></i>
        </div>
        <VButton
          type="default"
          size="sm"
          class="py-1 !px-edge-1/4 min-w-[25px] rounded"
          icon="fa-chevron-right"
          @click="emit('open', task)" />
      </div>
    </VTableCell>
    <VTableCell v-if="columnsToShow.includes('private_status') && !isSmallScreen">
      <TaskPrivatePublicCell
        :locked-keys="lockedKeys"
        :task="task"
        :owner-model="ownerModel"
        :owner-id="ownerId"
        :event-id="eventId"
        :invite-id="inviteId"
        @updated="[emit('addOrUpdateTask', $event)]" />
    </VTableCell>

    <VTableCell
      v-if="!hideColumns.includes('Due Date') && !isSmallScreen"
      class="!py-0 !px-edge-1/4">
      <TaskDateCell
        :task="task"
        :owner-model="ownerModel"
        @updated="emit('addOrUpdateTask', $event)" />
    </VTableCell>

    <VTableCell
      v-if="!hideColumns.includes('Description') && !isSmallScreen"
      class="!p-0">
      <TaskDescriptionCell
        :task="task"
        @updated="emit('addOrUpdateTask', $event)" />
    </VTableCell>

    <VTableCell
      v-if="columnsToShow.includes('own_groups') && !hideColumns.includes('Group') && !isSmallScreen"></VTableCell>
    <VTableCell
      v-if="columnsToShow.includes('group_selector') && !hideColumns.includes('Group') && !isSmallScreen"
      class="!py-0 !px-edge-1/4">
      <TaskContextCell
        :locked-keys="lockedKeys"
        :task="task"
        @change="$event ? toggleUserOpen() : null"
        @updated="[emit('addOrUpdateTask', $event)]" />
    </VTableCell>
    <VTableCell
      v-if="columnsToShow.includes('user_selector') && !hideColumns.includes('User') && !isSmallScreen"
      class="!py-0 !px-edge-1/4">
      <TaskUserCell
        :locked-keys="lockedKeys"
        :task="task"
        :open-dropdown="userOpen"
        @updated="emit('addOrUpdateTask', $event)" />
    </VTableCell>

    <VTableCell v-if="columnsToShow.includes('events') && !hideColumns.includes('Event') && !isSmallScreen">
      <TaskEventCell
        :owner-model="ownerModel"
        pill-classes="max-w-[140px] text-xs"
        :task="task"
        :owner-id="ownerId" />
    </VTableCell>
    <VTableCell v-if="columnsToShow.includes('performance') && !hideColumns.includes('Performance') && !isSmallScreen">
      <div class="flex px-edge-1/4">
        <TaskPerformanceCell
          :task="task"
          pill-classes="max-w-[140px] text-xs"
          :with-label="false" />
      </div>
    </VTableCell>

    <VTableCell
      v-for="displayablefield in displayTaskFields
        .map((f) => getItemFromArrayBasedOnId(f, taskFields))
        .filter((field) => field !== null)"
      v-if="!isSmallScreen">
      <TaskTaskFieldCell
        :task="task"
        in-list
        :task-field="displayablefield"
        @updated="emit('addOrUpdateTask', $event)" />
    </VTableCell>

    <!--    <VTableCell-->
    <!--      class="bg !pl-edge-1/4"-->
    <!--      @click="emit('open', task)">-->
    <!--     -->
    <!--    </VTableCell>-->
  </VTableRow>
</template>

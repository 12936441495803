import type { ModelType } from '@/types/global';
import { PartnerCompanyResource, PartnerContactResource, PartnerFieldResource } from '@/types/partners';
import { useToast } from 'vue-toastification';
import { arrayToJoinString, getKey } from '@/util/globals';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { useMultipleButtonsModal } from '@/composables/modals/use-multiple-buttons-modal';
import { getRoute } from '@/util/route';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';

export const getPartnerFields = async (model: ModelType, modelId: number, type: string) => {
  const url = '/api/partners/fields';
  const params = {
    model_type: `App\\${model}`,
    model_id: modelId,
    type,
  };

  return axios.get<PartnerFieldResource[]>(url, { params });
};

export type PartnerType = {
  id: number;
  title: string;
};

export const getPartnerTypes = async (model: ModelType, modelId: number) => {
  const url = '/api/partners/types';
  const params = {
    model_type: `App\\${model}`,
    model_id: modelId,
  };
  return axios.get<PartnerType[]>(url, { params });
};

type Contact = {
  first_name: string;
  last_name: string;
  phone: string | null;
  email: string | null;
  country_code: string | null;
};

export const createPartnerContact = async (model: ModelType, modelId: number, contact: Contact) => {
  return axios.post<PartnerContactResource>('/api/partners/contacts', {
    first_name: contact.first_name,
    last_name: contact.last_name,
    phone: contact.phone,
    country_code: contact.country_code,
    email: contact.email,
    model_type: `App\\${model}`,
    model_id: modelId,
  });
};

type Company = {
  name: string;
  partner_type_id: number | null;
  phone: string | null;
  email: string | null;
};

export const createPartnerCompany = async (model: ModelType, modelId: number, company: Company) => {
  return axios.post<PartnerCompanyResource>('/api/partners/companies', {
    model_type: `App\\${model}`,
    model_id: modelId,
    ...company,
  });
};

export const addContactToCompany = async (
  model: ModelType,
  modelId: number,
  companyId: number,
  contactId: number,
  role: string,
  isPrimary: boolean
) => {
  return axios.post<string>(`/api/partners/companies/${companyId}/contacts/${contactId}`, {
    model_type: `App\\${model}`,
    model_id: modelId,
    role,
    is_primary: isPrimary,
  });
};

export const removeContactFromCompany = async ({
  companyId,
  contactId,
  model,
  modelId,
}: {
  companyId: number;
  contactId: number;
  model: string;
  modelId: number;
}) => {
  if (!companyId || !contactId) throw Error('Missing company or contact id');
  return axios.delete(`/api/partners/companies/${companyId}/contacts/${contactId}`, {
    params: {
      model_type: `App\\${model}`,
      model_id: modelId,
    },
  });
};
export const patchPartnerValue = async (
  model: string,
  modelId: number,
  fieldId: number,
  value: unknown,
  companyId: number | null,
  contactId?: number
) => {
  const url = companyId
    ? `/api/partners/companies/${companyId}/partner-fields/${fieldId}`
    : `/api/partners/contacts/${contactId}/partner-fields/${fieldId}`;
  return axios.patch(url, {
    value,
    model_type: `App\\${model}`,
    model_id: modelId,
  });
};

export const patchPartnerContact = async (
  id: number,
  firstName: string,
  lastName: string,
  description: string | null,
  phone: number,
  countryCode: number,
  email: string | null,
  userId: number | null,
  partnerTypeId: number | null,
  withToast = true
) => {
  if (!id) return null;
  await axios.patch(`/api/partners/contacts/${id}`, {
    first_name: firstName,
    last_name: lastName,
    description,
    phone,
    country_code: countryCode,
    email,
    user_id: userId,
    partner_type_id: partnerTypeId,
  });
  if (withToast) {
    useToast().success('Updated');
  }
};

export const patchPartnerCompany = async ({
  id,
  name,
  phone,
  countryCode,
  email,
  groupId,
  website,
  partnerTypeId,
  address,
  postalCode,
  country,
  description,
  city,
  withToast = true,
}) => {
  if (!id) return null;
  await axios.patch(`/api/partners/companies/${id}`, {
    name,
    phone,
    country_code: countryCode,
    email,
    website,
    partner_type_id: partnerTypeId,
    group_id: groupId,
    address,
    postal_code: postalCode,
    country,
    description,
    city,
  });
  if (withToast) {
    useToast().success('Updated');
  }
};

const sendToEmail = async (partner) => {
  if (!partner.email) return;
  const { data } = await axios.post(`/api/partners/contacts/${partner.id}/request-more-info`);
  useToast().success('Sent');
  return data.uuid;
};

const removeLink = async (partner) => {
  if (!partner.email) return;
  await axios.delete(`/api/partners/contacts/${partner.id}/request-more-info`);
  useToast().success('Removed');
  return null;
};

export const sendOrResendInfo = async ({ partner }) => {
  if (!partner || !partner.email) return;
  if (!partner.uuid) {
    const certain = await useCertaintyModal().assertCertain(
      'Request Info',
      `This will send out an email with a link and instructions to revise and fill out this contact's profile details. Would you like to have one sent to ${partner.first_name} ${partner.last_name} now?`
    );
    if (!certain) throw Error('Cancel');
    return await sendToEmail(partner);
  }

  const result = await useMultipleButtonsModal().threeButtonModal({
    title: 'Info Request already exists',
    description: `Request already exists. The url is ${getRoute('partnerContact.requests.show', partner.uuid)}.
           Would you like to resend the link, or remove the link all together?`,
    button: {
      text: 'Confirm',
      type: 'primary',
    },
    options: [
      {
        value: 'first',
        label: 'Remove',
      },
      {
        value: 'second',
        label: 'Resend',
      },
    ],
  });

  switch (result) {
    case 'first': {
      return removeLink(partner);
    }
    case 'second': {
      return sendToEmail(partner);
    }
    case 'cancel':
    default: {
      throw Error('Cancel');
    }
  }
};

export const concatName = (contact) => {
  if (!contact) return '';
  if (getKey(contact, 'name') !== null) return contact.name;
  return arrayToJoinString(
    [getKey(contact, 'first_name'), getKey(contact, 'last_name')].filter((n) => n !== null),
    ' ',
    ' '
  );
};

export const deleteContact = async ({ contact, withToast = true }) => {
  const deleteIt = await useDeleteObjectModal().assertReadyToDeleteModal(
    `Delete ${concatName(contact)}`,
    `Are you sure you want to delete ${concatName(contact)}? This is permanent.`
  );
  if (!deleteIt) return false;
  await axios.delete(`/api/partners/contacts/${contact.id}`);

  if (withToast) {
    useToast().success(`${concatName(contact)} Deleted.`);
  }
  return true;
};

export const deleteCompany = async ({ company, withToast = true }) => {
  const deleteIt = await useDeleteObjectModal().assertReadyToDeleteModal(
    `Delete ${company.name}`,
    `Are you sure you want to delete ${company.name}? This is permanent.`
  );
  if (!deleteIt) return false;
  await axios.delete(`/api/partners/companies/${company.id}`);

  if (withToast) {
    useToast().success(`${company.name} Deleted.`);
  }
  return true;
};

export const postPartnerType = async (model: ModelType, modelId: number, type: string, title: string) => {
  return axios.post<PartnerType>('/api/partners/types', {
    model_type: `App\\${model}`,
    model_id: modelId,
    title,
    type,
  });
};

export const patchPartnerType = async (id: number, title: string) => {
  return axios.patch<string>(`/api/partners/types/${id}`, {
    title,
  });
};

export const destroyPartnerType = async (id: number) => {
  return axios.delete<string>(`/api/partners/types/${id}`);
};

export const getPartnerContact = async (id: number) => {
  return axios.get<PartnerContactResource>(`/api/partners/contacts/${id}`);
};

export const getPartnerCompany = async (id: number) => {
  return axios.get<PartnerCompanyResource>(`/api/partners/companies/${id}`);
};

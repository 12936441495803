<script setup lang="ts">
import GridTemplate from '@/components/GridTemplate.vue';
import { patchFormSection } from '@/services/api-form';
import { copyObject } from '@/util/object-helpers';
import { z } from 'zod';
import { EventResource } from '@/types/event';
import { FormModelType, FormResource, FormSectionResource } from '@/types/form';
import { useToast } from 'vue-toastification';
import { createUuId, getIndexFromArrayBasedOnId } from '@/util/globals';
import CrudModal from '@/components/Modals/CrudModal.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import FormSection from '@/components/Models/Forms/FormSection.vue';
import SaveAsTemplateModal from '@/components/Modals/SaveAsTemplateModal.vue';
import { downloadFile } from '@/helpers/downloadFileFunctions';
import ContentContainer from '@/components/Content/ContentContainer.vue';
import { computed, inject, onMounted, ref, watch } from 'vue';
import { copyToClipboard } from '@/util/copy';
import { getRoute, openRoute } from '@/util/route';
import { useSmallScreen } from '@/composables/use-small-screen';
import { eventTypesKey } from '@/provide/keys';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import ActionButtonGroup from '@/components/Inputs/Components/ActionButtonGroup.vue';
import SectionConnectedToEventTypeSubHeader from '@/components/Config/EventTypes/SectionConnectedToEventTypeSubHeader.vue';

type Props = {
  form: FormResource;
  isDisplay: boolean;
  model: FormModelType;
  modelId: number;
  event?: EventResource;
  isOwner: boolean;
  canEdit: boolean;
  isTemplate: boolean;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (event: 'edit'): void;
  (event: 'updated'): void;
}>();

const { isSmallScreen } = useSmallScreen();

const { assertReadyToDeleteModal } = useDeleteObjectModal();
const toast = useToast();

const loading = ref(false);

const downloadingForm = ref(false);

const downloadForm = async () => {
  if (downloadingForm.value) return;
  downloadingForm.value = true;
  const { data } = await axios.get(`/api/forms/${props.form.id}/report`).catch((error) => {
    console.error(error);
    downloadingForm.value = false;
    toast.warning('Something went wrong, please try again later');
  });
  await downloadFile(data.url, data.name);
  downloadingForm.value = false;
};

const sections = ref(
  copyObject(props.form.sections.map((section, index) => ({ ...section, order: (index + 1) * 10000 })))
);

watch(
  () => props.form,
  () => {
    sections.value = copyObject(
      props.form.sections.map((section, index) => ({ ...section, order: (index + 1) * 10000 }))
    );
  },
  { deep: true }
);

const showSaveAsTemplateModal = ref(false);

const createSectionModalOpen = ref(false);

const sectionSchema = z.object({
  title: z.string().min(2).max(255),
  text: z.string(),
  id: z.string().nullable(),
});

type Section = z.infer<typeof sectionSchema>;

const selectedSection = ref<Section>({
  title: '',
  text: '',
  id: null,
});

const canSaveSection = computed(() => {
  return sectionSchema.safeParse(selectedSection.value).success;
});

const openSectionModal = (section?: FormSectionResource) => {
  selectedSection.value = {
    id: section?.id ?? null,
    title: section?.title ?? '',
    text: section?.text ?? '',
  };
  createSectionModalOpen.value = true;
};

const updateSection = async (close: () => void) => {
  if (!canSaveSection.value || !selectedSection.value.id) return;
  loading.value = true;

  const index = getIndexFromArrayBasedOnId(selectedSection.value.id, sections.value);
  sections.value[index].title = selectedSection.value.title;
  sections.value[index].text = selectedSection.value.text;

  await patchFormSection(props.form.id, sections.value);
  toast.success('Section Updated');

  close();
  loading.value = false;
};

const removeSection = async (close: () => void) => {
  if (!props.canEdit || !editMode.value) return;

  loading.value = true;

  const deleteIt = await assertReadyToDeleteModal(
    'Delete Section',
    `Are you sure that you want to delete ${selectedSection.value.title}?`
  );
  if (!deleteIt) return;

  const newSections = sections.value.filter((s) => s.id !== selectedSection.value.id);

  try {
    await patchFormSection(props.form.id, newSections);
    sections.value = newSections;
    toast.success('Section Deleted');
  } catch (e) {
    toast.error('Something went wrong. Please try again later.');
    throw e;
  }
  close();
  loading.value = false;
};

const editMode = ref(false);
const moveSection = ref(false);

const actions = computed(() => {
  const array = [];
  if (isSmallScreen.value) return array;
  if (!props.isTemplate && !editMode.value && !props.isDisplay && props.event) {
    array.push({
      title: 'Open',
      icon: 'fa-external-link',
      action: () => {
        openRoute(getRoute('form.show', props.form.slug), null, true);
      },
    });
    array.push({
      title: 'Copy Link',
      icon: 'fa-copy',
      action: () => {
        copyToClipboard(getRoute('form.show', props.form.slug));
      },
    });
  }
  if (editMode.value && props.canEdit) {
    array.push({
      title: 'Add Section',
      icon: 'fa-plus',
      action: () => {
        openSectionModal();
      },
    });
    if (sections.value.length > 1) {
      array.push({
        title: 'Move Section',
        icon: 'fa-arrows-alt',
        action: () => {
          moveSection.value = !moveSection.value;
        },
      });
    }
  }
  // if (props.canEdit) {
  //   array.push({
  //     title: editMode.value ? 'Done' : 'Edit Form',
  //     icon: editMode.value ? 'fa-circle-check' : 'fa-pen',
  //     action: () => {
  //       editMode.value = !editMode.value;
  //       moveSection.value = false;
  //     },
  //   });
  // }
  const dropdown = [
    {
      title: 'Save as Template',
      action: () => {
        showSaveAsTemplateModal.value = true;
      },
    },
    {
      title: 'Download',
      action: () => {
        downloadForm();
      },
    },
  ];
  if (!props.isTemplate && !props.canEdit) {
    array.push({
      icon: 'fa-chevron-down',
      dropdown: dropdown,
    });
  } else {
    array.push({
      icon: 'fa-pencil fa-regular',
      title: 'Edit',
      action: () => {
        emit('edit');
      },
      buttonDropdown: dropdown,
    });
  }
  return array;
});

const createSection = async (close: () => void) => {
  if (!canSaveSection.value) return;
  loading.value = true;
  const newSection = {
    title: selectedSection.value.title,
    text: selectedSection.value.text,
    id: createUuId('section_'),
    fields: [],
  };
  await patchFormSection(props.form.id, [...sections.value, newSection]);
  const order = Math.max(...sections.value.map((s) => Number(s.order)).concat(0));
  sections.value.push({ ...newSection, order: order + 10000 });
  toast.success('Section Created.');
  close();
  loading.value = false;
};

const onSectionChange = async (index: number, data: { section: FormSectionResource; action: string }) => {
  loading.value = true;
  const newSections = sections.value.map((s, i) => {
    if (i === index) {
      return data.section;
    }
    return s;
  });
  try {
    await patchFormSection(props.form.id, newSections);
    sections.value = newSections;
    toast.success('Section ' + data.action);
  } catch (e) {
    toast.error('Something went wrong. Please try again later.');
    throw e;
  }
  loading.value = false;
};

const listenForBroadcast = () => {
  if (!props.model || !props.modelId || props.model === 'GlobalModel' || props.model === 'Invite') return;

  Echo.join(`On.${props.model}.${props.modelId}`).listen(`.form.${props.form.id}.updated`, () => {
    emit('updated');
  });
};

onMounted(() => {
  listenForBroadcast();
});

const onOrderChange = async (data: FormSectionResource[]) => {
  try {
    sections.value = data;
    await patchFormSection(props.form.id, data);
  } catch (e) {
    console.error(e);
  }
};
const open = ref(false);

const { eventTypes, fetch: fetchEventTypes } = inject(eventTypesKey, {
  eventTypes: computed(() => []),
  fetch: (force?: boolean = false) => {},
});

fetchEventTypes();

const connectetedEventTypes = computed(() => {
  if (!props.form) return [];

  return eventTypes.value.filter((ev) => ev.pivot.forms.some((cl) => cl.id === props.form.id));
});

const concatAllEvenTypeNames = computed(() => {
  if (!connectetedEventTypes.value) return '';

  return connectetedEventTypes.value.map((ev) => ev.name).join(', ');
});
</script>

<template>
  <ContentContainer
    :edit-mode="false"
    :title="form.title"
    :just-content-without-header="isDisplay"
    :actions="actions"
    :z-index="99"
    :loading="loading || downloadingForm"
    pre-icon="fa-file fa-regular"
    content-class="p-edge"
    :post-icon="form.is_locked && !isTemplate ? ' fa-lock fa-regular ' : null"
    @open="[(editMode = false), (open = $event)]"
    @edit="$emit('edit')">
    <template #underHeader>
      <div v-if="open && isTemplate">
        <SectionConnectedToEventTypeSubHeader
          :model="model"
          :model-id="modelId"
          pre-icon="fa-table"
          :title-of-item="form.title"
          :id-of-item="form.id"
          type-of-item="Form" />
      </div>
    </template>
    <template #content>
      <component :is="isDisplay ? 'div' : BoxContainer">
        <div
          v-if="canEdit"
          class="flex justify-end mb-edge">
          <ActionButtonGroup
            :actions="[
              {
                title: editMode ? 'Done' : 'Edit Form',
                icon: editMode ? 'fa-circle-check' : 'fa-pen',
                emphasized: true,
                action: () => {
                  editMode = !editMode;
                  moveSection = false;
                },
              },
            ]"></ActionButtonGroup>
        </div>
        <div
          v-if="form.text"
          class="pb-edge px-edge text-soft">
          {{ form.text }}
        </div>
        <GridTemplate
          v-if="form"
          v-slot="{ item: section, index }"
          :can-drag="moveSection"
          drag-handle=".fa-bars"
          class="space-y-edge"
          gap="0"
          :show-border="moveSection"
          :model-value="sections"
          @update:model-value="onOrderChange">
          <FormSection
            :section="section"
            :is-template="isTemplate"
            :can-edit="canEdit"
            :edit-mode="editMode"
            :model="model"
            :model-id="modelId"
            :moving-section="moveSection"
            @edit="openSectionModal(section)"
            @update:section="onSectionChange(index, $event)" />
        </GridTemplate>
      </component>
    </template>
  </ContentContainer>

  <CrudModal
    v-if="createSectionModalOpen"
    :update="selectedSection.id !== null"
    :title="`${selectedSection.id ? 'Edit' : 'Create'} section`"
    :disabled="!canSaveSection"
    small
    @closed="createSectionModalOpen = false"
    @create="createSection"
    @delete="removeSection"
    @update="updateSection">
    <div class="form-layout">
      <TextInput
        v-model="selectedSection.title"
        label="Title"
        placeholder="Section Title" />
      <TextareaInput
        v-model="selectedSection.text"
        label="Text"
        placeholder="Section Text" />
    </div>
  </CrudModal>

  <SaveAsTemplateModal
    v-if="showSaveAsTemplateModal"
    :init-title="form.title"
    :modal-title="'Add ' + form.title + ' as a template'"
    url="/api/forms"
    :params="{
      model_type: 'App\\Group',
      parent_id: form.id,
    }"
    @closed="showSaveAsTemplateModal = false" />
</template>

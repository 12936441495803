<script setup lang="ts">
import { useToast } from 'vue-toastification';
import ReportModal from '@/components/Modals/ReportModal.vue';
import VMultiselect from '@/components/Inputs/VMultiselect.vue';
import { downloadFile } from '@/helpers/downloadFileFunctions.ts';
import { ref } from 'vue';

const { checkLists } = defineProps<{
  checkLists: any;
}>();

const toast = useToast();

const downloadableCheckLists = ref([]);
const working = ref(false);
const modalOpen = ref(false);

const closeDownloadCheckListReportModal = () => {
  modalOpen.value = false;
};

const downloadReport = async () => {
  if (working.value) return;
  working.value = true;

  try {
    const { data } = await axios.post('/api/check-lists/event-report', {
      downloadableCheckLists: downloadableCheckLists.value,
    });
    await downloadFile(data.url, data.name);
    working.value = false;
    closeDownloadCheckListReportModal();
  } catch (error) {
    console.error(error);
    working.value = false;
    toast.warning('Something went wrong, please try again later');
  }
};
</script>

<template>
  <ReportModal
    :with-button="false"
    :can-download="true"
    title="Download Check List Report"
    :working="working"
    @download="downloadReport">
    <VMultiselect
      v-model="downloadableCheckLists"
      :close-on-select="false"
      option-label="title"
      label-key="id"
      :options="checkLists"
      label="Check Lists to include" />
  </ReportModal>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification';
import ButtonGroup from '@/components/Inputs/Components/ButtonGroup.vue';
import VButton from '@/components/Inputs/VButton.vue';

type Props = {
  link: string;
  title?: string | null;
  buttonClasses?: string | null;
  buttonSize?: string | null;
  subject?: string | null;
  body?: string | null;
  onlyButton?: boolean | null;
  openButton?: boolean | null;
};

const props = withDefaults(defineProps<Props>(), {
  title: null,
  buttonClasses: null,
  buttonSize: 'btn-xs',
  subject: null,
  body: null,
  onlyButton: false,
  openButton: false,
});

const clicked = () => {
  const toast = useToast();

  navigator.clipboard.writeText(props.link);

  toast.success(`The link of '${props.title}' has been copied to your clipboard.`);
};
const openLink = () => {
  window.open(props.link, '_blank');
};
const sendAsEmail = () => {
  const subject = props.subject.split(' ').join('%20');
  const body = props.body.split(' ').join('%20');
  const href = `mailto:?subject=${subject}&body=${body}`;
  const link = document.createElement('a');
  link.href = href;
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    link.parentNode.removeChild(link);
  }, 1000 * 10);
};
</script>
<template>
  <ButtonGroup v-if="link">
    <VButton
      :class="buttonClasses + ' ' + buttonSize"
      tool-tip-text="Copy the link to your clipboard"
      :title="onlyButton ? 'Copy' : null"
      emphasized
      icon="fa-copy fa-regular"
      :stop-click="true"
      @click="clicked" />
    <VButton
      v-if="openButton"
      :class="buttonClasses + ' ' + buttonSize"
      tool-tip-text="Open Link in new tab"
      :title="onlyButton ? 'Open' : null"
      emphasized
      icon="fa-external-link-alt fa-regular"
      :stop-click="true"
      @click="openLink" />
    <VButton
      v-if="subject && body"
      :class="buttonClasses + ' ' + buttonSize"
      tool-tip-text="Send Link as Email"
      emphasized
      :title="onlyButton ? 'Send' : null"
      icon="fa-envelope"
      :stop-click="true"
      @click="sendAsEmail" />
  </ButtonGroup>
</template>
